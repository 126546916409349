require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/06fac0359d833ac55ccf22d782b246c6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_06fac0359d833ac55ccf22d782b246c6'] = function fn_06fac0359d833ac55ccf22d782b246c6(date) {
var months;


  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

return ([months[((new Date(date).getMonth() + 1) - 1)],' ',(new Date(date).getFullYear())].join(''))
}

})
define('./functions/0739089f230c0b29eaf5067eb1646d7b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b'] = async function fn_0739089f230c0b29eaf5067eb1646d7b(mainLocationId) {
return (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetPaymentSummary`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'mainLocationId': JSON.stringify(mainLocationId) }).send())
}

})
define('./functions/1881b06c0206a0662709cc4781b5388e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1881b06c0206a0662709cc4781b5388e'] = async function fn_1881b06c0206a0662709cc4781b5388e(categoryList) {
  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.category__list');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.objectId) + 1

  	    if(item.order === newOrder) return;

  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })(categoryList);

}

})
define('./functions/1b3221e760f6705f5d2efe0d87fac07e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_1b3221e760f6705f5d2efe0d87fac07e'] = async function fn_1b3221e760f6705f5d2efe0d87fac07e(mainLocationId, lastReconciliationObj) {
var offset, transactionsRes, transactions, lastReconciliationDate, date, item, users, usersRes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getTransactions(offset) {
  transactionsRes = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetReconciliationTransactions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'date': lastReconciliationDate,'offset': offset,'mainLocationId': mainLocationId }));
  addItemToList(transactions, transactionsRes);
  if (transactionsRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getTransactions(offset);
  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getUsers(offset) {
  usersRes = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetTransactionUsers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': (transactions.map(item => item['userId'])),'offset': offset }));
  addItemToList(users, usersRes);
  if (usersRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getUsers(offset);
  }
}


  lastReconciliationDate = (getObjectProperty(lastReconciliationObj, 'lastReconciliationDate'));
  date = (new Date());
  date.setDate(((new Date(date).getDate()) - 1));
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  transactions = [];
  await getTransactions(0);
  users = [];
  await getUsers(0);

return (await Promise.all(transactions.map(async item => {  item['username'] = (await (async function(list, userId) {
  	return list.find(item => item.objectId === userId)?.name
  })(users, (getObjectProperty(item, 'userId'))));
; return item;})))
}

})
define('./functions/223c2099ea67474426bbe9e8aeaf108b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b'] = async function fn_223c2099ea67474426bbe9e8aeaf108b(list, value) {
var value, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  value = value.toLowerCase();

return (await asyncListFilter(list, async (item) => {


 return (((getObjectProperty(item, 'username')) ? (((getObjectProperty(item, 'username')).toLowerCase()).indexOf(value) !== -1) : false) || (((getObjectProperty(item, 'title')).toLowerCase()).indexOf(value) !== -1) || ((String(getObjectProperty(item, 'amount')).toLowerCase()).indexOf(value) !== -1) || ((getObjectProperty(item, 'method')) ? (((getObjectProperty(item, 'method')).toLowerCase()).indexOf(value) !== -1) : false));
}))
}

})
define('./functions/3b82a0c51f35ff4e4b25e54aeb198a94/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'] = async function fn_3b82a0c51f35ff4e4b25e54aeb198a94(data) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['phoneNumber LIKE \'+%\'',[' AND ','name IS NOT NULL',' AND ','email IS NOT NULL'].join(''),(getObjectProperty(data, 'searchName')) ? String(' AND ') + String(['name LIKE \'%',(getObjectProperty(data, 'searchName')),'%\''].join('')) : '',(getObjectProperty(data, 'searchPhone')) ? String(' AND ') + String(['phoneNumber LIKE \'%',(getObjectProperty(data, 'searchPhone')),'%\''].join('')) : '',(getObjectProperty(data, 'searchEmail')) ? String(' AND ') + String(['email LIKE \'%',(getObjectProperty(data, 'searchEmail')),'%\''].join('')) : ''].join('')))))
}

})
define('./functions/4744eed4bcbd0dfd8db1ec8f4cb8addc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4744eed4bcbd0dfd8db1ec8f4cb8addc'] = async function fn_4744eed4bcbd0dfd8db1ec8f4cb8addc(lastReconciliationObj) {
var transactions, date, lastReconciliationDate, offset, transactionsRes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getTransactions(offset) {
  transactionsRes = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetReconciliationQRTransactions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'date': lastReconciliationDate,'offset': offset }));
  addItemToList(transactions, transactionsRes);
  if (transactionsRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getTransactions(offset);
  }
}


  lastReconciliationDate = (getObjectProperty(lastReconciliationObj, 'lastReconciliationDate'));
  date = (new Date());
  date.setDate(((new Date(date).getDate()) - 1));
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  transactions = [];
  await getTransactions(0);

return transactions
}

})
define('./functions/67559c72e94d48924fe326d47e282977/code.js', () => { 

BackendlessUI.Functions.Custom['fn_67559c72e94d48924fe326d47e282977'] = function fn_67559c72e94d48924fe326d47e282977(date) {
return ([[(new Date(year).getMonth() + 1) < 10 ? String('0') + String(new Date(year).getMonth() + 1) : (new Date(year).getMonth() + 1),'-','01','-',(new Date(year).getFullYear())].join(''),' - ',[(new Date(year).getMonth() + 1) < 10 ? String('0') + String(new Date(year).getMonth() + 1) : (new Date(year).getMonth() + 1),'-',(new Date(year.getFullYear(), year.getMonth() + 1, 0).getDate()),'-',(new Date(year).getFullYear())].join('')].join(''))
}

})
define('./functions/6c98c9313608834ca421bdc6a59a05e1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'] = async function fn_6c98c9313608834ca421bdc6a59a05e1(pageData) {
var categories, category, editing, error, i, j, list, menu, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    editing = (getObjectProperty(pageData, 'editing'));
    menu = (getObjectProperty(pageData, 'activeMenu'));
    console.log(getObjectProperty(pageData, 'editing'));
    console.log(getObjectProperty(pageData, 'activeMenu'));
    categories = (getObjectProperty(pageData, 'editing.category'));
    var i_end = categories.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      category = categories[(i - 1)];
      category[''] = undefined;
    }
    if ((getObjectProperty(menu, 'name')) != (getObjectProperty(editing, 'name'))) {
      Object.assign(menu, editing);
      await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(menu));
    }
    for (var j_index in categories) {
      j = categories[j_index];
      if (getObjectProperty(j, 'changed')) {
        await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'objectId': (getObjectProperty(j, 'objectId')),'order': (getObjectProperty(j, 'order')) })));
        j['changed'] = false;
        delete j['oldOrder'];
      }
    }
    timestamp = (await (async function(list, id) {
    	return list.find(item => item.menuId === id)
    })((getObjectProperty(pageData, 'timestamps')), (getObjectProperty(menu, 'objectId'))));
    timestamp['changeDate'] = ((new Date()).valueOf());
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(timestamp, 'objectId'))));

  } catch (error) {
    await (async function(error) {
    	alert(`Something went wrong:\n ${error}`)
    })(error);

  }

}

})
define('./functions/76da9e8603d2501c3881ff997b9cafb6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6'] = async function fn_76da9e8603d2501c3881ff997b9cafb6(date) {
var orders, j, day, offers, result, val, lastDay, firstDay, offset, res;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getOffers(offset) {
  res = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetMonthOffers`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'date': (date.valueOf()),'offset': offset }).send());
  addItemToList(offers, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getOffers(offset);
  }
}


  console.log(date);
  offers = [];
  await getOffers(0);
  orders = [];
  for (var j_index in offers) {
    j = offers[j_index];
    day = (new Date((getObjectProperty(j, 'offerDate'))).getDate());
    if (getObjectProperty(orders, day)) {
      addItemToList((getObjectProperty(orders, day)), j);
    } else {
      orders[day] = [j];
    }
  }

return orders
}

})
define('./functions/7b36908c404963be12756483593c50f6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7b36908c404963be12756483593c50f6'] = function fn_7b36908c404963be12756483593c50f6(month) {
var months;


  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

return (months[(month - 1)])
}

})
define('./functions/8cf84e8de2cc5df820b632e5fa0962e9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8cf84e8de2cc5df820b632e5fa0962e9'] = async function fn_8cf84e8de2cc5df820b632e5fa0962e9(date, locationIds) {
var reconcileList, obj, j, item, squareOrders;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  reconcileList = [];
  squareOrders = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-location-orders`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locationId': locationIds,'startDate': ([(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) < 10 ? String('0') + String(new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1),'-','01'].join('')),'endDate': ([(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) < 10 ? String('0') + String(new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1),'-',(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate())].join('')) })), 'orders'));
  for (var j_index in locationIds) {
    j = locationIds[j_index];
    obj = ({  });
    obj[j] = (await asyncListFilter(squareOrders, async (item) => {


     return ((getObjectProperty(item, 'location_id')) == j);
    }));
    addItemToList(reconcileList, obj);
  }

return ({ 'monthOrders': reconcileList })
}

})
define('./functions/90716ecc9a4bfea6e87832068108f1ed/code.js', () => { 

BackendlessUI.Functions.Custom['fn_90716ecc9a4bfea6e87832068108f1ed'] = async function fn_90716ecc9a4bfea6e87832068108f1ed(month, year) {
var days, timestamp, i, curDate, dayOfWeek, monthDays;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  curDate = (new Date());
  curDate.setMonth(month);
  curDate.setFullYear(year);
  monthDays = (new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0).getDate());
  curDate.setDate(0);
  days = [];
  dayOfWeek = (new Date(curDate).getDay());
  if (dayOfWeek < 6) {
    var i_start = (new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0).getDate()) - dayOfWeek;
    var i_end = (new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0).getDate());
    var i_inc = 1;
    if (i_start > i_end) {
      i_inc = -i_inc;
    }
    for (i = i_start; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      curDate.setDate(i);
      timestamp = ((await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b'](curDate)).valueOf());
      addItemToList(days, ({ 'date': i,'timestamp': timestamp }));
    }
  }
  curDate.setDate(((new Date(curDate).getDate()) + 1));
  var i_inc2 = 1;
  if (1 > monthDays) {
    i_inc2 = -i_inc2;
  }
  for (i = 1; i_inc2 >= 0 ? i <= monthDays : i >= monthDays; i += i_inc2) {
    curDate.setDate(i);
    timestamp = ((await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b'](curDate)).valueOf());
    addItemToList(days, ({ 'date': i,'timestamp': timestamp,'current': true }));
  }
  dayOfWeek = (new Date(curDate).getDay());
  if (dayOfWeek < 6) {
    var i_end2 = 6 - dayOfWeek;
    var i_inc3 = 1;
    if (1 > i_end2) {
      i_inc3 = -i_inc3;
    }
    for (i = 1; i_inc3 >= 0 ? i <= i_end2 : i >= i_end2; i += i_inc3) {
      curDate.setDate(i);
      timestamp = ((await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b'](curDate)).valueOf());
      addItemToList(days, ({ 'date': i,'timestamp': timestamp }));
    }
  }

return ({ 'days': days,'month': month,'year': year })
}

})
define('./functions/c0a3f7f2c1b299c7fa9efbcfccfaf763/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c0a3f7f2c1b299c7fa9efbcfccfaf763'] = function fn_c0a3f7f2c1b299c7fa9efbcfccfaf763(pageData) {
var changed, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  changed = false;
  var j_list = (getObjectProperty(pageData, 'editing.category'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    if ((getObjectProperty(j, 'changed')) && (getObjectProperty(j, 'order')) != (getObjectProperty(j, 'oldOrder'))) {
      changed = true;
      break;
    }
  }

return (changed || (getObjectProperty(pageData, 'editing.name')) != (getObjectProperty(pageData, 'activeMenu.name')))
}

})
define('./functions/cae38d8db93837ea37c781e2535338e7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'] = function fn_cae38d8db93837ea37c781e2535338e7(data, list) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (list.slice((((getObjectProperty(data, 'pageSize')) * (getObjectProperty(data, 'page')) - ((getObjectProperty(data, 'pageSize')) - 1)) - 1), (getObjectProperty(data, 'pageSize')) * (getObjectProperty(data, 'page'))))
}

})
define('./functions/cec8cdbb0f189ed2751b0981095a423e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'] = async function fn_cec8cdbb0f189ed2751b0981095a423e(pageData, date) {
var curDate, curMonthObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  curDate = date;
  curMonthObj = (await BackendlessUI.Functions.Custom['fn_90716ecc9a4bfea6e87832068108f1ed']((new Date(curDate).getMonth()), (new Date(curDate).getFullYear())));
  pageData['days'] = (getObjectProperty(curMonthObj, 'days'));
  pageData['month'] = (getObjectProperty(curMonthObj, 'month'));
  pageData['year'] = (getObjectProperty(curMonthObj, 'year'));
  pageData['monthName'] = (await BackendlessUI.Functions.Custom['fn_06fac0359d833ac55ccf22d782b246c6'](curDate));

}

})
define('./functions/ddb4eddf43837818efc38d6d1f1cdf27/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ddb4eddf43837818efc38d6d1f1cdf27'] = async function fn_ddb4eddf43837818efc38d6d1f1cdf27(month, year) {
var monthSummary, curYear, curMonth, endDay, startDay, month;


  month = month - 1;
  startDay = (new Date());
  startDay.setDate(1);
  startDay.setFullYear(year);
  startDay.setMonth(month);
  endDay = (new Date());
  endDay.setFullYear(year);
  endDay.setMonth(month);
  endDay.setDate((new Date(endDay.getFullYear(), endDay.getMonth() + 1, 0).getDate()));
  curMonth = (new Date(startDay).getMonth() + 1);
  curYear = (new Date(startDay).getFullYear());
  monthSummary = (await BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b'](null));

return ({ 'startDay': startDay,'endDay': endDay,'curMonth': curMonth,'curYear': curYear,'paymentMonthStr': [(await BackendlessUI.Functions.Custom['fn_7b36908c404963be12756483593c50f6'](curMonth)),' ',curYear].join(''),'monthSummary': monthSummary })
}

})
define('./functions/de9c0eec9816df08f6e21d751148f188/code.js', () => { 

BackendlessUI.Functions.Custom['fn_de9c0eec9816df08f6e21d751148f188'] = async function fn_de9c0eec9816df08f6e21d751148f188(id, sortField, sortOrder, locationId) {
var item, users, QRTransactions, transactions, offset, transactionsRes, usersRes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getTransactions(offset) {
  transactionsRes = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetLocationTransaction`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'id': id,'sortField': sortField,'sortOrder': sortOrder,'offset': offset }));
  addItemToList(transactions, transactionsRes);
  if (transactionsRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getTransactions(offset);
  }
}

/**
 * Describe this function...
 */
async function getQRTransactions(offset) {
  transactionsRes = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getQRTransactions`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'locationId': JSON.stringify(locationId),'offset': offset }).send());
  addItemToList(QRTransactions, transactionsRes);
  if (transactionsRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getQRTransactions(offset);
  }
}

/**
 * Describe this function...
 */
async function getUsers(offset) {
  usersRes = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetTransactionUsers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': (transactions.map(item => item['userId'])),'offset': offset }));
  addItemToList(users, usersRes);
  if (usersRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getUsers(offset);
  }
}


  transactions = [];
  await getTransactions(0);
  users = [];
  await getUsers(0);
  QRTransactions = [];
  await getQRTransactions(0);
  console.log(QRTransactions);

return (await asyncListSort((await Promise.all((transactions.concat(QRTransactions)).map(async item => {  item['createdStr'] = ((new Date((getObjectProperty(item, 'created')))).toLocaleString());
  item['amountStr'] = (String('$') + String((await (async function(number) {
  	return number.toFixed(2)
  })(((getObjectProperty(item, 'amount')) / 100)))));
  item['username'] = (await (async function(list, userId) {
  	return list.find(item => item.objectId === userId)?.name
  })(users, (getObjectProperty(item, 'userId'))));
  if (typeof (getObjectProperty(item, 'usedBonus')) === 'number' && !isNaN((getObjectProperty(item, 'usedBonus')))) {
    item['usedBonusStr'] = (String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(item, 'usedBonus')) / 100)))));
  }
; return item;}))), -1, async (item) => {


 return (getObjectProperty(item, 'created'));
}))
}

})
define('./functions/e7469d9baa2aa6578facbe8a273cb826/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'] = async function fn_e7469d9baa2aa6578facbe8a273cb826(pageData) {
var error, activeCategory, item, items, ids, editing;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  try {
    activeCategory = (getObjectProperty(pageData, 'activeCategory'));
    editing = (getObjectProperty(pageData, 'editingCategory'));
    console.log(activeCategory);
    console.log(editing);
    Object.assign(activeCategory, editing);
    if (!!(getObjectProperty(editing, 'item')).length) {
      ids = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(editing, 'item')))));
      activeCategory['item'] = (await Promise.all(ids.map(async item => {; return ({ 'objectId': item });})));
    }
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'name': (getObjectProperty(activeCategory, 'name')),'objectId': (getObjectProperty(activeCategory, 'objectId')),'image': (getObjectProperty(activeCategory, 'image')) })));
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminAssignItemToCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(activeCategory));
    items = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetCategoryItems`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'categoryId': JSON.stringify((getObjectProperty(activeCategory, 'objectId'))) }).send());
    activeCategory['item'] = (await asyncListSort(items, 1, async (item) => {


     return (getObjectProperty(item, 'order'));
    }));
    pageData['editingCategory'] = ({ 'name': (getObjectProperty(activeCategory, 'name')),'objectId': (getObjectProperty(activeCategory, 'objectId')),'item': [],'image': (getObjectProperty(activeCategory, 'image')) });
    (getObjectProperty(pageData, 'editingCategory'))['item'] = (getObjectProperty(activeCategory, 'item'));
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(pageData, 'timestamps')), (getObjectProperty(pageData, 'editing.objectId'))))));
    await (async function(categoryList) {
    	const items = [...document.querySelectorAll('.draggable')];
    	const container = document.querySelector('#item_list');
    	let dragItem = null;

    	items.forEach(draggable => {
    	  draggable.draggable = true;
    	  draggable.addEventListener('dragstart', (e) => {
    	    draggable.classList.add("is-dragging")
    	  });

    	  draggable.addEventListener('dragend', (e) => {
    	    draggable.classList.remove("is-dragging");
    	  })
    	})

    	container.addEventListener('dragover', (e) => {
    	  e.preventDefault();
    	  const afterElement = getDragAfterElement(container, e.clientY);
    	  const draggable = document.querySelector(".is-dragging");

    	  if (afterElement === null) {
    	    container.appendChild(draggable);
    	  } else {
    	    container.insertBefore(draggable, afterElement);
    	  }
    	});

    	container.addEventListener('drop', (e) => {
    	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);
    	  categoryList.forEach(item => {
    	    const newOrder = newList.indexOf(item.squareId) + 1

    	    if(item.order === newOrder) return;

    	    if(!item.oldOrder) item.oldOrder = item.order;
    	    item.order = newOrder;
    	    item.changed = true;
    	  })
    	});

    	function getDragAfterElement(container, y) {
    	  const draggableElements = [
    	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
    	  ];

    	  return draggableElements.reduce(
    	    (closest, child) => {
    	      const box = child.getBoundingClientRect();
    	      const offset = y - box.top - box.height / 2;

    	      if (offset < 0 && offset > closest.offset) {
    	        return {
    	          offset,
    	          element: child
    	        };
    	      } else {
    	        return closest;
    	      }
    	    },
    	    { offset: Number.NEGATIVE_INFINITY }
    	  ).element;
    	}



    })((getObjectProperty(pageData, 'editingCategory.item')));

  } catch (error) {
    await (async function(error) {
    	alert(`Something went wrong:\n ${error}`)
    })(error);

  }

}

})
define('./functions/ec0d32962c938b39757cb0fa83e523b6/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'] = async function fn_ec0d32962c938b39757cb0fa83e523b6(pageData) {
var item, editingCategory, activeCategory;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  activeCategory = (getObjectProperty(pageData, 'activeCategory'));
  editingCategory = (getObjectProperty(pageData, 'editingCategory'));

return (!((getObjectProperty(activeCategory, 'name')) == (getObjectProperty(editingCategory, 'name')) && (getObjectProperty(activeCategory, 'image')) == (getObjectProperty(editingCategory, 'image')) && (getObjectProperty(activeCategory, 'item')).length == (getObjectProperty(editingCategory, 'item')).length && ((await asyncListSort((getObjectProperty(activeCategory, 'item')), 1, async (item) => {


 return (getObjectProperty(item, 'name'));
})).map(item => item['squareId'])).join(',') == ((await asyncListSort((getObjectProperty(editingCategory, 'item')), 1, async (item) => {


 return (getObjectProperty(item, 'name'));
})).map(item => item['squareId'])).join(',')))
}

})
define('./functions/f00721b3fe57ced8dac4cb530886ec5b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b'] = function fn_f00721b3fe57ced8dac4cb530886ec5b(date) {
var date;


  date = date;
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

return date
}

})
define('./functions/f194bc7a16e6c9651e9df683e1bced61/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61'] = function fn_f194bc7a16e6c9651e9df683e1bced61() {
return ({ 'dashboard': '/img/icons/admin_icons/dashboard.svg','billing': '/img/icons/admin_icons/billing.svg','menu': '/img/icons/admin_icons/menu.svg','image': '/img/icons/admin_icons/image.svg','location': '/img/icons/admin_icons/location.svg','lto': '/img/icons/admin_icons/lto.svg','transactions': '/img/icons/admin_icons/transactions.svg','customers': '/img/icons/admin_icons/customers.svg','settings': '/img/icons/admin_icons/settings.svg','content': '/img/icons/admin_icons/content.svg' })
}

})
define('./functions/f9da412c517b7cdce9d73c1ba0acec54/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54'] = async function fn_f9da412c517b7cdce9d73c1ba0acec54(locationId, date, type) {
var paymentsRes, payments, cursor, locationId, total, squareOrders, payment, j, source, date, users, usedBonus, order, orders, item, offset, usersRes, ordersRes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getPayments(cursor) {
  paymentsRes = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-payments`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'locationId': JSON.stringify((locationId[0])),'startDate': JSON.stringify(([(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) < 10 ? String('0') + String(new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1),'-','01'].join(''))),'cursor': JSON.stringify(cursor),'endDate': JSON.stringify(([(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) < 10 ? String('0') + String(new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1),'-',(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate())].join(''))) }).send());
  if (getObjectProperty(paymentsRes, 'payments')) {
    addItemToList(payments, (getObjectProperty(paymentsRes, 'payments')));
    if (getObjectProperty(paymentsRes, 'cursor')) {
      await getPayments((getObjectProperty(paymentsRes, 'cursor')));
    }
  }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

/**
 * Describe this function...
 */
async function getUsers(offset) {
  usersRes = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetReconUsers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': (payments.map(item => item['customer_id'])),'offset': offset }));
  addItemToList(users, usersRes);
  if (usersRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getUsers(offset);
  }
}

/**
 * Describe this function...
 */
async function getOrders(offset) {
  ordersRes = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetReconOrders`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': (squareOrders.map(item => item['id'])),'offset': offset }));
  addItemToList(orders, ordersRes);
  if (ordersRes.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getOrders(offset);
  }
}


  payments = [];
  users = [];
  await getPayments('');
  if (!!payments.length) {
    await getUsers(0);
  }
  squareOrders = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-location-orders`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locationId': locationId,'startDate': ([(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) < 10 ? String('0') + String(new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1),'-','01'].join('')),'endDate': ([(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) < 10 ? String('0') + String(new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1),'-',(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate())].join('')) })), 'orders'));
  console.log(squareOrders);
  if (squareOrders && !!squareOrders.length) {
    if (type == 'income') {
      squareOrders = (await asyncListFilter(squareOrders, async (item) => {


       return ((getObjectProperty(item, 'tenders')) && ((getObjectProperty(((getObjectProperty(item, 'line_items'))[0]), 'name')) == 'Welcome gift' || (getObjectProperty(((getObjectProperty(item, 'line_items'))[0]), 'name')) == 'Fill up the balance'));
      }));
    } else if (type == 'outgoing') {
      squareOrders = (await asyncListFilter(squareOrders, async (item) => {


       return ((getObjectProperty(item, 'tenders')) && (getObjectProperty(((getObjectProperty(item, 'line_items'))[0]), 'name')) != 'Welcome gift' && (getObjectProperty(((getObjectProperty(item, 'line_items'))[0]), 'name')) != 'Fill up the balance');
      }));
    }
    if (!!squareOrders.length) {
      orders = [];
      await getOrders(0);
      for (var j_index in squareOrders) {
        j = squareOrders[j_index];
        order = (await (async function(list, id) {
        	return list.find(item => item.squareId === id)
        })(orders, (getObjectProperty(j, 'id'))));
        usedBonus = order ? (getObjectProperty(order, 'usedBonus')) : 0;
        j['usedBonus'] = usedBonus;
        j['usedBonusStr'] = (await (async function(number) {
        	return number.toFixed(2)
        })((usedBonus / 100)));
        j['amount'] = (await (async function(number) {
        	return number.toFixed(2)
        })((((getObjectProperty(j, 'total_money.amount')) - usedBonus) / 100)));
        j['payment'] = (await (async function(list, id) {
        	return list.find(item => item.order_id === id)
        })(payments, (getObjectProperty(j, 'id'))));
        j['customer'] = (await (async function(list, id) {
        	return list.find(item => item.customerId === id)
        })(users, (getObjectProperty(j, 'payment.customer_id'))));
        date = (getObjectProperty(j, 'updated_at'));
        j['orderTime'] = ([[(new Date(date).getMonth() + 1) < 10 ? String(0) + String(new Date(date).getMonth() + 1) : (new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) < 10 ? String(0) + String(new Date(date).getDate()) : (new Date(date).getDate()),'-',subsequenceFromEndLast(String(new Date(date).getFullYear()), 1)].join(''),', ',[(new Date(date).getHours()) < 10 ? String(0) + String(new Date(date).getHours()) : (new Date(date).getHours()),':',(new Date(date).getMinutes()) < 10 ? String(0) + String(new Date(date).getMinutes()) : (new Date(date).getMinutes()),':',(new Date(date).getSeconds()) < 10 ? String(0) + String(new Date(date).getSeconds()) : (new Date(date).getSeconds())].join('')].join(''));
        payment = (getObjectProperty(j, 'payment'));
        source = (getObjectProperty(payment, 'source_type'));
        if (source) {
          if (source == 'CARD') {
            if ((getObjectProperty(payment, 'card_details.entry_method')) == 'KEYED') {
              j['paymentType'] = 'Apple Pay / Google Pay';
            } else if ((getObjectProperty(payment, 'card_details.entry_method')) == 'ON_FILE') {
              j['paymentType'] = (getObjectProperty(payment, 'card_details.card.card_brand'));
            }
          } else if (source == 'SQUARE_GIFT_CARD') {
            j['paymentType'] = (getObjectProperty(payment, 'card_details.card.card_brand'));
          }
        }
      }
      total = ((squareOrders.map(item => item['total_money'])).map(item => item['amount'])).reduce(function(x, y) {return x + y;}) - (squareOrders.map(item => item['usedBonus'])).reduce(function(x, y) {return x + y;});
    }
  }
  console.log(squareOrders);

return ({ 'payments': squareOrders,'totalSum': (await (async function(number) {
	return number.toFixed(2)
})((total ? total / 100 : 0))) })
}

})
define('./functions/fcec57351c91061d5927992ed935b2e9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_fcec57351c91061d5927992ed935b2e9'] = async function fn_fcec57351c91061d5927992ed935b2e9(payments) {
var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return ({ 'incomeList': (await Promise.all((getObjectProperty(payments, 'income')).map(async item => {  item['amountStr'] = (String('$') + String((await (async function(number) {
  	return number.toFixed(2)
  })(((getObjectProperty(item, 'totalAmount')) / 100)))));
; return item;}))),'payoutList': (await Promise.all((getObjectProperty(payments, 'payout')).map(async item => {  item['amountStr'] = (String('$') + String((await (async function(number) {
  	return number.toFixed(2)
  })(((getObjectProperty(item, 'totalAmount')) / 100)))));
  item['usedBonusStr'] = (String('$') + String((await (async function(number) {
  	return number.toFixed(2)
  })(((getObjectProperty(item, 'totalUsedBonus')) / 100)))));
  item['paymentStr'] = (String('$') + String((await (async function(number) {
  	return number.toFixed(2)
  })((((getObjectProperty(item, 'totalAmount')) - (getObjectProperty(item, 'totalUsedBonus'))) / 100)))));
; return item;}))) })
}

})
define('./functions/ff8ba791ea54622d40e3ca931eab7d0c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'] = async function fn_ff8ba791ea54622d40e3ca931eab7d0c(data) {
return (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetUsers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(data)))
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/billing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/billing/components/1ba4723e9dd479774a4fa0260ff9aa35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c498a0cfb944cc7eee4e8a619d6d4c3', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/billing/components/410e58d77f51268a83f47b913bca2e14/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c498a0cfb944cc7eee4e8a619d6d4c3', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/billing/components/4eb1dc24c946fe1d261e54079bbb811a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c498a0cfb944cc7eee4e8a619d6d4c3', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/billing/components/94c878b5600ea57129c13cafcb8c2f81/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1c498a0cfb944cc7eee4e8a619d6d4c3', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var mainLocation, allLocationsList, item, activeLocationsList, newLocations, squareLocations, itemsLength, sortedItems, j, updateItem, itemsList, menuItems, result, offset, res;

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.pageData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/content/components/1e6780e56a12dc1e04f3b30a268105d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/09ee7b31196c7618e9438bb4d9766b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/67972eade3335ce9fbdf479ab15d7f6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newMenu, list, menuName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  menuName = (getObjectProperty(___arguments.context.pageData, 'newMenuName'));
  list = (getObjectProperty(___arguments.context.pageData, 'menuList'));
  if (menuName) {
    newMenu = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminCreateNewMenu`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'name': JSON.stringify(menuName) }).send());
    addItemToList(list, newMenu);
    delete ___arguments.context.pageData['newMenuName'];
    addItemToList((getObjectProperty(___arguments.context.pageData, 'timestamps')), (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(newMenu, 'objectId'))))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/5e23e373e2d47b6876070a18845ace2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/ca2fa06723b33b229969fa56df5fafb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/fffb1468d62269c33bb737b61e9b0659/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, list, categoryName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categoryName = (getObjectProperty(___arguments.context.pageData, 'newCategoryName'));
  list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  if (categoryName) {
    category = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'name': categoryName,'order': (list.length + 1),'menuId': (getObjectProperty(___arguments.context.pageData, 'editing.objectId')) }));
    category['item'] = [];
    addItemToList(list, category);
    await (async function() {
    	const category = [...document.querySelectorAll('.draggable')].slice(-1).pop();

    	category.draggable = true;
    	category.addEventListener('dragstart', (e) => {
    	  category.classList.add("is-dragging")
    	});

    	category.addEventListener('dragend', (e) => {
    	  category.classList.remove("is-dragging");
    	})
    })();
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
    delete ___arguments.context.pageData['newCategoryName'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/80451d7b74fdb049e2133bf6b39e6db7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  delete ___arguments.context.pageData['newItem'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/d2cdea06507901cd1ebfad2e5e7357d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['newItem'];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/81ef466fc0daa5438334ddd78906b37a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newItem, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'newItems')) && !!(getObjectProperty(___arguments.context.pageData, 'newItems')).length) {
    newItem = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'newItems')).map(async item => {; return ({ 'name': (getObjectProperty(item, 'item_data.name')),'squareId': (getObjectProperty(item, 'id')),'variation': ((getObjectProperty(item, 'item_data.variations')).map(item => item['id'])),'order': (getObjectProperty(___arguments.context.pageData, 'newItems')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).length });})));
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['item'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), newItem));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    delete ___arguments.context.pageData['newItems'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/2195e4413bb4651d9af9c0b5f35cc786/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/c517adbd761c078868c23a0417cb4009/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var categories, items, j, menu;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categories = (getObjectProperty(___arguments.context.pageData, 'activeMenu.category'));
  menu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'menuList')), menu, 'objectId');
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(menu, 'objectId'))));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(menu, 'objectId'))))));
  if (categories && !!categories.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((categories.map(item => item['objectId']))));
    items = [];
    var j_list = (categories.map(item => item['item']));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(items, j);
    }
    if (!!items.length) {
      await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
    }
  }
  ___arguments.context.pageData['activeScreen'] = 'menu';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/4d497b345ff8661374350be53f762fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/81eb464908fa214371fad0fc9da7da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, items;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  category = (getObjectProperty(___arguments.context.pageData, 'categoryToDelete'));
  items = (getObjectProperty(category, 'item'));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(category, 'objectId'))]));
  if (!!items.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
  }
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editing.category')), category, 'objectId');
  await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  ___arguments.context.pageData['activeScreen'] = 'category';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/f351bbec212b1a64f9bb319b2db7ed1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/04354a94d901e953b2dd83af23dfc6a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  item = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  if (getObjectProperty(item, 'objectId')) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'objectId');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'objectId');
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify());
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'order');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'order');
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  ___arguments.context.pageData['editItem'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/c2354b1d5cbb04b68e686f65e4f2329d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var newItems;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
  if (___arguments.value) {
    if (newItems) {
      addItemToList(newItems, ___arguments.context.dataModel);
    } else {
      ___arguments.context.pageData['newItems'] = [___arguments.context.dataModel];
      newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
    }
  } else {
    removeItemInList(newItems, ___arguments.context.dataModel, 'id');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'newItems')) && (((getObjectProperty(___arguments.context.pageData, 'newItems')).map(item => item['id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/content/components/d3d5a474c709ae6bfc919e3250f71aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/9de2b727d0c49ad7ffe601c858dcf287/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/c331492cf5738f93b85d32619a4f57f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/content/components/23144bc14fa4e6d42aec6ed66d7da7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/8665bb11608ea9cc4a16d186892a68c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/content/components/4813af6d506917b2fa7993d9713e7ce9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/cac1771cf2985c29f493f07d8404503a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/content/components/8c3ccce88fcdb265c83723bfa9c1e75a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    var j_list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'oldOrder')) {
        j['order'] = (getObjectProperty(j, 'oldOrder'));
        j['changed'] = false;
        delete j['oldOrder'];
      }
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/7ea3c78b76896a9f6a8bfe3b8467a838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
    await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/1f3e6b85e1da4e78f09d0552d3ac44da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/2ea8f662f17978e7b5b36f0b8b868237/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/7e92da65f1f758a852c2bcbdd48d8741/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('23aa26dfcdc16688b2065c0d25da2ca4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/8bd7b99185b6ae3788dfce84b9d1b728/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu') {
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ac65e8de202b30c7ef2d83bd7be8675a')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/content/components/68a4020f2c832f04358838766e864a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/3678cc2ead6cad6dce141ea1ebbcb756/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/6526393763e3a4c7909bcc2a81665aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/e53b4263b63bbe926862d1f42016faca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/f167cc4a529265896f775dd1f58fcf4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/853de7c6b4c7d7081c68f5f3f27699a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/8528128c771cbd8e7a729a72e25e9622/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteMenu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/21b6da3853232976a77f5053705d4a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/6bfec90cc41883d3ef91acc5545e7a83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'todaysOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/38cc2ab7137c43ce012215fafa838566/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, item, j, modifiers, offers, offersToDelete, selectedModifiers, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'offers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  offersToDelete = (getObjectProperty(___arguments.context.pageData, 'offersToDelete'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'offerDate': (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp')),'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'offers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/content/components/147fb543216568d9044a403b78dd33c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/9741a2f63a781c339b7455042b7a2fcc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/a2fb35e6eb5ed781034fb01ed0c13555/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, offers, offersToDelete, ids, offersToCreate, item, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  timestamp = (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'));
  addItemToList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'offers')), 1, async (item) => {
     item['order'] = ((getObjectProperty(___arguments.context.pageData, 'offers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length);


   return (getObjectProperty(item, 'name'));
  })));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/3b7ca5aa8ac86a1dda15706d607f5aa4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length) {
    ___arguments.context.pageData['periodOffersList'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'periodOffers')), 1, async (item) => {
       item['order'] = ((getObjectProperty(___arguments.context.pageData, 'periodOffers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length);


     return (getObjectProperty(item, 'name'));
    }))));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/content/components/2347c3e33bd631fcb82155b1b63efee4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, offers, selectedModifiers, j, modifiers, item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'periodOffers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'periodOffers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/content/components/d15846c1fa19d2c5bfc2071d973a670e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/94a2c4e501c37ae46467178715faa1db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/95412ce446138ea82f1b0a2953127ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/de042b7c17155e92ca7b0324cfbf86f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffers')), ___arguments.context.getComponentDataStoreByUid('7bbeefa6fdc6e11dd6dc2fd798ad5749'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/3af29a84d03d50b21fe45a3ee8a3683a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'offers')), ___arguments.context.getComponentDataStoreByUid('552b29f96cbcda34a01a373cb661dd0b'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/f2e60cedd8461306cf55af0501dd8c39/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  async ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return (await (async function(files, percent, width) {
	for (let index = 0; index < files.length; index++) {
	  const file = files[index]
	  const fileName = file.name

	  const content = await readFileAsDataURL(file)

	  const img = await getNewImage(content)

	  const elem = document.createElement('canvas')

	  const widthImage = !width ? img.width : img.width > width ? width : img.width
	  const scaleFactor = img.width > width ? width && width / img.width : 1
	  const heightImage = width ? img.height * scaleFactor : img.height

	  elem.width = widthImage
	  elem.height = heightImage

	  const ctx = elem.getContext('2d')

	  ctx.drawImage(img, 0, 0, widthImage, heightImage)

	  const blob = await getCanvasBlob(ctx.canvas, percent)

	  const newFile = new File([blob], fileName, {
	    type: 'image/jpeg',
	    lastModified: Date.now()
	  })

	  files[index] = newFile
	}

	async function readFileAsDataURL(file) {
	  const result = await new Promise((resolve) => {
	    const fileReader = new FileReader()

	    fileReader.onload = (e) => resolve(e.target.result)

	    fileReader.readAsDataURL(file)
	  })

	  return result
	}

	async function getNewImage(content) {
	  const result = await new Promise((resolve) => {
	    const img = new Image()

	    img.src = content

	    img.onload = () => resolve(img)
	  })

	  return result
	}

	async function getCanvasBlob(canvas, percent) {
	  const result = await new Promise((resolve) => {
	    canvas.toBlob((blob) => {
	      resolve(blob)
	    }, 'image/jpeg', percent)
	  })

	  return result
	}

	return files
})(___arguments.files, 1, 600))

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedOfferImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedOfferImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/content/components/c2b51d85ca38df7c478af3ec9a3dd2ef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editOfferImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/afc9812e122100175a02defad0086d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['useImage'] = true;
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/50d646aff2df909d6f3cfa42834e46d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/5ab734948432b055f37bd2f5d79f49a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/73bf13a62b99deee7c49d918cd4e9b65/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/content/components/f6f8336f7239d56e550334dc3c796739/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/content/components/fa79760dc4aee4548cf8b0b361de395c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/38fdc0d564cb93555f7b3af990942bbd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteUser')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/content/components/d462ba7e784a2bfbf4fb69ebd36d9463/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/7ffbfd02194122b2b9fa64d5203f74a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(___arguments.context.pageData, 'userToDelete.objectId')) });
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/3e8b6c2f40af464cbb6b024c9abee553/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var card;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance') {
    card = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'customer')), 'walletId'))) }).send());
    (getObjectProperty(___arguments.context.pageData, 'customer'))['wallet'] = card;
    (getObjectProperty(___arguments.context.pageData, 'customer'))['balance'] = (getObjectProperty(card, 'balance_money.amount'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a591c5686d8d9cb355e7eba9fb40ecc', true);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(card, 'balance_money.amount')) / 100)))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94912c8669c55c478fbc6851df1ce87d', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/content/components/12c213fd49647dcefcfeceac2d54c56e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/c6e29ba49b64e8678919ffe51b74abf4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, wallet, order, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    if (!(getObjectProperty(user, 'customerId'))) {
      user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
    }
    if (!(getObjectProperty(user, 'walletId'))) {
      wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))))), 'gift_card'));
      await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      try {
        amount = 500;
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'line_items': [({ 'name': 'Welcome gift','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) })), 'payment'));
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
        user['wallet'] = wallet;
        user['walletId'] = (getObjectProperty(wallet, 'id'));
        user['bonusBalance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
        	return number.toFixed(2)
        })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
        await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(user, 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(user, 'customerId')) })) );

      } catch (error) {
        console.log(error);

      }
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/6dafc3783e893a15101bb6ff439889a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, wallet, user, order, locationId, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Proceeding...';
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Manual refill','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      if (getObjectProperty(user, 'walletId')) {
        wallet = (getObjectProperty(user, 'wallet'));
      } else {
        wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(locationId))), 'gift_card'));
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      }
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
      	return number.toFixed(2)
      })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Done';
      await new Promise(r => setTimeout(r, 5000 || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = '';

    } catch (error) {
      console.log(error);
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = (String('Error: ') + String(getObjectProperty(error, 'message')));
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/b4b1bbad5badf82823b35199fc3eeaed/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4b1bbad5badf82823b35199fc3eeaed'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/content/components/8b39c08d8789848677dd3923e547da4f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/saveAppContent`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c394470b2df24abb927bf18e0676480d'), 'orderModalText'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', true);
    await new Promise(r => setTimeout(r, 5000 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);

  } catch (error) {
    await (async function(error) {
    	alert(error)
    })(error);
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/content/components/c394470b2df24abb927bf18e0676480d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var appContent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  appContent = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getAppContent`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.dataModel['appContent'] = appContent;
  console.log(appContent);
  ___arguments.context.dataModel['orderModalText'] = (getObjectProperty(appContent, 'orderModalText'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/customers/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var mainLocation, allLocationsList, item, activeLocationsList, newLocations, squareLocations, createdLocations, itemsLength, sortedItems, j, updateItem, itemsList, menuItems, result, offset, res;

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.pageData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  if (await Backendless.UserService.isValidLogin()) {
    if (getObjectProperty(___arguments.context.appData, 'locations')) {
      allLocationsList = (getObjectProperty(___arguments.context.appData, 'locations'));
    } else {
      await getLocationsFromSquare();
      allLocationsList = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((squareLocations.map(item => item['id'])))));
      if (squareLocations.length > allLocationsList.length) {
        newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (await Promise.all((await asyncListFilter(squareLocations, async (item) => {


         return (!((allLocationsList.map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
        })).map(async item => {; return ({ 'squareId': (getObjectProperty(item, 'id')),'name': (getObjectProperty(item, 'name')),'hidden': (getObjectProperty(item, 'status')) == 'INACTIVE' });}))),'timestampId': (getObjectProperty((await (async function(list) {
        	return list.find(item => item.type === 'location')
        })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) }));
        addItemToList(allLocationsList, newLocations);
      }
      ___arguments.context.appData['locations'] = allLocationsList;
    }
    activeLocationsList = (await asyncListFilter(allLocationsList, async (item) => {


     return ((getObjectProperty(item, 'isMain')) || !(getObjectProperty(item, 'hidden')));
    }));
    mainLocation = (await (async function(list) {
    	return list.find(item => item.isMain)
    })(allLocationsList));
    ___arguments.context.pageData['mainLocationId'] = (getObjectProperty(mainLocation, 'objectId'));
    ___arguments.context.pageData['mainLocationSquareId'] = (getObjectProperty(mainLocation, 'squareId'));
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
    ___arguments.context.pageData['section'] = 'menu';
    ___arguments.context.pageData['months'] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/customers/components/953bd551accbc806b9dbfad62dabc57a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['pageSize'] = 10;
  ___arguments.context.dataModel['pageOffset'] = 0;
  ___arguments.context.dataModel['pageNumber'] = ((getObjectProperty(___arguments.context.dataModel, 'pageOffset')) / (getObjectProperty(___arguments.context.dataModel, 'pageSize')) + 1);
  ___arguments.context.dataModel['sortField'] = 'created';
  ___arguments.context.dataModel['sortOrder'] = 'desc';
  customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.dataModel));
  ___arguments.context.dataModel['customersAmount'] = customersAmount;
  ___arguments.context.dataModel['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.dataModel, 'pageSize'))));
  ___arguments.context.dataModel['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/customers/components/45ef1d17d00bc17c481681125e240fc4/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('45ef1d17d00bc17c481681125e240fc4'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('45ef1d17d00bc17c481681125e240fc4'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'name';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/customers/components/2216fcbab4ffd18264bf82611f683fa1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'phoneNumber';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2216fcbab4ffd18264bf82611f683fa1'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2216fcbab4ffd18264bf82611f683fa1'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/customers/components/80826327cf89e0d94f6623fddef1f9c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'email';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('80826327cf89e0d94f6623fddef1f9c4'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('80826327cf89e0d94f6623fddef1f9c4'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/customers/components/63647a6e2bfb5723de1fff5242be3a2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/f3157869327e8f061aa9f4cb6a8ea87c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > 1 && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/c4510d57e82c46f6a1d407a9ca6de024/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var value;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  value = (Number((___arguments.value.replace((new RegExp('\\D', 'g')), ''))));
  if (value <= (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages')) && value >= 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = value;
  } else if (value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
  } else if (value < 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = 1;
  }

  },
  /* handler:onChange */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.keyCode == 13) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) - 1) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/customers/components/38fdc0d564cb93555f7b3af990942bbd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteUser')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/customers/components/d462ba7e784a2bfbf4fb69ebd36d9463/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/7ffbfd02194122b2b9fa64d5203f74a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(___arguments.context.pageData, 'userToDelete.objectId')) });
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/b989cd8feda832fee987dd98dacc0d5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['searchText'] = '';
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil((await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((String('phoneNumber LIKE \'+%\'') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''))))) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/705a38190973ff4b085f87d423dc1da3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/3e8b6c2f40af464cbb6b024c9abee553/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var card;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance') {
    card = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'customer')), 'walletId'))) }).send());
    (getObjectProperty(___arguments.context.pageData, 'customer'))['wallet'] = card;
    (getObjectProperty(___arguments.context.pageData, 'customer'))['balance'] = (getObjectProperty(card, 'balance_money.amount'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a591c5686d8d9cb355e7eba9fb40ecc', true);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(card, 'balance_money.amount')) / 100)))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94912c8669c55c478fbc6851df1ce87d', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/customers/components/12c213fd49647dcefcfeceac2d54c56e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/aa0ec2488a77fafeb0d8716b163be712/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    customersAmount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['phoneNumber LIKE \'+%\'',___arguments.value ? [' AND ','name IS NOT NULL',' AND ','email IS NOT NULL'].join('') : '',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''].join('')))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/customers/components/c6e29ba49b64e8678919ffe51b74abf4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, wallet, order, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    if (!(getObjectProperty(user, 'customerId'))) {
      user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
    }
    if (!(getObjectProperty(user, 'walletId'))) {
      wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))))), 'gift_card'));
      await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      try {
        amount = 500;
        order = (getObjectProperty(({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'line_items': [({ 'name': 'Gift Card','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })],'discounts': [({ 'amount_money': ({ 'amount': amount,'currency': 'USD' }),'name': 'Welcome Gift Discount' })],'state': 'COMPLETED' }), 'order'));
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
        user['wallet'] = wallet;
        user['walletId'] = (getObjectProperty(wallet, 'id'));
        user['bonusBalance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
        	return number.toFixed(2)
        })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
        await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(user, 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(user, 'customerId')) })) );

      } catch (error) {
        console.log(error);

      }
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/6dafc3783e893a15101bb6ff439889a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, wallet, user, order, locationId, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['content'] = 'Proceeding...';
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Manual refill','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      if (getObjectProperty(user, 'walletId')) {
        wallet = (getObjectProperty(user, 'wallet'));
      } else {
        wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(locationId))), 'gift_card'));
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      }
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
      	return number.toFixed(2)
      })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['content'] = 'Done';
      await new Promise(r => setTimeout(r, 5000 || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['content'] = '';

    } catch (error) {
      console.log(error);
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = (String('Error: ') + String(getObjectProperty(error, 'message')));
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/b4b1bbad5badf82823b35199fc3eeaed/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4b1bbad5badf82823b35199fc3eeaed'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/customers/components/b7d6873f9854a6eae72f316aa5edff6f/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'created') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b7d6873f9854a6eae72f316aa5edff6f'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b7d6873f9854a6eae72f316aa5edff6f'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'created' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'created') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'created';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/9ed7dbd5f737c15accef35aeb03a662a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['userToDelete'] = ___arguments.context.getComponentDataStoreByUid('bb7c09942da32af24212a83b96738f39');
  ___arguments.context.pageData['modal'] = 'deleteUser';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/a7f9cfd9bf8df9b1b5b7f732728dd336/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['customer'] = ___arguments.context.getComponentDataStoreByUid('bb7c09942da32af24212a83b96738f39');
  ___arguments.context.pageData['modal'] = 'balance';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/65df08b2f6ee161e11c6881308b4be85/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    stopSetTimeout('search');

    ;(function() {
      const callback = async () => {
          ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
      customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
      if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
        ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
      }
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;

      };

      const timerId = 'search';
      const timerDelay = 1000;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/customers/components/c8438b718b0378e57691c0539a461099/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    stopSetTimeout('search');

    ;(function() {
      const callback = async () => {
          ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
      customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
      if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
        ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
      }
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;

      };

      const timerId = 'search';
      const timerDelay = 1000;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/customers/components/8b6aa9783f3208fd88715417d187cada/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    stopSetTimeout('search');

    ;(function() {
      const callback = async () => {
          ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
      customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
      if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
        ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
      }
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;

      };

      const timerId = 'search';
      const timerDelay = 1000;

      runSetTimeout(timerId, callback, timerDelay)
    })()
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/customers/components/0d8bbf3f6987c262ad330f97711195ea/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var customersAmount;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  stopSetTimeout('search');
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['searchName'] = '';
  customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/00328cb2e80420e3d02b47e38f22457b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var customersAmount;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  stopSetTimeout('search');
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['searchPhone'] = '';
  customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/dde9e9fddebbe90671c5977d08aadcc4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var customersAmount;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  stopSetTimeout('search');
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['searchEmail'] = '';
  customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/customers/components/83715e629affd4f89f5b9475343da1ed/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var customersAmount;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  stopSetTimeout('search');
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageSize'] = ___arguments.value;
  customersAmount = (await BackendlessUI.Functions.Custom['fn_3b82a0c51f35ff4e4b25e54aeb198a94'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/customers/components/4d0c5c2934aba85fcd417238ebd2029d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email' ? ['table__header_text', 'active'] : ['table__header_text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/customers/components/8dd962cbf65c8b18563551793a1af3ab/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'created' ? ['table__header_text', 'active'] : ['table__header_text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/customers/components/17981b43acfebc75fba8f22112cfb358/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber' ? ['table__header_text', 'active'] : ['table__header_text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/customers/components/fa64d9cd9e39d08d533571a98b3b236e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name' ? ['table__header_text', 'active'] : ['table__header_text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/dashboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item, allLocationsList, newLocations, squareLocations, sortedItems, menuItems, result, offset, res, itemsList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));

  } catch (error) {
    await getLocationsFromSquare();

  }
}


  if (await Backendless.UserService.isValidLogin()) {
    ___arguments.context.pageData['timestamps'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    if (getObjectProperty(___arguments.context.appData, 'squareItems')) {
      sortedItems = (getObjectProperty(___arguments.context.appData, 'squareItems'));
    } else {
      await getMenus();
      sortedItems = (await asyncListSort(menuItems, 1, async (item) => {


       return (getObjectProperty(item, 'item_data.name'));
      }));
      ___arguments.context.appData['squareItems'] = sortedItems;
    }
    if (!(getObjectProperty(___arguments.context.appData, 'taxList'))) {
      ___arguments.context.appData['taxList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('tax') }).send());
    }
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/dashboard/components/ff55f1d4e2970dd49eafde08c8f3eaa7/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['amount'] = (String('$ ') + String((await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(((getObjectProperty(((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getTotalAppPurchase`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send())[0]), 'amount')) / 100)))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/89e756c758a54f0b8b3ee963c19e15c2/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    var dayStart;


  dayStart = (new Date());
  dayStart.setHours(0);
  dayStart.setMinutes(0);
  dayStart.setSeconds(0);
  dayStart.setMilliseconds(0);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/e237e3763549949c16deaaf4bc1584e5/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['amount'] = (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['name is not null',' AND ','email is not null'].join('')))))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/75a3e43d05bdacda8984365deea137af/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var date, item, users, dailyUsers, period, averageDivider, j, groupBy, months, days, i, filter, list, created, nextObj, obj;

/**
 * Describe this function...
 */
async function setBaseDate() {
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function chechTodayDate(list) {
  created = (getObjectProperty((list.slice(-1)[0]), 'created'));
  if ((new Date(created).getUTCDate()) != (new Date((new Date())).getUTCDate())) {
    addItemToList(list, ({ [`amount`]: 0,[`date`]: [(new Date((new Date())).getFullYear()),'-',(new Date((new Date())).getMonth() + 1) >= 10 ? (new Date((new Date())).getMonth() + 1) : String(0) + String(new Date((new Date())).getMonth() + 1),'-',(new Date((new Date())).getDate()) >= 10 ? (new Date((new Date())).getDate()) : String(0) + String(new Date((new Date())).getDate())].join(''),[`label`]: (new Date((new Date())).getDate()) >= 10 ? (new Date((new Date())).getDate()) : String(0) + String(new Date((new Date())).getDate()) }));
  }
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getChartData() {
  return await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getUsersChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((date ? String('created > ') + String(date.valueOf()) : '')),'groupBy': JSON.stringify(groupBy) }).send()), 1, async (item) => {


 return (getObjectProperty(item, 'created'));
})
}

/**
 * Describe this function...
 */
async function insertMissingDate() {
  obj = users[(i - 1)];
  nextObj = users[((i - 1) - 1)];
  date = (new Date((getObjectProperty(obj, 'date'))));
  date.setDate(((new Date(date).getDate()) - 1));
  if (nextObj && (new Date(date).getDate()) != (Number((getObjectProperty(nextObj, 'label')))) && users.length <= averageDivider) {
    users.splice((i - 1), 0, ({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
    await insertMissingDate();
  }
}


  await new Promise(r => setTimeout(r, 50 || 0));
  filter = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'));
  period = (getObjectProperty(filter, 'signedUp'));
  averageDivider = 30;
  await setBaseDate();
  date.setDate(((new Date((new Date())).getDate()) - (averageDivider - 1)));
  groupBy = 'date(created)';
  dailyUsers = await getChartData();
  await chechTodayDate(dailyUsers);
  if (period == 'day') {
    await setBaseDate();
    date.setDate(((new Date((new Date())).getDate()) - (averageDivider - 1)));
    groupBy = 'date(created)';
    users = await getChartData();
    await chechTodayDate(users);
    for (var j_index in users) {
      j = users[j_index];
      j['label'] = (subsequenceFromEndLast((getObjectProperty(j, 'date')), 1));
    }
    if (users.length < averageDivider) {
      var i_start = users.length;
      var i_inc = 1;
      if (i_start > 2) {
        i_inc = -i_inc;
      }
      for (i = i_start; i_inc >= 0 ? i <= 2 : i >= 2; i += i_inc) {
        await insertMissingDate();
      }
      while (users.length < averageDivider) {
        obj = users[0];
        date = (new Date((getObjectProperty(obj, 'date'))));
        date.setDate(((new Date(date).getDate()) - 1));
        users.unshift(({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
      }
    }
  } else if (period == 'week') {
    await setBaseDate();
    days = 7 * (averageDivider - 1);
    date.setDate(((new Date(date).getDate()) - ((new Date((new Date())).getDay()) + 1 + days)));
    groupBy = 'week(created)';
    users = await getChartData();
    for (var j_index2 in users) {
      j = users[j_index2];
      j['label'] = '';
    }
  } else if (period == 'month') {
    await setBaseDate();
    date.setMonth(((new Date((new Date())).getMonth()) - (averageDivider - 1)));
    groupBy = 'month(created)';
    users = await getChartData();
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var j_index3 in users) {
      j = users[j_index3];
      j['label'] = (months[((Number(((getObjectProperty(j, 'date')).slice(5, 7)))) - 1)]);
    }
  } else if (period == 'year') {
    await setBaseDate();
    date.setFullYear(((new Date((new Date())).getFullYear()) - (averageDivider - 1)));
    groupBy = 'year(created)';
    users = await getChartData();
    for (var j_index4 in users) {
      j = users[j_index4];
      j['label'] = ((getObjectProperty(j, 'date')).slice(0, 4));
    }
  }
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('89e756c758a54f0b8b3ee963c19e15c2'))['amount'] = (getObjectProperty((dailyUsers.slice(-1)[0]), 'amount'));
  if (users.length > averageDivider) {
    users = users.slice(users.length - averageDivider, users.length);
  }
  await (async function() {
  	const el = document.querySelector(".signed-up");
  	let html = `<canvas id="signed-up" height="200"></canvas>`;
  	el.insertAdjacentHTML("afterbegin", html);
  })();
  Object.assign(___arguments.context.dataModel, ({ [`period`]: period,[`todayAmount`]: (getObjectProperty((dailyUsers.slice(-1)[0]), 'amount')),[`yesterdayAmount`]: (getObjectProperty((dailyUsers.slice(-2)[0]), 'amount')),[`average`]: (await (async function(number) {
  	return number.toFixed(1)
  })(((dailyUsers.map(item => item['amount'])).reduce(function(x, y) {return x + y;}) / dailyUsers.length))),[`chart`]: (await (async function(data) {
  	return new Chart(
  	  document.getElementById('signed-up'),
  	  {
  	    type: 'bar',
  	    data: {
  	      hidden: true,
  	      labels: data.map(row => row.x),
  	      datasets: [{
  	        data
  	      }],
  	      parsing: {
  	        yAxisKey: 'y',
  	        xAxisKey: 'x',
  	      },
  	    },
  	    options: {
  	      maintainAspectRatio: false,
  	      scales: {
  	        x: {
  	          // display: false
  	          grid: {
  	            display: false,
  	            drawBorder: false,
  	          },
  	          ticks: {
  	            autoSkip: false,
  	            maxRotation: 0
  	          },
  	          border: {
  	            display: false
  	          }
  	        },
  	        y: {
  	          display: false,
  	        }
  	      },

  	      elements: {
  	        bar: {
  	          hoverBackgroundColor: '#B29A6F',
  	          backgroundColor: '#D9D9D9',
  	          borderRadius: 3,
  	          borderSkipped: false
  	        }
  	      },

  	      plugins: {
  	        legend: {
  	          display: false
  	        },
  	        tooltip: {
  	          displayColors: false,
  	          callbacks: {
  	            title: () => ''
  	          }
  	        }
  	      },

  	      barThickness: 6,

  	      onHover: (event, chartEl) => {
  	        event.native.target.style.cursor = chartEl[0] ? 'pointer': 'default'
  	      }
  	    }
  	  }
  	);
  })((await Promise.all(users.map(async item => {; return ({ [`x`]: (getObjectProperty(item, 'label')),[`y`]: (getObjectProperty(item, 'amount')) });}))))) }));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/afef10720c6199cc45e48426620612cd/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var yesterdayAmountStr, todayAmountStr, period, averageStr, amount, item, ledger, j, groupBy, averageDivider, date, months, days, obj, i, averageAmount, monthOrders, yesterdayAmount, yesterdayOrders, todayAmount, todayOrders, dailyLedger, filter, list, created, nextObj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function chechTodayDate(list) {
  created = (getObjectProperty((list.slice(-1)[0]), 'created'));
  if ((new Date(created).getUTCDate()) != (new Date((new Date())).getUTCDate())) {
    addItemToList(list, ({ [`amount`]: 0,[`date`]: [(new Date((new Date())).getFullYear()),'-',(new Date((new Date())).getMonth() + 1) >= 10 ? (new Date((new Date())).getMonth() + 1) : String(0) + String(new Date((new Date())).getMonth() + 1),'-',(new Date((new Date())).getDate()) >= 10 ? (new Date((new Date())).getDate()) : String(0) + String(new Date((new Date())).getDate())].join(''),[`label`]: (new Date((new Date())).getDate()) >= 10 ? (new Date((new Date())).getDate()) : String(0) + String(new Date((new Date())).getDate()) }));
  }
}

/**
 * Describe this function...
 */
async function setBaseDate() {
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getChartData() {
  return await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getPurchasesChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((String('created > ') + String(date.valueOf()))),'groupBy': JSON.stringify('DATE(created)') }).send()), 1, async (item) => {


 return (getObjectProperty(item, 'date'));
})
}

/**
 * Describe this function...
 */
async function insertMissingDate() {
  obj = ledger[(i - 1)];
  nextObj = ledger[((i - 1) - 1)];
  date = (new Date((getObjectProperty(obj, 'date'))));
  date.setDate(((new Date(date).getDate()) - 1));
  if (nextObj && (new Date(date).getDate()) != (Number((getObjectProperty(nextObj, 'label')))) && ledger.length <= averageDivider) {
    ledger.splice((i - 1), 0, ({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
    await insertMissingDate();
  }
}


  await new Promise(r => setTimeout(r, 50 || 0));
  filter = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'));
  period = (getObjectProperty(filter, 'appPurchase'));
  averageDivider = 30;
  await setBaseDate();
  date.setDate(((new Date((new Date())).getDate()) - (averageDivider - 1)));
  groupBy = 'date(created)';
  dailyLedger = await getChartData();
  await chechTodayDate(dailyLedger);
  todayOrders = (getObjectProperty((dailyLedger.slice(-1)[0]), 'amount'));
  yesterdayOrders = (getObjectProperty((dailyLedger.slice(-2)[0]), 'amount'));
  monthOrders = (dailyLedger.map(item => item['amount'])).reduce(function(x, y) {return x + y;}) / dailyLedger.length;
  if (todayOrders) {
    todayAmount = Math.floor(todayOrders / 100);
    todayAmountStr = String('$ ') + String(todayAmount >= 1000 ? String((await (async function(number) {
    	return new Intl.NumberFormat("en-US").format(number)
    })((await (async function(number) {
    	return number.toFixed(1)
    })((todayAmount / 1000)))))) + String('k') : (await (async function(number) {
    	return new Intl.NumberFormat("en-US").format(number)
    })(todayAmount)));
  } else {
    todayAmountStr = '$ 0';
  }
  if (yesterdayOrders) {
    yesterdayAmount = Math.floor(yesterdayOrders / 100);
    yesterdayAmountStr = String('$ ') + String(yesterdayAmount >= 1000 ? String((await (async function(number) {
    	return new Intl.NumberFormat("en-US").format(number)
    })((await (async function(number) {
    	return number.toFixed(1)
    })((yesterdayAmount / 1000)))))) + String('k') : (await (async function(number) {
    	return new Intl.NumberFormat("en-US").format(number)
    })(yesterdayAmount)));
  } else {
    yesterdayAmountStr = '$ 0';
  }
  if (monthOrders) {
    averageAmount = Math.floor(monthOrders / 100);
    averageStr = String('$ ') + String(averageAmount >= 1000 ? String((await (async function(number) {
    	return new Intl.NumberFormat("en-US").format(number)
    })((await (async function(number) {
    	return number.toFixed(1)
    })((averageAmount / 1000)))))) + String('k') : (await (async function(number) {
    	return new Intl.NumberFormat("en-US").format(number)
    })(averageAmount)));
  } else {
    averageStr = '$ 0';
  }
  await setBaseDate();
  if (period == 'day') {
    date.setDate(((new Date((new Date())).getDate()) - (averageDivider - 1)));
    groupBy = 'date(created)';
    ledger = await getChartData();
    await chechTodayDate(ledger);
    for (var j_index in ledger) {
      j = ledger[j_index];
      j['label'] = (subsequenceFromEndLast((getObjectProperty(j, 'date')), 1));
    }
    if (ledger.length < averageDivider) {
      var i_start = ledger.length;
      var i_inc = 1;
      if (i_start > 2) {
        i_inc = -i_inc;
      }
      for (i = i_start; i_inc >= 0 ? i <= 2 : i >= 2; i += i_inc) {
        await insertMissingDate();
      }
      while (ledger.length < averageDivider) {
        obj = ledger[0];
        date = (new Date((getObjectProperty(obj, 'date'))));
        date.setDate(((new Date(date).getDate()) - 1));
        ledger.unshift(({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
      }
    }
  } else if (period == 'week') {
    days = 7 * (averageDivider - 1);
    date.setDate(((new Date(date).getDate()) - ((new Date((new Date())).getDay()) + 1 + days)));
    groupBy = 'week(created)';
    ledger = await getChartData();
    for (var j_index2 in ledger) {
      j = ledger[j_index2];
      j['label'] = '';
    }
  } else if (period == 'month') {
    date.setMonth(((new Date((new Date())).getMonth()) - (averageDivider - 1)));
    groupBy = 'month(created)';
    ledger = await getChartData();
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var j_index3 in ledger) {
      j = ledger[j_index3];
      j['label'] = (months[((Number(((getObjectProperty(j, 'date')).slice(5, 7)))) - 1)]);
    }
  } else if (period == 'year') {
    date.setFullYear(((new Date((new Date())).getFullYear()) - (averageDivider - 1)));
    groupBy = 'year(created)';
    ledger = await getChartData();
    for (var j_index4 in ledger) {
      j = ledger[j_index4];
      j['label'] = ((getObjectProperty(j, 'date')).slice(0, 4));
    }
  }
  await (async function() {
  	const el = document.querySelector(".app-purchase");
  	let html = `<canvas id="app-purchase" height="200"></canvas>`;
  	el.insertAdjacentHTML("afterbegin", html);
  })();
  Object.assign(___arguments.context.dataModel, ({ [`chart`]: (await (async function(data) {
  	return new Chart(
  	  document.getElementById('app-purchase'),
  	  {
  	    type: 'bar',
  	    data: {
  	      hidden: true,
  	      labels: data.map(row => row.x),
  	      datasets: [{
  	        data,
  	        parsing: {
  	          yAxisKey: 'y',
  	          xAxisKey: 'x',
  	        }
  	      }]
  	    },
  	    options: {
  	      maintainAspectRatio: false,
  	      scales: {
  	        x: {
  	          // display: false,
  	          grid: {
  	            display: false,
  	            drawBorder: false,
  	          },
  	          ticks: {
  	            autoSkip: false,
  	            maxRotation: 0
  	          },
  	          border: {
  	            display: false
  	          }
  	        },
  	        y: {
  	          display: false,
  	        }
  	      },

  	      elements: {
  	        bar: {
  	          hoverBackgroundColor: '#B29A6F',
  	          backgroundColor: '#D9D9D9',
  	          borderRadius: 3,
  	          borderSkipped: false
  	        }
  	      },

  	      plugins: {
  	        legend: {
  	          display: false
  	        },
  	        tooltip: {
  	          displayColors: false,
  	          callbacks: {
  	            label: (context) => `${context.raw.amountStr}`,
  	            title: () => ''
  	          }
  	        }
  	      },

  	      barThickness: 6,

  	      onHover: (event, chartEl) => {
  	        event.native.target.style.cursor = chartEl[0] ? 'pointer': 'default'
  	      }
  	    }
  	  }
  	);
  })((await Promise.all(ledger.map(async item => {  amount = Math.floor((getObjectProperty(item, 'amount')) / 100);
  ; return ({ [`x`]: (getObjectProperty(item, 'label')),[`y`]: (getObjectProperty(item, 'amount')),[`amountStr`]: String('$ ') + String(amount >= 1000 ? String((await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((await (async function(number) {
  	return number.toFixed(1)
  })((amount / 1000)))))) + String('k') : (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(amount))) });}))))),[`average`]: averageStr,[`period`]: period,[`todayAmount`]: todayAmountStr,[`yesterdayAmount`]: yesterdayAmountStr }));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/34c4f16a89da192f52793aaa7754dbd8/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var amount, item, list, period, total, date, filter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 50 || 0));
  filter = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'));
  period = (getObjectProperty(filter, 'topLocation'));
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (period == 'day') {
  } else if (period == 'week') {
    date.setDate(((new Date(date).getDate()) - (new Date(date).getDay())));
  } else if (period == 'month') {
    date.setDate(1);
  } else if (period == 'year') {
    date.setDate(1);
    date.setMonth(0);
  } else {
    date = '';
  }
  list = (await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getLocationIncomeChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((date ? String('created >=') + String(date.valueOf()) : '')) }).send()), -1, async (item) => {


   return (getObjectProperty(item, 'amount'));
  }));
  total = String('$ ') + String(!list.length ? '0' : (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((Math.floor((list.map(item => item['amount'])).reduce(function(x, y) {return x + y;}) / 100)))));
  await (async function() {
  	const el = document.querySelector(".top-locations");
  	let html = `<canvas id="top-locations" height="140px" width="140px"></canvas>`;
  	el.insertAdjacentHTML("afterbegin", html);
  })();
  Object.assign(___arguments.context.dataModel, ({ [`total`]: total,[`locations`]: list.slice(0, 3),[`period`]: period,[`chart`]: (await (async function(data) {
  	return new Chart(
  	  document.getElementById('top-locations'),
  	  {
  	    type: 'doughnut',
  	    data: {
  	      hidden: true,
  	      labels: data.map(row => row.x),
  	      datasets: [{
  	        data,

  	      }]
  	    },
  	    options: {
  	      borderWidth: 0,
  	      backgroundColor: ['#B29A6F', '#B29A6F80', '#B29A6F54', '#B29A6F1A'],
  	      cutout: '80%',
  	      parsing: {
  	        key: 'y'
  	      },
  	      scales: {
  	        x: {
  	          display: false,
  	        },
  	        y: {
  	          display: false,
  	        }
  	      },

  	      plugins: {
  	        legend: {
  	          display: false
  	        },
  	        tooltip: {
  	          displayColors: false,
  	          callbacks: {
  	            label: (context) => `${context.raw.amountStr}`,
  	            title: () => ''
  	          }
  	        }
  	      },

  	      onHover: (event, chartEl) => {
  	        event.native.target.style.cursor = chartEl[0] ? 'pointer': 'default'
  	      }
  	    }
  	  }
  	);
  })((await Promise.all((!list.length ? [({ [`name`]: 'placeholder',[`amount`]: 0 })] : (list.length > 3 ? (addItemToList((list.slice(0, 3)), ({ [`name`]: 'other',[`amount`]: ((list.slice(3, list.length)).map(item => item['amount'])).reduce(function(x, y) {return x + y;}) }))) : list.slice(0, list.length))).map(async item => {  if (getObjectProperty(item, 'amount')) {
      amount = Math.floor((getObjectProperty(item, 'amount')) / 100);
    }
  ; return ({ [`x`]: (getObjectProperty(item, 'name')),[`y`]: (getObjectProperty(item, 'amount')) ? (getObjectProperty(item, 'amount')) : 1,[`amountStr`]: amount ? (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(amount)) : 0 });}))))) }));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/db8d5a2add09957576b9ea7569589a44/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var order;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['amountStr'] = (String('$ ') + String((await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(((getObjectProperty(___arguments.context.dataModel, 'amount')) / 100)))));
  order = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('34c4f16a89da192f52793aaa7754dbd8'), 'locations')).map(item => item['name'])).indexOf(getObjectProperty(___arguments.context.dataModel, 'name')) + 1;
  ___arguments.context.dataModel['order'] = order;
  if ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('721b06cf58de03568ae8671ae6c43714')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('721b06cf58de03568ae8671ae6c43714'))['opacity'] = (1 / order);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/49cafef65ca343f500fe2b472491520f/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var amount, income, order, list, taxes, price, j, modPrices, basePrice, item, taxList, variations, k, usedMods, selectedMods, modKeys, mods, i, date, period;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  period = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0d7d3baa6edc3ef5bbff587d2a5316ad'), 'period'));
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (period == 'day') {
  } else if (period == 'week') {
    date.setDate(((new Date(date).getDate()) - (new Date(date).getDay())));
  } else if (period == 'month') {
    date.setDate(1);
  } else if (period == 'year') {
    date.setDate(1);
    date.setMonth(0);
  } else {
    date = '';
  }
  amount = (getObjectProperty(___arguments.context.dataModel, 'amount'));
  order = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0d7d3baa6edc3ef5bbff587d2a5316ad'), 'items')).map(item => item['name'])).indexOf(getObjectProperty(___arguments.context.dataModel, 'name')) + 1;
  Object.assign(___arguments.context.dataModel, ({ [`order`]: order,[`profitStr`]: 'calculating ...',[`amountStr`]: String(amount) + String(amount == 1 ? ' item' : ' items') }));
  list = [];
  var i_inc = 100;
  if (0 > amount) {
    i_inc = -i_inc;
  }
  for (i = 0; i_inc >= 0 ? i <= amount : i >= amount; i += i_inc) {
    addItemToList(list, (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChartItems`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((String(['name = \'',(getObjectProperty(___arguments.context.dataModel, 'name')),'\''].join('')) + String(date ? String(' AND ') + String(String('created >= ') + String(date.valueOf())) : ''))),'offset': i }).send()));
  }
  while (!((getObjectProperty(___arguments.context.appData, 'taxList')) && (getObjectProperty(___arguments.context.appData, 'modifierList')) && (getObjectProperty(___arguments.context.appData, 'squareItems')))) {
    await new Promise(r => setTimeout(r, 50 || 0));
  }
  taxList = (getObjectProperty(___arguments.context.appData, 'taxList'));
  mods = (getObjectProperty(___arguments.context.appData, 'modifierList'));
  variations = (getObjectProperty((await (async function(list, id) {
  	return list.find(item => item.id === id)
  })((getObjectProperty(___arguments.context.appData, 'squareItems')), (getObjectProperty(___arguments.context.dataModel, 'squareId')))), 'item_data.variations'));
  usedMods = (await (async function(arr) {
  	return arr.flat()
  })(((mods.map(item => item['modifier_list_data'])).map(item => item['modifiers']))));
  for (var j_index in list) {
    j = list[j_index];
    selectedMods = ((await (async function(arr) {
    	return arr.flat()
    })((await (async function(object) {
    	return Object.values(object)
    })((getObjectProperty(j, 'selectedModifier')))))).map(item => item['catalog_object_id']));
    modKeys = (await (async function(object) {
    	return Object.keys(object)
    })((getObjectProperty(j, 'selectedModifier'))));
    modPrices = [];
    var k_list = (await (async function(arr) {
    	return [...new Set(arr)]
    })(selectedMods));
    for (var k_index in k_list) {
      k = k_list[k_index];
      addItemToList(modPrices, ((await asyncListFilter(selectedMods, async (item) => {


       return (item == k);
      })).length * (getObjectProperty((await (async function(list, id) {
      	return list.find(item => item.id === id)
      })(usedMods, k)), 'modifier_data.price_money.amount'))));
    }
    basePrice = (getObjectProperty((await (async function(list, id) {
    	return list.find(item => item.id === id)
    })(variations, (getObjectProperty(j, 'selectedVariation')))), 'item_variation_data.price_money.amount'));
    taxes = (await Promise.all((((await asyncListFilter(taxList, async (item) => {


     return ((getObjectProperty(item, 'present_at_location_ids')).includes((getObjectProperty(j, 'locationId'))));
    })).map(item => item['tax_data'])).map(item => item['percentage'])).map(async item => {; return (Number(item));})));
    price = !modPrices.length ? basePrice : basePrice + modPrices.reduce(function(x, y) {return x + y;});
    j['price'] = (Math.round(price + (price / 100) * taxes.reduce(function(x, y) {return x + y;})));
  }
  income = (list.map(item => item['price'])).reduce(function(x, y) {return x + y;});
  ___arguments.context.dataModel['profitStr'] = (['$ ',(await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((income / 100))),' in total'].join(''));

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dashboard/components/e5487cb18a8ae1848aaa1849163820b9/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var amount, item, chartData, list, type, j, transactions, i, currentHour, startHour, date, whereClause, filter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 50 || 0));
  filter = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'));
  type = (getObjectProperty(filter, 'transaction'));
  if (type == 'fill') {
    whereClause = 'type = \'Fill up the balance\'';
  } else if (type == 'payment') {
    whereClause = 'type = \'Order\'';
  } else {
    whereClause = '';
  }
  list = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getTransactionsChartList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify(whereClause) }).send());
  date = (new Date());
  date.setHours(((new Date(date).getHours()) - 5));
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  transactions = (await Promise.all((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getTransactionsChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((['created > ',(date.valueOf()),whereClause ? String(' AND ') + String(whereClause) : ''].join(''))) }).send()).map(async item => {; return ({ [`amount`]: (getObjectProperty(item, 'amount')),[`time`]: (new Date((getObjectProperty(item, 'created'))).getHours()) });})));
  chartData = [];
  currentHour = (new Date((new Date())).getHours());
  startHour = (new Date(date).getHours());
  if (startHour < currentHour) {
    var i_inc = 1;
    if (startHour > currentHour) {
      i_inc = -i_inc;
    }
    for (i = startHour; i_inc >= 0 ? i <= currentHour : i >= currentHour; i += i_inc) {
      addItemToList(chartData, ({ [`amount`]: 0,[`time`]: i }));
    }
  } else {
    var i_start = startHour - 24;
    var i_inc2 = 1;
    if (i_start > currentHour) {
      i_inc2 = -i_inc2;
    }
    for (i = i_start; i_inc2 >= 0 ? i <= currentHour : i >= currentHour; i += i_inc2) {
      addItemToList(chartData, ({ [`amount`]: 0,[`time`]: i + 24 }));
    }
  }
  for (var j_index in transactions) {
    j = transactions[j_index];
    item = (await (async function(list, searchItem) {
    	return list.find(item => item.time === searchItem.time /*&& item.date === searchItem.date*/)
    })(chartData, j));
    item['amount'] = ((getObjectProperty(item, 'amount')) + (getObjectProperty(j, 'amount')));
  }
  await (async function() {
  	const el = document.querySelector(".latest-transactions");
  	let html = `<canvas id="latest-transactions" height="200"></canvas>`;
  	el.insertAdjacentHTML("afterbegin", html);
  })();
  Object.assign(___arguments.context.dataModel, ({ [`type`]: type,[`list`]: list,[`chart`]: (await (async function(transactions, padding) {
  	const data = {
  	  hidden: true,
  	  labels: transactions.map(row => row.x),
  	  datasets: [{
  	    data: transactions,
  	    parsing: {
  	      yAxisKey: 'y',
  	      xAxisKey: 'x',
  	    },
  	    backgroundColor: '#B29A6F',
  	    borderColor: '#D9D9D9',
  	    borderRadius: 3,
  	    borderSkipped: false,
  	    barThickness: 6,
  	    minBarThickness: 0
  	  }]
  	};

  	const roundedProgressBar = {
  	  id: 'roundedProgressBar',
  	  beforeDraw(chart, args, options) {
  	    const {
  	      ctx,
  	      data,
  	      chartArea: {
  	        top,
  	        bottom,
  	        left,
  	        right,
  	        width,
  	        height
  	      },
  	      scales: {
  	        x,
  	        y
  	      }
  	    } = chart
  	    // const {ctx } = chart

  	    ctx.save()

  	    const barWidth = data.datasets[0].barThickness

  	    chart.getDatasetMeta(0).data.forEach((datapoint, index) => {

  	      // datapoint.x = left + (barWidth * index)

  	      ctx.beginPath()
  	      ctx.strokeStyle = data.datasets[0].borderColor
  	      ctx.fillStyle = data.datasets[0].borderColor
  	      ctx.lineWidth = barWidth
  	      ctx.lineJoin = "round"
  	      ctx.strokeRect(datapoint.x, top + 3, 1, height - 8)
  	    })

  	  }
  	}

  	const config = {
  	  type: 'bar',
  	  data,
  	  options: {
  	    maintainAspectRatio: false,
  	    scales: {
  	      x: {
  	        // display: false,
  	        grid: {
  	          display: false,
  	          drawBorder: false,
  	        },
  	        ticks: {
  	          padding: 10
  	        },
  	        border: {
  	          display: false
  	        }
  	      },
  	      y: {
  	        display: false,
  	      }
  	    },

  	    plugins: {
  	      legend: {
  	        display: false
  	      },
  	      tooltip: {
  	        displayColors: false,
  	        callbacks: {
  	          label: (context) => `${context.raw.amountStr}`,
  	          title: () => ''
  	        }
  	      }
  	    },

  	    onHover: (event, chartEl) => {
  	      event.native.target.style.cursor = chartEl[0] ? 'pointer': 'default'
  	    }
  	  },
  	  plugins: [roundedProgressBar]
  	};

  	return new Chart(
  	  document.getElementById('latest-transactions'),
  	  config
  	);
  })((await Promise.all(chartData.map(async item => {  amount = Math.floor((getObjectProperty(item, 'amount')) / 100);
  ; return ({ [`x`]: (getObjectProperty(item, 'time')) >= 12 ? String((getObjectProperty(item, 'time')) == 12 ? (getObjectProperty(item, 'time')) : (getObjectProperty(item, 'time')) - 12) + String(' PM') : String((getObjectProperty(item, 'time')) == 0 ? (getObjectProperty(item, 'time')) + 12 : (getObjectProperty(item, 'time'))) + String(' AM'),[`y`]: (getObjectProperty(item, 'amount')) == 0 ? null : (getObjectProperty(item, 'amount')) / 100,[`amountStr`]: String('$ ') + String(amount >= 1000 ? String((await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((await (async function(number) {
  	return number.toFixed(1)
  })((amount / 1000)))))) + String('k') : (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(amount))) });}))), null)) }));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/0d7d3baa6edc3ef5bbff587d2a5316ad/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var location2, date, period, filter, item, allLocationsList, newLocations, squareLocations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));

  } catch (error) {
    await getLocationsFromSquare();

  }
}


  ;(async () => {
      if (getObjectProperty(___arguments.context.appData, 'locations')) {
      allLocationsList = (getObjectProperty(___arguments.context.appData, 'locations'));
    } else {
      await getLocationsFromSquare();
      allLocationsList = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((squareLocations.map(item => item['id'])))));
      if (squareLocations.length > allLocationsList.length) {
        newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (await Promise.all((await asyncListFilter(squareLocations, async (item) => {


         return (!((allLocationsList.map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
        })).map(async item => {; return ({ [`squareId`]: (getObjectProperty(item, 'id')),[`name`]: (getObjectProperty(item, 'name')),[`hidden`]: (getObjectProperty(item, 'status')) == 'INACTIVE' });}))),'timestampId': (getObjectProperty((await (async function(list) {
        	return list.find(item => item.type === 'location')
        })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) }));
        addItemToList(allLocationsList, newLocations);
      }
      ___arguments.context.appData['locations'] = allLocationsList;
    }
    ___arguments.context.dataModel['locationSelect'] = ([({ [`value`]: 'all',[`label`]: 'All locations' })].concat((await Promise.all((await asyncListFilter(allLocationsList, async (item) => {


     return (!(getObjectProperty(item, 'hidden')));
    })).map(async item => {; return ({ [`value`]: (getObjectProperty(item, 'name')),[`label`]: (getObjectProperty(item, 'name')) });})))));

  })();
  await new Promise(r => setTimeout(r, 50 || 0));
  filter = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter.mostPurchase'));
  location2 = (getObjectProperty(filter, 'location'));
  period = (getObjectProperty(filter, 'period'));
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (period == 'day') {
  } else if (period == 'week') {
    date.setDate(((new Date(date).getDate()) - (new Date(date).getDay())));
  } else if (period == 'month') {
    date.setDate(1);
  } else if (period == 'year') {
    date.setDate(1);
    date.setMonth(0);
  } else {
    date = '';
  }
  Object.assign(___arguments.context.dataModel, ({ [`location`]: location2,[`period`]: period,[`items`]: (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getMostPurchasedChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((String(date ? String('created >= ') + String(date.valueOf()) : '') + String(location2 == 'all' ? '' : String(date ? ' AND ' : '') + String(['location = \'',location2,'\''].join(''))))) }).send()) }));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/f6f68129fcb94351ab6c1ef51868ebae/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var filter;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.appData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}


  filter = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('dashboardFilter'));
  if (!filter) {
    filter = ({ [`signedUp`]: 'day',[`appPurchase`]: 'day',[`topLocation`]: 'month',[`mostPurchase`]: ({ [`location`]: 'all',[`period`]: 'month' }),[`transaction`]: 'all' });
    localStorage.setItem('dashboardFilter', JSON.stringify(filter));
  }
  ___arguments.context.dataModel['filter'] = filter;
  if (!(getObjectProperty(___arguments.context.appData, 'modifierList'))) {
    await getModifiers();
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/13310da7913eebfe5f53798a2b0a8125/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var timeStr, timeDif;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['icon'] = ((getObjectProperty(___arguments.context.dataModel, 'type')) == 'Order' ? '/img/icons/admin_icons/money.svg' : '/img/icons/admin_icons/wallet.svg');
  ___arguments.context.dataModel['amountStr'] = (String('$ ') + String((await (async function(num) {
  	return num.toFixed(2)
  })(((getObjectProperty(___arguments.context.dataModel, 'amount')) / 100)))));
  timeDif = Math.floor(((((new Date()).valueOf()) - (getObjectProperty(___arguments.context.dataModel, 'created'))) / 1000) / 60);
  if (timeDif < 60) {
    timeStr = [timeDif,timeDif == 1 ? ' min' : ' mins',' ago'].join('');
  } else if (Math.floor(timeDif / 60) < 24) {
    timeStr = [Math.floor(timeDif / 60),Math.floor(timeDif / 60) == 1 ? ' hour' : ' hours',' ago'].join('');
  } else {
    timeStr = [Math.floor((timeDif / 60) / 24),Math.floor((timeDif / 60) / 24) == 1 ? ' hour' : ' hours',' ago'].join('');
  }
  ___arguments.context.dataModel['timeStr'] = timeStr;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dashboard/components/11a8115d9e3f5288acec04062ed7b265/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var amount, item, chartData, j, transactions, i, currentHour, startHour, date, whereClause, users, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (___arguments.value == 'fill') {
    whereClause = 'type = \'Fill up the balance\'';
  } else if (___arguments.value == 'payment') {
    whereClause = 'type = \'Order\'';
  } else {
    whereClause = '';
  }
  ___arguments.context.getComponentDataStoreByUid('e5487cb18a8ae1848aaa1849163820b9')['list'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getTransactionsChartList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify(whereClause) }).send());
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b22220d1016c308d7764b2fdfe02f8af', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b22220d1016c308d7764b2fdfe02f8af', true);
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))['transaction'] = ___arguments.value;
  localStorage.setItem('dashboardFilter', JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))));
  date = (new Date());
  date.setHours(((new Date(date).getHours()) - 5));
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  transactions = (await Promise.all((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getTransactionsChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((['created > ',(date.valueOf()),whereClause ? String(' AND ') + String(whereClause) : ''].join(''))) }).send()).map(async item => {; return ({ [`amount`]: (getObjectProperty(item, 'amount')),[`time`]: (new Date((getObjectProperty(item, 'created'))).getHours()) });})));
  chartData = [];
  currentHour = (new Date((new Date())).getHours());
  startHour = (new Date(date).getHours());
  if (startHour < currentHour) {
    var i_inc = 1;
    if (startHour > currentHour) {
      i_inc = -i_inc;
    }
    for (i = startHour; i_inc >= 0 ? i <= currentHour : i >= currentHour; i += i_inc) {
      addItemToList(chartData, ({ [`amount`]: 0,[`time`]: i }));
    }
  } else {
    var i_start = startHour - 24;
    var i_inc2 = 1;
    if (i_start > currentHour) {
      i_inc2 = -i_inc2;
    }
    for (i = i_start; i_inc2 >= 0 ? i <= currentHour : i >= currentHour; i += i_inc2) {
      addItemToList(chartData, ({ [`amount`]: 0,[`time`]: i + 24 }));
    }
  }
  for (var j_index in transactions) {
    j = transactions[j_index];
    item = (await (async function(list, searchItem) {
    	return list.find(item => item.time === searchItem.time /*&& item.date === searchItem.date*/)
    })(chartData, j));
    item['amount'] = ((getObjectProperty(item, 'amount')) + (getObjectProperty(j, 'amount')));
  }
  ___arguments.context.getComponentDataStoreByUid('e5487cb18a8ae1848aaa1849163820b9')['chart'] = (await (async function(chart, data) {
  	chart.data.datasets[0].data = data
  	chart.update()

  	return chart
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5487cb18a8ae1848aaa1849163820b9'), 'chart')), (await Promise.all(chartData.map(async item => {  amount = Math.floor((getObjectProperty(item, 'amount')) / 100);
  ; return ({ [`x`]: (getObjectProperty(item, 'time')) >= 12 ? String((getObjectProperty(item, 'time')) == 12 ? (getObjectProperty(item, 'time')) : (getObjectProperty(item, 'time')) - 12) + String(' PM') : String((getObjectProperty(item, 'time')) == 0 ? (getObjectProperty(item, 'time')) + 12 : (getObjectProperty(item, 'time'))) + String(' AM'),[`y`]: (getObjectProperty(item, 'amount')) == 0 ? null : (getObjectProperty(item, 'amount')) / 100,[`amountStr`]: String('$ ') + String(amount >= 1000 ? String((await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((await (async function(number) {
  	return number.toFixed(1)
  })((amount / 1000)))))) + String('k') : (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(amount))) });})))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/4428c3ba53953432d9a303341d7fc4d8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var date, period;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  period = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('0d7d3baa6edc3ef5bbff587d2a5316ad'), 'period'));
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (period == 'day') {
  } else if (period == 'week') {
    date.setDate(((new Date(date).getDate()) - (new Date(date).getDay())));
  } else if (period == 'month') {
    date.setDate(1);
  } else if (period == 'year') {
    date.setDate(1);
    date.setMonth(0);
  } else {
    date = '';
  }
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter.mostPurchase'))['location'] = ___arguments.value;
  localStorage.setItem('dashboardFilter', JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))));
  ___arguments.context.getComponentDataStoreByUid('0d7d3baa6edc3ef5bbff587d2a5316ad')['items'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getMostPurchasedChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((String(date ? String('created >= ') + String(date.valueOf()) : '') + String(___arguments.value == 'all' ? '' : String(date ? ' AND ' : '') + String(['location = \'',___arguments.value,'\''].join(''))))) }).send());
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bc413a4e9da8977cde4d393e1317ad7', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bc413a4e9da8977cde4d393e1317ad7', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/11e3dc2fdff3419148cd9e05523b57a5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (___arguments.value == 'day') {
  } else if (___arguments.value == 'week') {
    date.setDate(((new Date(date).getDate()) - (new Date(date).getDay())));
  } else if (___arguments.value == 'month') {
    date.setDate(1);
  } else if (___arguments.value == 'year') {
    date.setDate(1);
    date.setMonth(0);
  } else {
    date = '';
  }
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter.mostPurchase'))['period'] = ___arguments.value;
  localStorage.setItem('dashboardFilter', JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))));
  ___arguments.context.getComponentDataStoreByUid('0d7d3baa6edc3ef5bbff587d2a5316ad')['items'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getMostPurchasedChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((String(date ? String('created >= ') + String(date.valueOf()) : '') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0d7d3baa6edc3ef5bbff587d2a5316ad'), 'location')) == 'all' ? '' : String(date ? ' AND ' : '') + String(['location = \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('0d7d3baa6edc3ef5bbff587d2a5316ad'), 'location')),'\''].join(''))))) }).send());
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bc413a4e9da8977cde4d393e1317ad7', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2bc413a4e9da8977cde4d393e1317ad7', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/bd43a61fea4d1309f24331e6772a9e7c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var amount, item, list, total, date, period;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (___arguments.value == 'day') {
  } else if (___arguments.value == 'week') {
    date.setDate(((new Date(date).getDate()) - (new Date(date).getDay())));
  } else if (___arguments.value == 'month') {
    date.setDate(1);
  } else if (___arguments.value == 'year') {
    date.setDate(1);
    date.setMonth(0);
  } else {
    date = '';
  }
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))['topLocation'] = ___arguments.value;
  localStorage.setItem('dashboardFilter', JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))));
  list = (await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getLocationIncomeChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((date ? String('created >=') + String(date.valueOf()) : '')) }).send()), -1, async (item) => {


   return (getObjectProperty(item, 'amount'));
  }));
  total = String('$ ') + String(!list.length ? '0' : (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((Math.floor((list.map(item => item['amount'])).reduce(function(x, y) {return x + y;}) / 100)))));
  Object.assign(___arguments.context.getComponentDataStoreByUid('34c4f16a89da192f52793aaa7754dbd8'), ({ [`total`]: total,[`locations`]: list.slice(0, 3),[`chart`]: (await (async function(chart, data) {
  	chart.data.datasets[0].data = data
  	chart.update()

  	return chart
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('34c4f16a89da192f52793aaa7754dbd8'), 'chart')), (await Promise.all((!list.length ? [({ [`name`]: 'placeholder',[`amount`]: 0 })] : (list.length > 3 ? (addItemToList((list.slice(0, 3)), ({ [`name`]: 'other',[`amount`]: ((list.slice(3, list.length)).map(item => item['amount'])).reduce(function(x, y) {return x + y;}) }))) : list.slice(0, list.length))).map(async item => {  if (getObjectProperty(item, 'amount')) {
      amount = Math.floor((getObjectProperty(item, 'amount')) / 100);
    }
  ; return ({ [`x`]: (getObjectProperty(item, 'name')),[`y`]: (getObjectProperty(item, 'amount')) ? (getObjectProperty(item, 'amount')) : 1,[`amountStr`]: amount ? (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(amount)) : 0 });}))))) }));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('baf2e2f4972ebb49f11a4b127e1ba355', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('baf2e2f4972ebb49f11a4b127e1ba355', true);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/07ec146ea4747d6de0a3f5e800390e6e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var averageDivider, created, date, days, groupBy, i, item, j, list, months, nextObj, obj, users;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function chechTodayDate(list) {
  created = (getObjectProperty((list.slice(-1)[0]), 'created'));
  if ((new Date(created).getUTCDate()) != (new Date((new Date())).getUTCDate())) {
    addItemToList(list, ({ [`amount`]: 0,[`date`]: [(new Date((new Date())).getFullYear()),'-',(new Date((new Date())).getMonth() + 1) >= 10 ? (new Date((new Date())).getMonth() + 1) : String(0) + String(new Date((new Date())).getMonth() + 1),'-',(new Date((new Date())).getDate()) >= 10 ? (new Date((new Date())).getDate()) : String(0) + String(new Date((new Date())).getDate())].join(''),[`label`]: (new Date((new Date())).getDate()) >= 10 ? (new Date((new Date())).getDate()) : String(0) + String(new Date((new Date())).getDate()) }));
  }
}

/**
 * Describe this function...
 */
async function setBaseDate() {
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getChartData() {
  return await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getUsersChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((date ? String('created > ') + String(date.valueOf()) : '')),'groupBy': JSON.stringify(groupBy) }).send()), 1, async (item) => {


 return (getObjectProperty(item, 'created'));
})
}

/**
 * Describe this function...
 */
async function insertMissingDate() {
  obj = users[(i - 1)];
  nextObj = users[((i - 1) - 1)];
  date = (new Date((getObjectProperty(obj, 'date'))));
  date.setDate(((new Date(date).getDate()) - 1));
  if (nextObj && (new Date(date).getDate()) != (Number((getObjectProperty(nextObj, 'label')))) && users.length <= averageDivider) {
    users.splice((i - 1), 0, ({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
    await insertMissingDate();
  }
}


  averageDivider = 30;
  await setBaseDate();
  groupBy = 'date(created)';
  if (___arguments.value == 'day') {
    date.setDate(((new Date((new Date())).getDate()) - (averageDivider - 1)));
    groupBy = 'date(created)';
    users = await getChartData();
    for (var j_index in users) {
      j = users[j_index];
      j['label'] = (subsequenceFromEndLast((getObjectProperty(j, 'date')), 1));
    }
    await chechTodayDate(users);
    if (users.length < averageDivider) {
      var i_start = users.length;
      var i_inc = 1;
      if (i_start > 2) {
        i_inc = -i_inc;
      }
      for (i = i_start; i_inc >= 0 ? i <= 2 : i >= 2; i += i_inc) {
        await insertMissingDate();
      }
      while (users.length < averageDivider) {
        obj = users[0];
        date = (new Date((getObjectProperty(obj, 'date'))));
        date.setDate(((new Date(date).getDate()) - 1));
        users.unshift(({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
      }
    }
  } else if (___arguments.value == 'week') {
    days = 7 * (averageDivider - 1);
    date.setDate(((new Date(date).getDate()) - ((new Date((new Date())).getDay()) + 1 + days)));
    groupBy = 'week(created)';
    users = await getChartData();
    for (var j_index2 in users) {
      j = users[j_index2];
      j['label'] = '';
    }
  } else if (___arguments.value == 'month') {
    date.setMonth(((new Date((new Date())).getMonth()) - (averageDivider - 1)));
    groupBy = 'month(created)';
    users = await getChartData();
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var j_index3 in users) {
      j = users[j_index3];
      j['label'] = (months[((Number(((getObjectProperty(j, 'date')).slice(5, 7)))) - 1)]);
    }
  } else if (___arguments.value == 'year') {
    date.setFullYear(((new Date((new Date())).getFullYear()) - (averageDivider - 1)));
    groupBy = 'year(created)';
    users = await getChartData();
    for (var j_index4 in users) {
      j = users[j_index4];
      j['label'] = ((getObjectProperty(j, 'date')).slice(0, 4));
    }
  }
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))['signedUp'] = ___arguments.value;
  localStorage.setItem('dashboardFilter', JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))));
  if (users.length > averageDivider) {
    users = users.slice(users.length - averageDivider, users.length);
  }
  ___arguments.context.getComponentDataStoreByUid('75a3e43d05bdacda8984365deea137af')['chart'] = (await (async function(chart, data) {
  	console.log(data)
  	chart.data.labels = data.map(row => row.x)
  	chart.data.datasets[0].data = data
  	chart.update()
  	console.log(chart.data)
  	return chart
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('75a3e43d05bdacda8984365deea137af'), 'chart')), (await Promise.all(users.map(async item => {; return ({ [`x`]: (getObjectProperty(item, 'label')),[`y`]: (getObjectProperty(item, 'amount')) });})))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/dashboard/components/f4f1a055b2da1c1877e8aa076549441e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var amount, item, ledger, j, groupBy, averageDivider, date, months, days, i, created, nextObj, obj;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function subsequenceFromEndLast(sequence, at1) {
  var start = sequence.length - 1 - at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getChartData() {
  ledger = (await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getPurchasesChart`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'whereClause': JSON.stringify((date ? String('created > ') + String(date.valueOf()) : '')),'groupBy': JSON.stringify(groupBy) }).send()), 1, async (item) => {


   return (getObjectProperty(item, 'date'));
  }));
}

/**
 * Describe this function...
 */
async function insertMissingDate() {
  obj = ledger[(i - 1)];
  nextObj = ledger[((i - 1) - 1)];
  date = (new Date((getObjectProperty(obj, 'date'))));
  date.setDate(((new Date(date).getDate()) - 1));
  if (nextObj && (new Date(date).getDate()) != (Number((getObjectProperty(nextObj, 'label')))) && ledger.length <= averageDivider) {
    ledger.splice((i - 1), 0, ({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
    await insertMissingDate();
  }
}


  averageDivider = 30;
  date = (new Date());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (___arguments.value == 'day') {
    date.setDate(((new Date((new Date())).getDate()) - (averageDivider - 1)));
    groupBy = 'date(created)';
    await getChartData();
    created = (getObjectProperty((ledger.slice(-1)[0]), 'created'));
    if ((new Date(created).getUTCDate()) != (new Date((new Date())).getUTCDate())) {
      addItemToList(ledger, ({ [`amount`]: 0,[`date`]: [(new Date((new Date())).getFullYear()),'-',(new Date((new Date())).getMonth() + 1) >= 10 ? (new Date((new Date())).getMonth() + 1) : String(0) + String(new Date((new Date())).getMonth() + 1),'-',(new Date((new Date())).getDate()) >= 10 ? (new Date((new Date())).getDate()) : String(0) + String(new Date((new Date())).getDate())].join('') }));
    }
    for (var j_index in ledger) {
      j = ledger[j_index];
      j['label'] = (subsequenceFromEndLast((getObjectProperty(j, 'date')), 1));
    }
    if (ledger.length < averageDivider) {
      var i_start = ledger.length;
      var i_inc = 1;
      if (i_start > 2) {
        i_inc = -i_inc;
      }
      for (i = i_start; i_inc >= 0 ? i <= 2 : i >= 2; i += i_inc) {
        await insertMissingDate();
      }
      while (ledger.length < averageDivider) {
        obj = ledger[0];
        date = (new Date((getObjectProperty(obj, 'date'))));
        date.setDate(((new Date(date).getDate()) - 1));
        ledger.unshift(({ [`amount`]: 0,[`date`]: [(new Date(date).getFullYear()),'-',(new Date(date).getMonth() + 1) >= 10 ? (new Date(date).getMonth() + 1) : String(0) + String(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate())].join(''),[`label`]: (new Date(date).getDate()) >= 10 ? (new Date(date).getDate()) : String(0) + String(new Date(date).getDate()) }));
      }
    }
  } else if (___arguments.value == 'week') {
    days = 7 * (averageDivider - 1);
    date.setDate(((new Date(date).getDate()) - ((new Date((new Date())).getDay()) + 1 + days)));
    groupBy = 'week(created)';
    await getChartData();
    for (var j_index2 in ledger) {
      j = ledger[j_index2];
      j['label'] = '';
    }
  } else if (___arguments.value == 'month') {
    date.setMonth(((new Date((new Date())).getMonth()) - (averageDivider - 1)));
    groupBy = 'month(created)';
    await getChartData();
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var j_index3 in ledger) {
      j = ledger[j_index3];
      j['label'] = (months[((Number(((getObjectProperty(j, 'date')).slice(5, 7)))) - 1)]);
    }
  } else if (___arguments.value == 'year') {
    date.setFullYear(((new Date((new Date())).getFullYear()) - (averageDivider - 1)));
    groupBy = 'year(created)';
    await getChartData();
    for (var j_index4 in ledger) {
      j = ledger[j_index4];
      j['label'] = ((getObjectProperty(j, 'date')).slice(0, 4));
    }
  }
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))['appPurchase'] = ___arguments.value;
  localStorage.setItem('dashboardFilter', JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'filter'))));
  ___arguments.context.getComponentDataStoreByUid('afef10720c6199cc45e48426620612cd')['chart'] = (await (async function(chart, data) {
  	console.log(data)
  	chart.data.labels = data.map(row => row.x)
  	chart.data.datasets[0].data = data
  	chart.update()
  	console.log(chart.data)
  	return chart
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('afef10720c6199cc45e48426620612cd'), 'chart')), (await Promise.all(ledger.map(async item => {  amount = Math.floor((getObjectProperty(item, 'amount')) / 100);
  ; return ({ [`x`]: (getObjectProperty(item, 'label')),[`y`]: (getObjectProperty(item, 'amount')),[`amountStr`]: String('$ ') + String(amount >= 1000 ? String((await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })((await (async function(number) {
  	return number.toFixed(1)
  })((amount / 1000)))))) + String('k') : (await (async function(number) {
  	return new Intl.NumberFormat("en-US").format(number)
  })(amount))) });})))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/image/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var itemsLength, sortedItems, j, updateItem, itemsList, item, menuItems, result, offset, res;

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  if (await Backendless.UserService.isValidLogin()) {
    ___arguments.context.pageData['timestamps'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/image/components/d1f1f4fb93ae9a764d5a0681fd34b7a5/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869')['uploading'] = true;
  ___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869')['image'] = '';

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var item, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.uploadedFiles);
  ___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869')['image'] = ___arguments.uploadedFiles;
  ___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('d1f1f4fb93ae9a764d5a0681fd34b7a5');
  object = ({ 'squareId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869'), 'id')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869'), 'item_data.name')),'image': ___arguments.uploadedFiles });
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869'), 'objectId')) {
    object['objectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869'), 'objectId'));
  }
  item = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveImage`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(object)));
  console.log(item);
  ___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869')['objectId'] = (getObjectProperty(item, 'objectId'));

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      console.log('fail');
  ___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('d1f1f4fb93ae9a764d5a0681fd34b7a5');

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869'), 'item_data.name')).replace((new RegExp('\\s', 'g')), '_')).replace((new RegExp('\'', 'g')), '')) + String(subsequenceFromStartLast((getObjectProperty(___arguments.file, 'name')), (((getObjectProperty(___arguments.file, 'name')).lastIndexOf('.') + 1) - 1))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/image/components/f6f68129fcb94351ab6c1ef51868ebae/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var sortedItems, itemsLength, j, updateItem, itemsList, item, menuItems, result, offset, res;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  ___arguments.context.dataModel['searchResult'] = [];
  if (getObjectProperty(___arguments.context.appData, 'squareItems')) {
    sortedItems = (getObjectProperty(___arguments.context.appData, 'squareItems'));
  } else {
    await getMenus();
    sortedItems = (await asyncListSort(menuItems, 1, async (item) => {


     return (getObjectProperty(item, 'item_data.name'));
    }));
    ___arguments.context.appData['squareItems'] = sortedItems;
  }
  if (getObjectProperty(___arguments.context.appData, 'itemImages')) {
    itemsList = (getObjectProperty(___arguments.context.appData, 'itemImages'));
  } else {
    itemsList = [];
    await getItemImages(0);
    ___arguments.context.appData['itemImages'] = itemsList;
  }
  for (var j_index in itemsList) {
    j = itemsList[j_index];
    updateItem = (await (async function(list, squareId) {
    	return list.find(item => item.id === squareId)
    })(sortedItems, (getObjectProperty(j, 'squareId'))));
    if (updateItem) {
      updateItem['image'] = (getObjectProperty(j, 'image'));
      updateItem['objectId'] = (getObjectProperty(j, 'objectId'));
    }
  }
  ___arguments.context.dataModel['items'] = sortedItems;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8058f7e79974faac2d8ef2a4b3ba4771', sortedItems);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/image/components/5fe7a705c29d02a7a34ef958bee1315a/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c03afa896d8854261bd3c23e8feea869'), 'image')) : '/img/no-img.png')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/image/components/c03afa896d8854261bd3c23e8feea869/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('8058f7e79974faac2d8ef2a4b3ba4771')[(((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c03afa896d8854261bd3c23e8feea869'));

  },
  /* handler:onBeforeMount */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'searchValue')) && ((((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'searchValue')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1) ? ['item-images__item', 'active'] : ['item-images__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/image/components/423ce881045eb3c8272197367dcc3576/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var components, item;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  stopSetTimeout('searchTimer');

  ;(function() {
    const callback = async () => {
        components = (await asyncListFilter((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'items')), async (item) => {


     return ((((getObjectProperty(item, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf(((___arguments.value.replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1);
    }));
    ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['searchResult'] = components;
    if (!components.length) {
      ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['searchResultText'] = '0 of 0';
    } else {
      ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['selected'] = 1;
      ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')),' of ',components.length].join(''));
      (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('8058f7e79974faac2d8ef2a4b3ba4771')), (((getObjectProperty((components[0]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
    }

    };

    const timerId = 'searchTimer';
    const timerDelay = 100;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/image/components/9513fc261ba8293a843ff906e33bde4d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components, selected;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')) < components.length) {
      ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')) + 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['selected'] = 1;
    }
    ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('8058f7e79974faac2d8ef2a4b3ba4771')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/image/components/8c7ed1d846c82ce3acce6248c22e88d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')) > 1) {
      ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')) - 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['selected'] = (components.length);
    }
    ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('8058f7e79974faac2d8ef2a4b3ba4771')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/image/components/d80f776551d77f7c52624f8db83f8693/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['searchResult'] = [];
  ___arguments.context.getComponentDataStoreByUid('f6f68129fcb94351ab6c1ef51868ebae')['searchValue'] = '';
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8058f7e79974faac2d8ef2a4b3ba4771')), 'el'))['scrollTop'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var mainLocation, allLocationsList, item, activeLocationsList, newLocations, squareLocations, createdLocations, itemsLength, sortedItems, j, updateItem, itemsList, menuItems, result, offset, res;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.pageData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  if (await Backendless.UserService.isValidLogin()) {
    console.log(await Backendless.UserService.getUserRoles());
    ___arguments.context.pageData['timestamps'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    ___arguments.context.pageData['menuList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetMenus`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    console.log(getObjectProperty(___arguments.context.pageData, 'timestamps'));
    ___arguments.context.pageData['categoryImages'] = (await Backendless.Files.listing(encodePath('img/category'), '', false, 100, 0));
    ___arguments.context.pageData['offerImages'] = (await Backendless.Files.listing(encodePath('img/offer'), '', false, 100, 0));
    await getMenus();
    sortedItems = (await asyncListSort(menuItems, 1, async (item) => {


     return (getObjectProperty(item, 'item_data.name'));
    }));
    ___arguments.context.pageData['squareItems'] = sortedItems;
    ;(async () => {
        itemsList = [];
      await getItemImages(0);
      for (var j_index in itemsList) {
        j = itemsList[j_index];
        updateItem = (await (async function(list, squareId) {
        	return list.find(item => item.id === squareId)
        })(sortedItems, (getObjectProperty(j, 'squareId'))));
        if (updateItem) {
          updateItem['image'] = (getObjectProperty(j, 'image'));
          updateItem['objectId'] = (getObjectProperty(j, 'objectId'));
        }
      }
      itemsLength = sortedItems.length / 3;
      ___arguments.context.pageData['imagesContainer'] = ({  });
      (getObjectProperty(___arguments.context.pageData, 'imagesContainer'))['list1'] = (sortedItems.slice(0, itemsLength));
      (getObjectProperty(___arguments.context.pageData, 'imagesContainer'))['list2'] = (sortedItems.slice(((itemsLength + 1) - 1), itemsLength * 2));
      (getObjectProperty(___arguments.context.pageData, 'imagesContainer'))['list3'] = (sortedItems.slice(((itemsLength * 2 + 1) - 1), sortedItems.length));

    })();
    await getModifiers();
    await getLocationsFromSquare();
    allLocationsList = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((squareLocations.map(item => item['id'])))));
    if (squareLocations.length > allLocationsList.length) {
      newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (await Promise.all((await asyncListFilter(squareLocations, async (item) => {


       return (!((allLocationsList.map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
      })).map(async item => {; return ({ 'squareId': (getObjectProperty(item, 'id')),'name': (getObjectProperty(item, 'name')),'hidden': (getObjectProperty(item, 'status')) == 'INACTIVE' });}))),'timestampId': (getObjectProperty((await (async function(list) {
      	return list.find(item => item.type === 'location')
      })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) }));
      addItemToList(allLocationsList, newLocations);
    }
    activeLocationsList = (await asyncListFilter(allLocationsList, async (item) => {


     return ((getObjectProperty(item, 'isMain')) || !(getObjectProperty(item, 'hidden')));
    }));
    ___arguments.context.pageData['locationList'] = activeLocationsList;
    ___arguments.context.pageData['allLocationList'] = allLocationsList;
    ___arguments.context.pageData['locationSelect'] = (await Promise.all(allLocationsList.map(async item => {; return ({ 'value': (getObjectProperty(item, 'objectId')),'label': (getObjectProperty(item, 'name')) });})));
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['paymentsLocationSelect'] = (await Promise.all(activeLocationsList.map(async item => {; return ({ 'value': (getObjectProperty(item, 'squareId')),'label': (getObjectProperty(item, 'name')) });})));
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['paymentLocation'] = (getObjectProperty(((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373')), 'paymentsLocationSelect'))[0]), 'value'));
    mainLocation = (await (async function(list) {
    	return list.find(item => item.isMain)
    })(allLocationsList));
    ___arguments.context.pageData['mainLocationId'] = (getObjectProperty(mainLocation, 'objectId'));
    ___arguments.context.pageData['mainLocationSquareId'] = (getObjectProperty(mainLocation, 'squareId'));
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
    ___arguments.context.pageData['section'] = 'menu';
    ___arguments.context.pageData['months'] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/landing/components/1bbe429b40c9d4a733d964be9b8565d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var clonedMenu, k, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['editing'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'objectId')),'category': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) : [] });
  ___arguments.context.pageData['activeMenu'] = ___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368');
  ___arguments.context.pageData['activeScreen'] = 'category';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f64240780b5d739fd6e6666f1441413e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function showModal() {
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'saveCategory';
}

/**
 * Describe this function...
 */
async function changeScreen() {
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit') {
    if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
      await showModal();
    } else {
      await changeScreen();
    }
  } else {
    if ((await BackendlessUI.Functions.Custom['fn_c0a3f7f2c1b299c7fa9efbcfccfaf763'](___arguments.context.pageData))) {
      await showModal();
    } else {
      await changeScreen();
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/986adcc1402d905b0f177d556b1902f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'loaded'))) {
    ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a')['item'] = (await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetCategoryItems`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'categoryId': JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'objectId'))) }).send()), 1, async (item) => {


     return (getObjectProperty(item, 'order'));
    }));
    ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a')['loaded'] = true;
  }
  ___arguments.context.pageData['activeCategory'] = ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a');
  ___arguments.context.pageData['editingCategory'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'objectId')),'item': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'item')),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'image')) });
  ___arguments.context.pageData['activeScreen'] = 'categoryEdit';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/0beef512e1426846bafd37ed9d761f65/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.dataModel;
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.dataModel, 'name')),'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'modifier': (getObjectProperty(___arguments.context.dataModel, 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.dataModel, 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.dataModel, 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.dataModel, 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.dataModel, 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/5dba530cf0278ffdf5ead1f4895b6ada/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var item, addedIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addItem';
  ___arguments.context.pageData['uniqueItems'] = (getObjectProperty(___arguments.context.pageData, 'squareItems'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/5216e199f3447d92082afe38d2113d41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteItem';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/5239e6cb34e82ad2b5863dbdb167bb2e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['categoryToDelete'] = ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/febdc3c27952f9df956d827592e48717/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteMenu';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/64314f7464a6bad43488e3d84be7efd9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addMenu';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/19262a7e2927f06596277a061e05beda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/1e6780e56a12dc1e04f3b30a268105d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/09ee7b31196c7618e9438bb4d9766b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/67972eade3335ce9fbdf479ab15d7f6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newMenu, list, menuName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  menuName = (getObjectProperty(___arguments.context.pageData, 'newMenuName'));
  list = (getObjectProperty(___arguments.context.pageData, 'menuList'));
  if (menuName) {
    newMenu = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminCreateNewMenu`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'name': JSON.stringify(menuName) }).send());
    addItemToList(list, newMenu);
    delete ___arguments.context.pageData['newMenuName'];
    addItemToList((getObjectProperty(___arguments.context.pageData, 'timestamps')), (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(newMenu, 'objectId'))))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/5e23e373e2d47b6876070a18845ace2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/ca2fa06723b33b229969fa56df5fafb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/fffb1468d62269c33bb737b61e9b0659/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, list, categoryName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categoryName = (getObjectProperty(___arguments.context.pageData, 'newCategoryName'));
  list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  if (categoryName) {
    category = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'name': categoryName,'order': (list.length + 1),'menuId': (getObjectProperty(___arguments.context.pageData, 'editing.objectId')) }));
    category['item'] = [];
    addItemToList(list, category);
    await (async function() {
    	const category = [...document.querySelectorAll('.draggable')].slice(-1).pop();

    	category.draggable = true;
    	category.addEventListener('dragstart', (e) => {
    	  category.classList.add("is-dragging")
    	});

    	category.addEventListener('dragend', (e) => {
    	  category.classList.remove("is-dragging");
    	})
    })();
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
    delete ___arguments.context.pageData['newCategoryName'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/80451d7b74fdb049e2133bf6b39e6db7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  delete ___arguments.context.pageData['newItem'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/d2cdea06507901cd1ebfad2e5e7357d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['newItem'];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/81ef466fc0daa5438334ddd78906b37a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newItem, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'newItems')) && !!(getObjectProperty(___arguments.context.pageData, 'newItems')).length) {
    newItem = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'newItems')).map(async item => {; return ({ 'name': (getObjectProperty(item, 'item_data.name')),'squareId': (getObjectProperty(item, 'id')),'variation': ((getObjectProperty(item, 'item_data.variations')).map(item => item['id'])),'order': (getObjectProperty(___arguments.context.pageData, 'newItems')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).length });})));
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['item'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), newItem));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    delete ___arguments.context.pageData['newItems'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/2195e4413bb4651d9af9c0b5f35cc786/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c517adbd761c078868c23a0417cb4009/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var categories, items, j, menu;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categories = (getObjectProperty(___arguments.context.pageData, 'activeMenu.category'));
  menu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'menuList')), menu, 'objectId');
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(menu, 'objectId'))));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(menu, 'objectId'))))));
  if (categories && !!categories.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((categories.map(item => item['objectId']))));
    items = [];
    var j_list = (categories.map(item => item['item']));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(items, j);
    }
    if (!!items.length) {
      await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
    }
  }
  ___arguments.context.pageData['activeScreen'] = 'menu';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/4d497b345ff8661374350be53f762fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/81eb464908fa214371fad0fc9da7da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, items;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  category = (getObjectProperty(___arguments.context.pageData, 'categoryToDelete'));
  items = (getObjectProperty(category, 'item'));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(category, 'objectId'))]));
  if (!!items.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
  }
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editing.category')), category, 'objectId');
  await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  ___arguments.context.pageData['activeScreen'] = 'category';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f351bbec212b1a64f9bb319b2db7ed1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/04354a94d901e953b2dd83af23dfc6a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  item = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  if (getObjectProperty(item, 'objectId')) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'objectId');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'objectId');
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify());
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'order');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'order');
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  ___arguments.context.pageData['editItem'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/95ea0e42eeebb94276a411ce95679f5a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f738bbdb4e5be01adda1681b50387046', true);
  await new Promise(r => setTimeout(r, 3000 || 0));
  if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f738bbdb4e5be01adda1681b50387046')) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f738bbdb4e5be01adda1681b50387046'))['display'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c3e27d82d8e2dd71b48c331f6179b8fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'itemChanges')).length) {
    ___arguments.context.pageData['editItem'] = false;
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
    ___arguments.context.pageData['modal'] = 'saveItem';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/103bb4adbb33508c27bfd2d9ff6790a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, savedItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('00e7a064117b152b13ffce0241090eed', false);
  if (!(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('00e7a064117b152b13ffce0241090eed', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    try {
      savedItem = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveItem`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'variation': (getObjectProperty(___arguments.context.pageData, 'editingItem.variation')),'modifier': (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'editingItem.modifier')))),'objectId': (getObjectProperty(___arguments.context.pageData, 'editingItem.objectId')),'name': (getObjectProperty(___arguments.context.pageData, 'editingItem.name')),'image': (getObjectProperty(___arguments.context.pageData, 'editingItem.image')),'order': (getObjectProperty(___arguments.context.pageData, 'editingItem.order')),'squareId': (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.id')),'notesEnabled': (getObjectProperty(___arguments.context.pageData, 'editingItem.notesEnabled')) }))));
      Object.assign((getObjectProperty(___arguments.context.pageData, 'activeItem')), savedItem);
      Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));
      console.log(getObjectProperty(___arguments.context.pageData, 'editingCategory'));
      ___arguments.context.pageData['editItem'] = false;

    } catch (error) {
      await (async function(error) {
      	alert(`Something went wrong:\n ${error}`)
      })(error);

    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c2354b1d5cbb04b68e686f65e4f2329d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var newItems;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
  if (___arguments.value) {
    if (newItems) {
      addItemToList(newItems, ___arguments.context.dataModel);
    } else {
      ___arguments.context.pageData['newItems'] = [___arguments.context.dataModel];
      newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
    }
  } else {
    removeItemInList(newItems, ___arguments.context.dataModel, 'id');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'newItems')) && (((getObjectProperty(___arguments.context.pageData, 'newItems')).map(item => item['id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing/components/41679f5722fa99da566274fa92d88fc5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Edit ') + String(getObjectProperty(___arguments.context.pageData, 'activeItem.name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/89e4d46dbf7218df93e1f3fa2bec4626/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63d122c6aac6f8e76317c0af0696ad0e', true);
  await new Promise(r => setTimeout(r, 3000 || 0));
  if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('63d122c6aac6f8e76317c0af0696ad0e')) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('63d122c6aac6f8e76317c0af0696ad0e'))['display'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/68a513e8657f1f86451730ac519e999f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/26f1026ff85b2b28c52cbed88d31628a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/1799fbf1333349f910c697b5eb8ae39c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'itemChanges')).length) {
    ___arguments.context.pageData['editItem'] = false;
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
    ___arguments.context.pageData['modal'] = 'saveItem';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/8b737ccdaf9110cbaca2486f5b66d65c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8a4e6e1c84782adba95feedd7e491eaf'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/8a4e6e1c84782adba95feedd7e491eaf/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var id, itemChanges, variationList, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  variationList = (getObjectProperty(activeItem, 'variation'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (variationList.includes(id)) {
    removeItemInList(variationList, id, '');
  } else {
    addItemToList(variationList, id);
  }
  if (itemChanges.includes(id)) {
    removeItemInList(itemChanges, id, '');
  } else {
    addItemToList(itemChanges, id);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/0b1b67e2386aec0cc056e8978a931e7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierId, modifierListId, allModifiers, modifierOptions, activeItem, id, itemChanges;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  modifierId = (getObjectProperty(___arguments.context.dataModel, 'id'));
  modifierListId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'));
  allModifiers = (getObjectProperty(activeItem, 'modifier'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (allModifiers && ((Object.keys(allModifiers)).includes(modifierListId))) {
    modifierOptions = (getObjectProperty(allModifiers, modifierListId));
    if (modifierOptions.includes(modifierId)) {
      removeItemInList(modifierOptions, modifierId, '');
      if (!modifierOptions.length) {
        delete allModifiers[modifierListId];
      }
    } else {
      addItemToList(modifierOptions, modifierId);
    }
  } else {
    allModifiers[modifierListId] = [modifierId];
  }
  if (itemChanges.includes(modifierId)) {
    removeItemInList(itemChanges, modifierId, '');
  } else {
    addItemToList(itemChanges, modifierId);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var modifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/b1ee4c414c8998c21ae7eb6ca6a61b76/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0b1b67e2386aec0cc056e8978a931e7f'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/159bd412fc2f405825bb2cf9eb85d532/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'editImage';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/1ec7e0b98baf047f9aef6df2c20a3b11/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['categoryToDelete'] = (getObjectProperty(___arguments.context.pageData, 'editingCategory'));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/d3d5a474c709ae6bfc919e3250f71aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/9de2b727d0c49ad7ffe601c858dcf287/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c331492cf5738f93b85d32619a4f57f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/23144bc14fa4e6d42aec6ed66d7da7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/8665bb11608ea9cc4a16d186892a68c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/4813af6d506917b2fa7993d9713e7ce9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/cac1771cf2985c29f493f07d8404503a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/landing/components/979a5b4b8d832e33f19cacccab19ed0f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var activeMenu, menuList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeMenu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  menuList = (getObjectProperty(___arguments.context.dataModel, 'menu'));
  if (___arguments.value) {
    ___arguments.context.dataModel['menu'] = activeMenu;
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminAssignMenuToLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locationId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'menuId': (getObjectProperty(activeMenu, 'objectId')) });
  } else {
    delete ___arguments.context.dataModel['menu'];
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenuFromLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locationId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'menuId': (getObjectProperty(activeMenu, 'objectId')) });
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'menu')) && (getObjectProperty(___arguments.context.dataModel, 'menu.objectId')) == (getObjectProperty(___arguments.context.pageData, 'activeMenu.objectId')))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing/components/64857fd6e70c62b865fcc1424a7f2a32/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.category__list');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.objectId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editing.category')));

  },
  /* handler:onMounted */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var j, categories, menu, editing, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  editing = (getObjectProperty(___arguments.context.pageData, 'editing'));
  categories = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  editing['category'] = (await asyncListSort(categories, 1, async (item) => {


   return (getObjectProperty(item, 'order'));
  }));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/landing/components/04debe5e790704d2b4c89796d3ca2677/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, allModifiers, modifierId, modifierListId, modifierOptions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'))] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'modifier_list_data.modifiers')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/1434445d9e5999340fba3f0b895d8b14/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'))];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/6aa1ff2d4df14ec805c8339f4618538e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, id, variationList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  activeItem['variation'] = ((getObjectProperty(activeItem, 'squareObject.item_data.variations')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/00770631cfa6404945b9bb356763e13f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['variation'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/8e7abbdeee6840fb651b245837ef0fba/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'item')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/5a2defbc4875a0b7e1d535b24eb3ee83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) ? !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')).length : true)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/8c3ccce88fcdb265c83723bfa9c1e75a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    var j_list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'oldOrder')) {
        j['order'] = (getObjectProperty(j, 'oldOrder'));
        j['changed'] = false;
        delete j['oldOrder'];
      }
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/7ea3c78b76896a9f6a8bfe3b8467a838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
    await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/1f3e6b85e1da4e78f09d0552d3ac44da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/2ea8f662f17978e7b5b36f0b8b868237/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/7e92da65f1f758a852c2bcbdd48d8741/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('23aa26dfcdc16688b2065c0d25da2ca4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/cf2bc997cf9c1dba0748c8248f534a2e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/b211bf62b7d9f101e38aef0ce48c4e6a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/a9aaa9d1bd498382baf6d00f95f1f02a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'menu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/8bd7b99185b6ae3788dfce84b9d1b728/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu') {
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ac65e8de202b30c7ef2d83bd7be8675a')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/68a4020f2c832f04358838766e864a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/3678cc2ead6cad6dce141ea1ebbcb756/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/6526393763e3a4c7909bcc2a81665aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/e53b4263b63bbe926862d1f42016faca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/f167cc4a529265896f775dd1f58fcf4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/853de7c6b4c7d7081c68f5f3f27699a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/8528128c771cbd8e7a729a72e25e9622/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteMenu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/21b6da3853232976a77f5053705d4a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/e038155e367d4454206b7ceed51b3c38/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/32a38dc7f8399d5f6a37e115dc0cc44a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/1a5803e560248da7d0f7581b8a93db5b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'lto')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/b0d2bc649d62bc74a077376295241b9c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'menu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/b116e0c3fd0b99229caf59dc727aad92/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var curMonth;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'curMonth'))) {
    curMonth = String(new Date((new Date())).getMonth() + 1) + String(new Date((new Date())).getFullYear());
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, (new Date()));
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6']((new Date())));
      ___arguments.context.pageData['curMonth'] = curMonth;
    }
  }
  if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'calendar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/b534fbaf4a97aec2db355cdb919dc8d6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    date = (new Date());
    date.setDate(1);
    date.setFullYear((getObjectProperty(___arguments.context.pageData, 'year')));
    date.setMonth(((getObjectProperty(___arguments.context.pageData, 'month')) + 1));
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, date);
    curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6'](date));
    }
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/bb9374461fafeb16c413a8257add3729/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    date = (new Date());
    date.setFullYear((getObjectProperty(___arguments.context.pageData, 'year')));
    date.setMonth(((getObjectProperty(___arguments.context.pageData, 'month')) - 1));
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, date);
    curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6'](date));
    }
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/215cf642addae3297f43b7bba46c5d69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, (new Date()));
    curMonth = String(new Date((new Date())).getMonth() + 1) + String(new Date((new Date())).getFullYear());
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((new Date())).getMonth()) != (getObjectProperty(___arguments.context.pageData, 'month')) ? true : (new Date((new Date())).getFullYear()) != (getObjectProperty(___arguments.context.pageData, 'year')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/5126650d543e444e109719cfe7ef6668/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, offers, timestamp, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  today = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date())));
  if ((getObjectProperty(___arguments.context.dataModel, 'current')) && (getObjectProperty(___arguments.context.dataModel, 'timestamp')) >= today) {
    timestamp = (getObjectProperty(___arguments.context.dataModel, 'timestamp'));
    offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date(timestamp).getDate())));
    if (!offers) {
      (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth'))))[(new Date(timestamp).getDate())] = [];
      offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date(timestamp).getDate())));
    }
    ___arguments.context.pageData['activeDate'] = ___arguments.context.dataModel;
    ___arguments.context.pageData['offersTitle'] = (['Edit offers for ',(getObjectProperty(___arguments.context.pageData, 'months'))[((new Date(timestamp).getMonth() + 1) - 1)],' ',(getObjectProperty(___arguments.context.dataModel, 'date')),', ',(new Date(timestamp).getFullYear())].join(''));
    ___arguments.context.pageData['editingDate'] = ({ 'timestamp': timestamp,'offers': !offers.length ? [] : (await asyncListSort((await Promise.all(offers.map(async item => {; return (JSON.parse((JSON.stringify(item))));}))), 1, async (item) => {


     return (getObjectProperty(item, 'order'));
    })) });
    ___arguments.context.pageData['offersToDelete'] = [];
    ___arguments.context.pageData['tab'] = 'dayOffer';
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    var timestamp, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  timestamp = (getObjectProperty(___arguments.context.dataModel, 'timestamp'));
  today = (new Date());
  if ((new Date(today).getMonth() + 1) == (new Date(timestamp).getMonth() + 1) && (new Date(today).getFullYear()) == (new Date(timestamp).getFullYear()) && (new Date(today).getDate()) == (new Date(timestamp).getDate())) {
    ___arguments.context.dataModel['today'] = true;
  }

  },
  /* handler:onMounted */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'current')) && (getObjectProperty(___arguments.context.dataModel, 'timestamp')) >= (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date()))) ? ((getObjectProperty(___arguments.context.dataModel, 'today')) ? ['calendar__date', 'today'] : ['calendar__date']) : ['calendar__date', 'disabled'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/6bfec90cc41883d3ef91acc5545e7a83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'todaysOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/38cc2ab7137c43ce012215fafa838566/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, item, j, modifiers, offers, offersToDelete, selectedModifiers, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'offers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  offersToDelete = (getObjectProperty(___arguments.context.pageData, 'offersToDelete'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'offerDate': (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp')),'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'offers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing/components/147fb543216568d9044a403b78dd33c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/9741a2f63a781c339b7455042b7a2fcc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/a2fb35e6eb5ed781034fb01ed0c13555/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, offers, offersToDelete, ids, offersToCreate, item, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  timestamp = (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'));
  addItemToList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'offers')), 1, async (item) => {
     item['order'] = ((getObjectProperty(___arguments.context.pageData, 'offers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length);


   return (getObjectProperty(item, 'name'));
  })));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/6a2a5def5cef564788ff3486e38165bc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'current')) ? ['calendar__date_text', 'current'] : ['calendar__date_text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/7b2bd1ec04844548f0f5b324e6f8ca2e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  await Backendless.UserService.logout();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/3b7ca5aa8ac86a1dda15706d607f5aa4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length) {
    ___arguments.context.pageData['periodOffersList'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'periodOffers')), 1, async (item) => {
       item['order'] = ((getObjectProperty(___arguments.context.pageData, 'periodOffers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length);


     return (getObjectProperty(item, 'name'));
    }))));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/2347c3e33bd631fcb82155b1b63efee4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, offers, selectedModifiers, j, modifiers, item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'periodOffers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'periodOffers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing/components/d15846c1fa19d2c5bfc2071d973a670e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/33af7aa5b562cfc7e4bfcaaaa5911beb/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'current')) && (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'timestamp'))).getDate()))) ? (await asyncListSort((getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'timestamp'))).getDate()))), 1, async (item) => {


 return (getObjectProperty(item, 'order'));
})) : [])

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/landing/components/94a2c4e501c37ae46467178715faa1db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/95412ce446138ea82f1b0a2953127ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/de042b7c17155e92ca7b0324cfbf86f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffers')), ___arguments.context.getComponentDataStoreByUid('7bbeefa6fdc6e11dd6dc2fd798ad5749'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/3af29a84d03d50b21fe45a3ee8a3683a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'offers')), ___arguments.context.getComponentDataStoreByUid('552b29f96cbcda34a01a373cb661dd0b'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/7e97cdba7e76fe18b375ef73e14a1bd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  ___arguments.context.pageData['periodOffersList'] = [];
  ___arguments.context.pageData['tab'] = 'periodOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f6f68129fcb94351ab6c1ef51868ebae/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'image')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/fe54b685c39be297f8a507f6e4aff91d/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['uploading'] = true;
  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['image'] = '';

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('fe54b685c39be297f8a507f6e4aff91d');

  },
  /* handler:onUploadFail */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var item, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['image'] = ___arguments.uploadedFiles;
  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['uploading'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2f4b758b042f67611d55d7cfcb2f2831', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2f4b758b042f67611d55d7cfcb2f2831', true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('fe54b685c39be297f8a507f6e4aff91d');
  object = ({ 'squareId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'id')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'item_data.name')),'image': ___arguments.uploadedFiles });
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'objectId')) {
    object['objectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'objectId'));
  }
  item = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveImage`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(object)));
  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['objectId'] = (getObjectProperty(item, 'objectId'));

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'item_data.name')).replace((new RegExp('\\s', 'g')), '_')).replace((new RegExp('\'', 'g')), '')) + String(subsequenceFromStartLast((getObjectProperty(___arguments.file, 'name')), (((getObjectProperty(___arguments.file, 'name')).lastIndexOf('.') + 1) - 1))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/landing/components/2f4b758b042f67611d55d7cfcb2f2831/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'image')) : '/img/no-img.png')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing/components/b6699c0351cf444c0c7b028211892aba/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['uploading'] = true;
  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['image'] = '';

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var item, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['image'] = ___arguments.uploadedFiles;
  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['uploading'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e5ea31ebc9549e4cd9276edb8a6d3c', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e5ea31ebc9549e4cd9276edb8a6d3c', true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('b6699c0351cf444c0c7b028211892aba');
  object = ({ 'squareId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'id')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'item_data.name')),'image': ___arguments.uploadedFiles });
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'objectId')) {
    object['objectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'objectId'));
  }
  item = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveImage`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(object)));
  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['objectId'] = (getObjectProperty(item, 'objectId'));

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('b6699c0351cf444c0c7b028211892aba');

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'item_data.name')).replace((new RegExp('\\s', 'g')), '_')).replace((new RegExp('\'', 'g')), '')) + String(subsequenceFromStartLast((getObjectProperty(___arguments.file, 'name')), (((getObjectProperty(___arguments.file, 'name')).lastIndexOf('.') + 1) - 1))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/landing/components/d1f1f4fb93ae9a764d5a0681fd34b7a5/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['uploading'] = true;
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['image'] = '';

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var item, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.uploadedFiles);
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['image'] = ___arguments.uploadedFiles;
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['uploading'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dac599d445dab683c979b14ecbe8751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dac599d445dab683c979b14ecbe8751', true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('d1f1f4fb93ae9a764d5a0681fd34b7a5');
  object = ({ 'squareId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'id')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'item_data.name')),'image': ___arguments.uploadedFiles });
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'objectId')) {
    object['objectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'objectId'));
  }
  item = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveImage`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(object)));
  console.log(item);
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['objectId'] = (getObjectProperty(item, 'objectId'));

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      console.log('fail');
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('d1f1f4fb93ae9a764d5a0681fd34b7a5');

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'item_data.name')).replace((new RegExp('\\s', 'g')), '_')).replace((new RegExp('\'', 'g')), '')) + String(subsequenceFromStartLast((getObjectProperty(___arguments.file, 'name')), (((getObjectProperty(___arguments.file, 'name')).lastIndexOf('.') + 1) - 1))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/landing/components/27e5ea31ebc9549e4cd9276edb8a6d3c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'image')) : '/img/no-img.png')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing/components/4dac599d445dab683c979b14ecbe8751/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'image')) : '/img/no-img.png')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing/components/1a3683ceb692147e6a24c7dca66c72e3/bundle.js', [], () => ({
  /* content */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('img/items/') + String(getObjectProperty(___arguments.context.pageData, 'activeItem.objectId')))

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['image'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['upload'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('1a3683ceb692147e6a24c7dca66c72e3');

  },
  /* handler:onUploadSuccess */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.pageData['upload'] = true;

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      ___arguments.context.pageData['upload'] = true;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('1a3683ceb692147e6a24c7dca66c72e3');

  },
  /* handler:onUploadFail */
  /* content */
}))

define('./pages/landing/components/f4c9054be843a0964b99aca8ae9c9665/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('#item_list');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);
  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.squareId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/5e55ebfe0de58d894d6312c2ac2dc56e/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.dataModel, 'order'))) {
    ___arguments.context.dataModel['order'] = (((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).map(item => item['objectId'])).indexOf(getObjectProperty(___arguments.context.dataModel, 'objectId')) + 1);
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(___arguments.context.dataModel));
  }

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/e78ecc0f872f4c9a853383c68cd86e38/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers, squareObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e');
  squareObject = (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems'))));
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': squareObject,'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'image')) : (getObjectProperty(squareObject, 'image')),'order': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'order')),'showVariations': (getObjectProperty(squareObject, 'item_data.variations')).length > 1,'notesEnabled': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'notesEnabled')) });
  console.log(getObjectProperty(___arguments.context.pageData, 'editingItem'));
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/a064b75daaea8fafda4552c63f11eae9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var clonedMenu, j, k;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'));
  clonedMenu = (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368')))));
  clonedMenu['name'] = (String(getObjectProperty(clonedMenu, 'name')) + String(' - copy'));
  delete clonedMenu['objectId'];
  delete clonedMenu['created'];
  clonedMenu['objectId'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(clonedMenu))), 'objectId'));
  var j_list = (getObjectProperty(clonedMenu, 'category'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    delete j['objectId'];
    delete j['created'];
    var k_list = (getObjectProperty(j, 'item'));
    for (var k_index in k_list) {
      k = k_list[k_index];
      delete k['objectId'];
      delete k['created'];
    }
    j['objectId'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCopyCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(j))), 'objectId'));
  }
  await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminAssignCategoryToMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'menuId': (getObjectProperty(clonedMenu, 'objectId')),'categories': (getObjectProperty(clonedMenu, 'category')) });
  console.log(clonedMenu);
  addItemToList((getObjectProperty(___arguments.context.pageData, 'menuList')), clonedMenu);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f76c81716cdf482219c4e1116adaa5a6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'calendar')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/d58da494e6b59bef13a2339706a6154f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'dayOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/067b523acaef90acc9f71ea96cc6b276/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/7c335a8fa9a6969fdab1de75ed1d373b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['filteredSquareItems'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'squareItems')), async (item) => {


   return (!(((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
  }));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['offers'] = [];
  ___arguments.context.pageData['modal'] = 'todaysOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/b7e7f2b31fe2b0a81f4d8d7736b64734/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'image')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/3608c0c37214bd66f851fa2ab231086f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j, timestamp;


  ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c6f66dccefed7194b01575c63ea29f8e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  ___arguments.context.dataModel['dragId'] = (generateUUID().short());

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/4b4be3f608273237f7026a0c5fe861d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var offers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'name');
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId')) {
    offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'))).getDate())));
    removeItemInList(offers, ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId');
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteOffer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId'))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f2e60cedd8461306cf55af0501dd8c39/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  async ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return (await (async function(files, percent, width) {
	for (let index = 0; index < files.length; index++) {
	  const file = files[index]
	  const fileName = file.name

	  const content = await readFileAsDataURL(file)

	  const img = await getNewImage(content)

	  const elem = document.createElement('canvas')

	  const widthImage = !width ? img.width : img.width > width ? width : img.width
	  const scaleFactor = img.width > width ? width && width / img.width : 1
	  const heightImage = width ? img.height * scaleFactor : img.height

	  elem.width = widthImage
	  elem.height = heightImage

	  const ctx = elem.getContext('2d')

	  ctx.drawImage(img, 0, 0, widthImage, heightImage)

	  const blob = await getCanvasBlob(ctx.canvas, percent)

	  const newFile = new File([blob], fileName, {
	    type: 'image/jpeg',
	    lastModified: Date.now()
	  })

	  files[index] = newFile
	}

	async function readFileAsDataURL(file) {
	  const result = await new Promise((resolve) => {
	    const fileReader = new FileReader()

	    fileReader.onload = (e) => resolve(e.target.result)

	    fileReader.readAsDataURL(file)
	  })

	  return result
	}

	async function getNewImage(content) {
	  const result = await new Promise((resolve) => {
	    const img = new Image()

	    img.src = content

	    img.onload = () => resolve(img)
	  })

	  return result
	}

	async function getCanvasBlob(canvas, percent) {
	  const result = await new Promise((resolve) => {
	    canvas.toBlob((blob) => {
	      resolve(blob)
	    }, 'image/jpeg', percent)
	  })

	  return result
	}

	return files
})(___arguments.files, 1, 600))

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedOfferImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedOfferImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/landing/components/c2b51d85ca38df7c478af3ec9a3dd2ef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editOfferImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/afc9812e122100175a02defad0086d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['useImage'] = true;
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/50d646aff2df909d6f3cfa42834e46d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/5ab734948432b055f37bd2f5d79f49a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/fb3719614bf7550212476e5f7833e0b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editingOffer'] = ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e');
  ___arguments.context.pageData['modal'] = 'editOfferImage';
  ___arguments.context.pageData['selectedUrl'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/73bf13a62b99deee7c49d918cd4e9b65/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/f6f8336f7239d56e550334dc3c796739/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/fa79760dc4aee4548cf8b0b361de395c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/b79da0014b2eec1b63480ae3e545de5d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var ids, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ids = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewOffer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')))));
  (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth'))))[(new Date((getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'))).getDate())] = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetOffers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': ids,'offset': 0 }));
  ___arguments.context.pageData['tab'] = 'calendar';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/d19902d5fd5f9ce4fa16e4489901bf3b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['filteredSquareItems'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'squareItems')), async (item) => {


   return (!(((getObjectProperty(___arguments.context.pageData, 'periodOffersList')).map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
  }));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'periodOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/839a54593b6167c1ad32851aa5a75b69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editingOffer'] = ___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6');
  ___arguments.context.pageData['modal'] = 'editOfferImage';
  ___arguments.context.pageData['selectedUrl'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/8a097bb5315133112fe2c8b89aa54b7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c806826c9e1fd2a50732108339f22814/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'image')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/d5c59cac03b8c603972bee97e742c6ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, offset, res;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getOffers(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetOffers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': ids,'offset': offset }));
  addItemToList(offers, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getOffers(offset);
  }
}


  if ((getObjectProperty(___arguments.context.pageData, 'offerTo')) && (getObjectProperty(___arguments.context.pageData, 'offerFrom')) && !!(getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    listToSave = [];
    var i_end = ((getObjectProperty(___arguments.context.pageData, 'offerTo')) - (getObjectProperty(___arguments.context.pageData, 'offerFrom'))) / (1000 * 60 * 60 * 24);
    var i_inc = 1;
    if (0 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 0; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      date = (new Date((getObjectProperty(___arguments.context.pageData, 'offerFrom'))));
      date.setDate(((new Date((getObjectProperty(___arguments.context.pageData, 'offerFrom'))).getDate()) + i));
      var j_list = (getObjectProperty(___arguments.context.pageData, 'periodOffersList'));
      for (var j_index in j_list) {
        j = j_list[j_index];
        curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
        if (getObjectProperty(___arguments.context.pageData, curMonth)) {
          day = (new Date(date).getDate());
          if (!((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)) && (((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)).map(item => item['name'])).includes((getObjectProperty(j, 'name')))))) {
            addItemToList(listToSave, ({ 'name': (getObjectProperty(j, 'name')),'squareId': (getObjectProperty(j, 'squareId')),'offerDate': (date.valueOf()),'image': (getObjectProperty(j, 'image')),'useImage': (getObjectProperty(j, 'useImage')),'modifier': (getObjectProperty(j, 'modifier')),'variation': (getObjectProperty(j, 'variation')),'order': (getObjectProperty(j, 'order')) }));
          }
        }
      }
    }
    ids = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewOffer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(listToSave)));
    offers = [];
    await getOffers(0);
    for (var j_index2 in offers) {
      j = offers[j_index2];
      timestamp = (getObjectProperty(j, 'offerDate'));
      curMonth = String(new Date(timestamp).getMonth() + 1) + String(new Date(timestamp).getFullYear());
      if (getObjectProperty(___arguments.context.pageData, curMonth)) {
        day = (new Date(timestamp).getDate());
        if (getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)) {
          addItemToList((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)), j);
        } else {
          (getObjectProperty(___arguments.context.pageData, curMonth))[day] = [j];
        }
      }
    }
    ___arguments.context.pageData['tab'] = 'calendar';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/60f4db05f953036d6949e357b0532739/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['offerToMinDate'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));
  ___arguments.context.pageData['offerFrom'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));

  },
  /* handler:onChange */
  /* handler:onMinDateAssignment */
  async ['onMinDateAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date())))

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/landing/components/fcfeee7845d953e1520ddcf27b9b616e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['offerTo'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/00f33d138b7de4ca40bac2887c9ab3f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), ___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'name');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c394470b2df24abb927bf18e0676480d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'location')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/16797755ef44d4ecd81cfafed079fabc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminUpdateLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (getObjectProperty(___arguments.context.pageData, 'allLocationList')),'timestampId': (getObjectProperty((await (async function(list) {
    	return list.find(item => item.type === 'location')
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) });
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', true);
    await new Promise(r => setTimeout(r, 5000 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);

  } catch (error) {
    await (async function(error) {
    	alert(error)
    })(error);
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/74c24fa4099d67d57e258872cdb6126e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e');
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  console.log(getObjectProperty(___arguments.context.pageData, 'itemModifiers'));
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/8ab8f7b3f5d15cd8a80dbffd0711681c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19a73d3d203b2d138072f2c3c62496c8', false);
  if (!(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19a73d3d203b2d138072f2c3c62496c8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    try {
      Object.assign((getObjectProperty(___arguments.context.pageData, 'activeItem')), (getObjectProperty(___arguments.context.pageData, 'editingItem')));
      Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));
      ___arguments.context.pageData['editItem'] = false;

    } catch (error) {
      await (async function(error) {
      	alert(`Something went wrong:\n ${error}`)
      })(error);

    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/00cb893644f585c7572572d13c6efe7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['editItem'] = false;
  Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/4f77e65bbe84d88318a4fafb420ec41f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var id, itemChanges, variationList, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  variationList = (getObjectProperty(activeItem, 'variation'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (variationList.includes(id)) {
    removeItemInList(variationList, id, '');
  } else {
    addItemToList(variationList, id);
  }
  if (itemChanges.includes(id)) {
    removeItemInList(itemChanges, id, '');
  } else {
    addItemToList(itemChanges, id);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/998f58c7c6641cb6ce19ecc9536e079a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierId, itemChanges, modifierListId, allModifiers, modifierOptions, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  modifierId = (getObjectProperty(___arguments.context.dataModel, 'id'));
  modifierListId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'));
  allModifiers = (getObjectProperty(activeItem, 'modifier'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (allModifiers && ((Object.keys(allModifiers)).includes(modifierListId))) {
    modifierOptions = (getObjectProperty(allModifiers, modifierListId));
    if (modifierOptions.includes(modifierId)) {
      removeItemInList(modifierOptions, modifierId, '');
      if (!modifierOptions.length) {
        delete allModifiers[modifierListId];
      }
    } else {
      addItemToList(modifierOptions, modifierId);
    }
  } else {
    allModifiers[modifierListId] = [modifierId];
  }
  if (itemChanges.includes(modifierId)) {
    removeItemInList(itemChanges, modifierId, '');
  } else {
    addItemToList(itemChanges, modifierId);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/5013c39ad5a1b3db9e63c2ce5cef0fc2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  activeItem['variation'] = ((getObjectProperty(activeItem, 'squareObject.item_data.variations')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/a233aaf688a8a74693e86b1b7a22da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['variation'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/4726ff1894aa7b9416d28fe8ec9c5e18/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'))] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'modifier_list_data.modifiers')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/295bb519abb50f1f809bc1c7e2a78845/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'))];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/9bcd3286b8c3c68534e1684d7312dc28/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['editItem'] = false;
  Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/b68823b496468bf4585512b1806d42b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6');
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/5cc6ff837fda7407bbfc43bcc9082417/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.value);
  ___arguments.context.pageData['mainLocationId'] = ___arguments.value;
  var j_list = (getObjectProperty(___arguments.context.pageData, 'allLocationList'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['isMain'] = false;
  }
  (await (async function(list, id) {
  	return list.find(item => item.objectId === id)
  })((getObjectProperty(___arguments.context.pageData, 'allLocationList')), ___arguments.value))['isMain'] = true;
  console.log(getObjectProperty(___arguments.context.pageData, 'allLocationList'));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/4d6a79f8110c24cebdc2be92c924caa7/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.offers');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.dragId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/e03bc62323b6c7328c4181da3674c527/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.offers');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.dragId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'periodOffersList')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/48d68e29ab03cfd12e9712e9c7eeb6a6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  ___arguments.context.dataModel['dragId'] = (generateUUID().short());

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/953bd551accbc806b9dbfad62dabc57a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'customers')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['pageSize'] = 10;
  ___arguments.context.dataModel['pageOffset'] = 0;
  ___arguments.context.dataModel['pageNumber'] = ((getObjectProperty(___arguments.context.dataModel, 'pageOffset')) / (getObjectProperty(___arguments.context.dataModel, 'pageSize')) + 1);
  ___arguments.context.dataModel['sortField'] = 'name';
  ___arguments.context.dataModel['sortOrder'] = 'asc';
  customersAmount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('phoneNumber LIKE \'+%\'')));
  ___arguments.context.dataModel['customersAmount'] = customersAmount;
  ___arguments.context.dataModel['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.dataModel, 'pageSize'))));
  ___arguments.context.dataModel['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.dataModel));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/45ef1d17d00bc17c481681125e240fc4/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('45ef1d17d00bc17c481681125e240fc4'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('45ef1d17d00bc17c481681125e240fc4'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'name';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/2216fcbab4ffd18264bf82611f683fa1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'phoneNumber';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2216fcbab4ffd18264bf82611f683fa1'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2216fcbab4ffd18264bf82611f683fa1'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/80826327cf89e0d94f6623fddef1f9c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'email';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('80826327cf89e0d94f6623fddef1f9c4'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('80826327cf89e0d94f6623fddef1f9c4'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/landing/components/63647a6e2bfb5723de1fff5242be3a2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f3157869327e8f061aa9f4cb6a8ea87c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > 1 && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/c4510d57e82c46f6a1d407a9ca6de024/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var value;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  value = (Number((___arguments.value.replace((new RegExp('\\D', 'g')), ''))));
  if (value <= (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages')) && value >= 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = value;
  } else if (value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
  } else if (value < 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = 1;
  }

  },
  /* handler:onChange */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.keyCode == 13) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) - 1) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/landing/components/38fdc0d564cb93555f7b3af990942bbd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteUser')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/5654f8e9daed33de6d7d2575f251f3e1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['userToDelete'] = ___arguments.context.getComponentDataStoreByUid('bb7c09942da32af24212a83b96738f39');
  ___arguments.context.pageData['modal'] = 'deleteUser';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/d462ba7e784a2bfbf4fb69ebd36d9463/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/7ffbfd02194122b2b9fa64d5203f74a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(___arguments.context.pageData, 'userToDelete.objectId')) });
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/b989cd8feda832fee987dd98dacc0d5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['searchText'] = '';
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil((await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((String('phoneNumber LIKE \'+%\'') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''))))) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/705a38190973ff4b085f87d423dc1da3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    customersAmount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['phoneNumber LIKE \'+%\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'showRegistered')) ? [' AND ','name IS NOT NULL',' AND ','email IS NOT NULL'].join('') : '',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''].join('')))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/640837d3a097dfdcd91892e2645b96eb/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.dataModel, 'hidden')))

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.dataModel['hidden'] = (!___arguments.value);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/94f9727936598f1c1675cd4bafc5b373/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var date, endDay, startDay, monthSummary, item, lastReconciliationDate, lastReconciliation, locationId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['loading'] = true;
  await new Promise(r => setTimeout(r, 1000 || 0));
  while (!(getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
  lastReconciliation = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetLastReconDate`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.dataModel['lastReconciliation'] = lastReconciliation;
  lastReconciliationDate = (new Date((getObjectProperty(lastReconciliation, 'lastReconciliationDate'))));
  ___arguments.context.dataModel['lastReconciliationDateStr'] = (String('Last reconciliation date: ') + String(lastReconciliationDate.toLocaleDateString()));
  console.log(getObjectProperty(___arguments.context.dataModel, 'lastReconciliation'));
  ___arguments.context.dataModel['paymentSummary'] = (await BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b']());
  Object.assign(___arguments.context.dataModel, (await BackendlessUI.Functions.Custom['fn_fcec57351c91061d5927992ed935b2e9']((getObjectProperty(___arguments.context.dataModel, 'paymentSummary')), (getObjectProperty(___arguments.context.pageData, 'mainLocationId')))));
  ___arguments.context.dataModel['csvList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetCsvList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  console.log(getObjectProperty(___arguments.context.pageData, 'mainLocationId'));
  console.log(monthSummary);
  ___arguments.context.dataModel['loading'] = false;

  },
  /* handler:onMounted */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'transactions')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing/components/227d107f05ca7f016b8c72d53de80dd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/e533a22258fc99c6cb42074626642be2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54'](___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/93ea3b49c933286acc41dd122d8d41db/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), ___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/f10651b748adc02bd2a1989aaa4e030e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
    date = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate'));
    date.setMonth(((new Date(date).getMonth()) - 1));
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentsDate'] = date;
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['timeInterval'] = (await BackendlessUI.Functions.Custom['fn_67559c72e94d48924fe326d47e282977'](date));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
    }
    Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), date, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/d356090b202f94494949ac6df60e6779/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
    date = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate'));
    date.setMonth(((new Date(date).getMonth()) + 1));
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentsDate'] = date;
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['timeInterval'] = (await BackendlessUI.Functions.Custom['fn_67559c72e94d48924fe326d47e282977'](date));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
    }
    Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), date, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/ada9a22587a6580a47bc3a48e22f01d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ada9a22587a6580a47bc3a48e22f01d8'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['type'] = 'income';
  await new Promise(r => setTimeout(r, 1 || 0));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/eff17117d9eb48be484da79902aa01c3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['type'] = 'outgoing';
  await new Promise(r => setTimeout(r, 1 || 0));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'outgoing' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/3e8b6c2f40af464cbb6b024c9abee553/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var card;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance') {
    card = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'customer')), 'walletId'))) }).send());
    (getObjectProperty(___arguments.context.pageData, 'customer'))['wallet'] = card;
    (getObjectProperty(___arguments.context.pageData, 'customer'))['balance'] = (getObjectProperty(card, 'balance_money.amount'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a591c5686d8d9cb355e7eba9fb40ecc', true);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(card, 'balance_money.amount')) / 100)))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94912c8669c55c478fbc6851df1ce87d', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing/components/9cb3eba81d1c1565c465748db4d568f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var user;


  ___arguments.context.pageData['customer'] = ___arguments.context.getComponentDataStoreByUid('bb7c09942da32af24212a83b96738f39');
  ___arguments.context.pageData['modal'] = 'balance';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/12c213fd49647dcefcfeceac2d54c56e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/bb7c09942da32af24212a83b96738f39/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/aa0ec2488a77fafeb0d8716b163be712/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    customersAmount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['phoneNumber LIKE \'+%\'',___arguments.value ? [' AND ','name IS NOT NULL',' AND ','email IS NOT NULL'].join('') : '',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''].join('')))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/c6e29ba49b64e8678919ffe51b74abf4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, wallet, order, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    if (!(getObjectProperty(user, 'customerId'))) {
      user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
    }
    if (!(getObjectProperty(user, 'walletId'))) {
      wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))))), 'gift_card'));
      await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      try {
        amount = 500;
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'line_items': [({ 'name': 'Welcome gift','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) })), 'payment'));
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
        user['wallet'] = wallet;
        user['walletId'] = (getObjectProperty(wallet, 'id'));
        user['bonusBalance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
        	return number.toFixed(2)
        })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
        await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(user, 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(user, 'customerId')) })) );

      } catch (error) {
        console.log(error);

      }
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/6dafc3783e893a15101bb6ff439889a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, wallet, user, order, locationId, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Proceeding...';
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Manual refill','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      if (getObjectProperty(user, 'walletId')) {
        wallet = (getObjectProperty(user, 'wallet'));
      } else {
        wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(locationId))), 'gift_card'));
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      }
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
      	return number.toFixed(2)
      })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Done';
      await new Promise(r => setTimeout(r, 5000 || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = '';

    } catch (error) {
      console.log(error);
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = (String('Error: ') + String(getObjectProperty(error, 'message')));
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/b4b1bbad5badf82823b35199fc3eeaed/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4b1bbad5badf82823b35199fc3eeaed'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/145f77debf7fa897e9d3a73ef8dba7bb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationName'] = (getObjectProperty(___arguments.context.dataModel, 'locationName'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pageSize'] = 10;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showIncomeTable'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['transactionSearch'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', false);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await BackendlessUI.Functions.Custom['fn_de9c0eec9816df08f6e21d751148f188']((getObjectProperty(___arguments.context.dataModel, 'id')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')).length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/0919b9516c361e17e5b9f11efa8793a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationName'] = (getObjectProperty(___arguments.context.dataModel, 'locationName'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showPayoutTable'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', false);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pageSize'] = 10;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['transactionSearch'] = '';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await BackendlessUI.Functions.Custom['fn_de9c0eec9816df08f6e21d751148f188']((getObjectProperty(___arguments.context.dataModel, 'id')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')).length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')) ? null : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/4494ae2196374ab569430767b49971b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', true);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showIncomeTable'] = false;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f2ccb45c4ab65c62ec8481c5d7af7a06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', true);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showPayoutTable'] = false;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/0e69a9df25dabf74ba7b466ca95c81c3/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0e69a9df25dabf74ba7b466ca95c81c3'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0e69a9df25dabf74ba7b466ca95c81c3'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'amount';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/6bd8b1d2ccbc4d96d0ee027340757bcc/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6bd8b1d2ccbc4d96d0ee027340757bcc'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6bd8b1d2ccbc4d96d0ee027340757bcc'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'name';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'title'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'title'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/6d38af6628bee7ce49abf556cf61f171/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d38af6628bee7ce49abf556cf61f171'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d38af6628bee7ce49abf556cf61f171'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'user';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/427f14d92349e264ada64c64b49de852/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('427f14d92349e264ada64c64b49de852'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('427f14d92349e264ada64c64b49de852'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'created'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'created'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/49795d6f900f8234461c1587ab0a23ba/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'amount';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/40a58e39aa0f35f6013ccade35a42f88/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('40a58e39aa0f35f6013ccade35a42f88'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('40a58e39aa0f35f6013ccade35a42f88'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filteredList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'user';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/db78b60e1ff10bcd3a1bd735ef275381/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField'))));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField'))));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing/components/a109324acb4bb0ec3503c9cbf0643dca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) + 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f5fd1cd4ebef152a790d0741e222e4e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) - 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/636f1b4adfedfcc592843588aa94ef3a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  } else if (___arguments.value < 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ___arguments.value;
  }
  stopSetTimeout('paggination');

  ;(function() {
    const callback = async () => {
        ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

    };

    const timerId = 'paggination';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/aa5bf7f90678135c451f91163c8c70ce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) - 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/7bee3754f6bdf8b622f4a761841be4f2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    console.log('> pages');
    console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7bee3754f6bdf8b622f4a761841be4f2'))['value'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  } else if (___arguments.value < 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7bee3754f6bdf8b622f4a761841be4f2'))['value'] = 1;
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ___arguments.value;
  }
  stopSetTimeout('paggination');

  ;(function() {
    const callback = async () => {
        ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

    };

    const timerId = 'paggination';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/7a05548cb23e1e5360fde3842e672645/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) + 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f23687d6e3b8a503cd386f08637772ff/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), (___arguments.value.toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/4d322d4670491c87776538935cb9190c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (___arguments.value.toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/402123f67b41e9f627aa8b0d6a132bef/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/50f651cce01cc671a244676779c6df89/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var date, lastReconciliation;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', true);
  lastReconciliation = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'));
  date = (new Date());
  date.setDate(((new Date(date).getDate()) - 1));
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['reconciliationModalStr'] = (['Are you sure you want to reconcile all transactions between ',((new Date((getObjectProperty(lastReconciliation, 'lastReconciliationDate')))).toLocaleDateString()),' and ',(date.toLocaleDateString())].join(''));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var lastReconciliationDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  lastReconciliationDate = (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation.lastReconciliationDate'))));

  return ((new Date((new Date())).getDate()) == (new Date(lastReconciliationDate).getDate()) && (new Date((new Date())).getMonth() + 1) == (new Date(lastReconciliationDate).getMonth() + 1) && (new Date((new Date())).getFullYear()) == (new Date(lastReconciliationDate).getFullYear()))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing/components/eba9575be99b3396baf9390be52a4ee6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/6244141c5f41cca81413b199bcaec762/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newCsvList, csvNameDate, j, item, csvList, locations, reconciliationTransactions, lastReconciliationDate, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['processingReconciliation'] = true;
  try {
    reconciliationTransactions = (await BackendlessUI.Functions.Custom['fn_1b3221e760f6705f5d2efe0d87fac07e'](null, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))));
    csvList = (await Promise.all(reconciliationTransactions.map(async item => {; return ({ 'amount': String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(item, 'amount')) / 100)))),'bonuses': (typeof (getObjectProperty(item, 'usedBonus')) === 'number' && !isNaN((getObjectProperty(item, 'usedBonus')))) ? String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(item, 'usedBonus')) / 100)))) : '$0.00','method': (getObjectProperty(item, 'method')),'location': (getObjectProperty(item, 'name')),'title': (getObjectProperty(item, 'title')),'user': (getObjectProperty(item, 'username')) });})));
    locations = (await (async function(list) {
    	return [...new Set(list.map(item => item.location))]
    })(csvList));
    date = (new Date());
    date.setDate(((new Date(date).getDate()) - 1));
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    csvNameDate = [(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()),'-',(new Date(date).getFullYear())].join('');
    ;(async () => {
        lastReconciliationDate = date;
      (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))['lastReconciliationDate'] = (lastReconciliationDate.valueOf());
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['lastReconciliationDateStr'] = (String('Last reconciliation date: ') + String(lastReconciliationDate.toLocaleDateString()));
      await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminUpdateReconciliationDate`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))));
      await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminUpdateLedger`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((reconciliationTransactions.map(item => item['paymentId']))));
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentSummary'] = (await BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b']());
      Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_fcec57351c91061d5927992ed935b2e9']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentSummary')), (getObjectProperty(___arguments.context.pageData, 'mainLocationId')))));

    })();
    newCsvList = [];
    for (var j_index in locations) {
      j = locations[j_index];
      newCsvList.unshift(({ 'url': (await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/table2csv`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'filePath': (['reconciliation/',(([j,'_',csvNameDate].join('')).replace((new RegExp('\\s', 'g')), '_')),'.csv'].join('')),'tableName': (await asyncListFilter(csvList, async (item) => {


       return ((getObjectProperty(item, 'location')) == j);
      })),'tableOptions': ({ 'header': true }) })),'name': [j,'_',csvNameDate].join('') }));
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['csvList'] = (newCsvList.concat((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'csvList'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', false);
    ;(async () => {
        await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveCSVInfo`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(newCsvList));

    })();

  } catch (error) {
    console.log(error);

  } finally {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['processingReconciliation'] = false;

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'processingReconciliation')) ? 'Creating reports...' : 'Yes')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/a191ab34d59d250f6fefd5532378d787/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing/components/b27674164c3b35eba77c04c976e2f48a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, list, order, orders, orders2, tender;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orders = (await Backendless.Data.of('Order').find(Backendless.DataQueryBuilder.create().setWhereClause((['payment is not null',' AND ','payment.squareId is null'].join(''))).setRelated('payment').setPageSize(100)));
  console.log(orders);
  orders2 = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-order-history`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((orders.map(item => item['squareId']))))), 'orders'));
  console.log(orders2);
  for (var j_index in orders) {
    j = orders[j_index];
    order = (await (async function(list, id) {
    	return list.find(item => item.id === id)
    })(orders2, (getObjectProperty(j, 'squareId'))));
    tender = (getObjectProperty(order, 'tenders'))[0];
    (getObjectProperty(j, 'payment'))['amount'] = (getObjectProperty(tender, 'amount_money.amount'));
    (getObjectProperty(j, 'payment'))['squareId'] = (getObjectProperty(tender, 'payment_id'));
  }
  await Backendless.Data.of('Ledger').bulkUpsert( (orders.map(item => item['payment'])) );
  console.log(orders.map(item => item['payment']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f39a1e3c5e58abfd9a47a55c8ac2baf1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var id, locations, j, orders;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locations = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setWhereClause('squareId is not null').setPageSize(100)));
  orders = (await Backendless.Data.of('Order').find(Backendless.DataQueryBuilder.create().setWhereClause((['location is null',' or ','location.squareId is null'].join(''))).setPageSize(100)));
  console.log((orders.map(item => item['squareId'])).length);
  console.log('abc');
  for (var j_index in orders) {
    j = orders[j_index];
    id = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(j, 'squareId'))) }).send()), 'order.location_id'));
    console.log('abc');
    await Backendless.Data.of('Order').setRelation(j, 'location', [({ 'objectId': (await (async function(list, id) {
    	return list.find(item => item.squareId === id).objectId
    })(locations, id)) })]);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/01fb0b374671eb7e453bbe93af1790e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['sidebar'] = (!(getObjectProperty(___arguments.context.pageData, 'sidebar')));
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')));
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))[(getObjectProperty(___arguments.context.pageData, 'section'))] = (['/img/icons/admin_icons/',(getObjectProperty(___arguments.context.pageData, 'section')),'_active.svg'].join(''));
  } else {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))[(getObjectProperty(___arguments.context.pageData, 'section'))] = (['/img/icons/admin_icons/',(getObjectProperty(___arguments.context.pageData, 'section')),'_active-alt.svg'].join(''));
  }

  },
  /* handler:onClick */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'sidebar')) ? '/img/icons/admin_icons/burger_active.svg' : '/img/icons/admin_icons/burger.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing/components/7947883afab3f46aab21e72e00aa440a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'menu' ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/e8b8236a173b04c874be92760bfd4a0c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({ 'width': '220px' }) : ({ 'width': 'auto' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/feab8a9c2d4c971752e6c8d4371e6369/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'image' ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/2e5d48e7c357d23fbf11781d28158286/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'location' ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/fb1f6043a0d1d3139aacf3dd6e356204/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'lto' ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/afd9721c030d9b3c632bbc05eb01f1f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'transactions' ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/2ffd88835b68acb5130111d155fff5cd/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'customers' ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/cac3b89f366c7a063d5f92f680fcad71/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7947883afab3f46aab21e72e00aa440a')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['section'] = 'menu';
  ___arguments.context.pageData['activeScreen'] = 'menu';
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))['menu'] = '/img/icons/admin_icons/menu_active.svg';
  ___arguments.context.pageData['sidebar'] = true;
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f04fc7fb754f61f2d8e41459e5347002/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('feab8a9c2d4c971752e6c8d4371e6369')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['section'] = 'image';
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))['image'] = '/img/icons/admin_icons/images_active.svg';
  ___arguments.context.pageData['sidebar'] = true;
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/e1b1c14e8a95c03da97482f3dffdd1e4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('2e5d48e7c357d23fbf11781d28158286')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['section'] = 'location';
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))['location'] = '/img/icons/admin_icons/location_active.svg';
  ___arguments.context.pageData['sidebar'] = true;
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/f95a300c78637d8f4cc6f8f53826533e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['section'] = 'lto';
  ___arguments.context.pageData['tab'] = 'calendar';
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))['lto'] = '/img/icons/admin_icons/lto_active.svg';
  ___arguments.context.pageData['sidebar'] = true;
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1f6043a0d1d3139aacf3dd6e356204')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/4612ffce48cbdfb38875804965b36f15/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'section')) != 'transactions') {
    ___arguments.context.pageData['section'] = 'transactions';
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['showIncomeTable'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['showPayoutTable'] = false;
    ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))['transactions'] = '/img/icons/admin_icons/transactions_active.svg';
    ___arguments.context.pageData['sidebar'] = true;
    ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('afd9721c030d9b3c632bbc05eb01f1f9')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/1834849d84bf85fe7271628d90ba78bf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['section'] = 'customers';
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))['customers'] = '/img/icons/admin_icons/customers_active.svg';
  ___arguments.context.pageData['sidebar'] = true;
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('2ffd88835b68acb5130111d155fff5cd')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/cab2b24c03bed7bc94b51031f0c576e4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'show')) ? ({  }) : ({ 'max-width': '80px' }))

  },
  /* handler:onStyleAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var pageName, query, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  query = (getObjectProperty(((function () { return window.location })()), 'search'));
  pageName = query.slice(((query.indexOf('=') + 1 + 1) - 1), query.length);
  (getObjectProperty(___arguments.context.dataModel, 'icons'))[pageName] = (['/img/icons/admin_icons/',pageName,'_active-alt.svg'].join(''));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var mainLocation, allLocationsList, item, activeLocationsList, newLocations, createdLocations, squareLocations, itemsLength, sortedItems, j, updateItem, itemsList, menuItems, result, offset, res;

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.pageData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Data.of('ItemImage').find(Backendless.DataQueryBuilder.create().setPageSize(100).setOffset(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  console.log(await Backendless.UserService.getUserRoles());
  ___arguments.context.pageData['menuList'] = (await Backendless.Data.of('Menu').find(Backendless.DataQueryBuilder.create().setRelationsDepth(3).setSortBy('created').setPageSize(100)));
  ___arguments.context.pageData['categoryImages'] = (await Backendless.Files.listing(encodePath('img/category'), '', false, 100, 0));
  ___arguments.context.pageData['offerImages'] = (await Backendless.Files.listing(encodePath('img/offer'), '', false, 100, 0));
  await getMenus();
  sortedItems = (await asyncListSort(menuItems, 1, async (item) => {


   return (getObjectProperty(item, 'item_data.name'));
  }));
  ___arguments.context.pageData['squareItems'] = sortedItems;
  ;(async () => {
      itemsList = [];
    await getItemImages(0);
    for (var j_index in itemsList) {
      j = itemsList[j_index];
      updateItem = (await (async function(list, squareId) {
      	return list.find(item => item.id === squareId)
      })(sortedItems, (getObjectProperty(j, 'squareId'))));
      if (updateItem) {
        updateItem['image'] = (getObjectProperty(j, 'image'));
        updateItem['objectId'] = (getObjectProperty(j, 'objectId'));
      }
    }
    itemsLength = sortedItems.length / 3;
    ___arguments.context.pageData['imagesContainer'] = ({  });
    (getObjectProperty(___arguments.context.pageData, 'imagesContainer'))['list1'] = (sortedItems.slice(0, itemsLength));
    (getObjectProperty(___arguments.context.pageData, 'imagesContainer'))['list2'] = (sortedItems.slice(((itemsLength + 1) - 1), itemsLength * 2));
    (getObjectProperty(___arguments.context.pageData, 'imagesContainer'))['list3'] = (sortedItems.slice(((itemsLength * 2 + 1) - 1), sortedItems.length));

  })();
  await getModifiers();
  await getLocationsFromSquare();
  allLocationsList = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setWhereClause((['squareId IN (\'',(squareLocations.map(item => item['id'])).join('\',\''),'\')'].join(''))).setRelated('menu').setSortBy('name ASC').setPageSize(100)));
  if (squareLocations.length > allLocationsList.length) {
    createdLocations = (await Backendless.Data.of('Location').bulkCreate( (await Promise.all((await asyncListFilter(squareLocations, async (item) => {


     return (!((allLocationsList.map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
    })).map(async item => {; return ({ 'squareId': (getObjectProperty(item, 'id')),'name': (getObjectProperty(item, 'name')),'hidden': (getObjectProperty(item, 'status')) == 'INACTIVE' });}))) ));
    newLocations = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId IN (\'',createdLocations.join('\',\''),'\')'].join(''))).setPageSize((createdLocations.length))));
    addItemToList(allLocationsList, newLocations);
  }
  activeLocationsList = (await asyncListFilter(allLocationsList, async (item) => {


   return ((getObjectProperty(item, 'isMain')) || !(getObjectProperty(item, 'hidden')));
  }));
  ___arguments.context.pageData['locationList'] = activeLocationsList;
  ___arguments.context.pageData['allLocationList'] = allLocationsList;
  ___arguments.context.pageData['locationSelect'] = (await Promise.all(allLocationsList.map(async item => {; return ({ 'value': (getObjectProperty(item, 'objectId')),'label': (getObjectProperty(item, 'name')) });})));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['paymentsLocationSelect'] = (await Promise.all(activeLocationsList.map(async item => {; return ({ 'value': (getObjectProperty(item, 'squareId')),'label': (getObjectProperty(item, 'name')) });})));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['paymentLocation'] = (getObjectProperty(((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373')), 'paymentsLocationSelect'))[0]), 'value'));
  mainLocation = (await (async function(list) {
  	return list.find(item => item.isMain)
  })(allLocationsList));
  ___arguments.context.pageData['mainLocationId'] = (getObjectProperty(mainLocation, 'objectId'));
  ___arguments.context.pageData['mainLocationSquareId'] = (getObjectProperty(mainLocation, 'squareId'));

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
    ___arguments.context.pageData['section'] = 'menus';
    ___arguments.context.pageData['months'] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/landing_backup/components/7c1298757f34d55f8736013d323bbd4f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['section'] = 'menus';
  ___arguments.context.pageData['activeScreen'] = 'menu';
  ___arguments.context.pageData['sidebar'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f367079a75e46735ea15e76f88d561a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['sidebar'] = (!(getObjectProperty(___arguments.context.pageData, 'sidebar')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1bbe429b40c9d4a733d964be9b8565d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var clonedMenu, k, j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['editing'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'objectId')),'category': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) : [] });
  ___arguments.context.pageData['activeMenu'] = ___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368');
  ___arguments.context.pageData['activeScreen'] = 'category';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f64240780b5d739fd6e6666f1441413e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function showModal() {
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'saveCategory';
}

/**
 * Describe this function...
 */
async function changeScreen() {
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit') {
    if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
      await showModal();
    } else {
      await changeScreen();
    }
  } else {
    if ((await BackendlessUI.Functions.Custom['fn_c0a3f7f2c1b299c7fa9efbcfccfaf763'](___arguments.context.pageData))) {
      await showModal();
    } else {
      await changeScreen();
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/986adcc1402d905b0f177d556b1902f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'loaded'))) {
    ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a')['item'] = (await asyncListSort((await Backendless.Data.of('Category').loadRelations((getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('item').setPageSize(100).setOffset(0))), 1, async (item) => {


     return (getObjectProperty(item, 'order'));
    }));
    ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a')['loaded'] = true;
  }
  ___arguments.context.pageData['activeCategory'] = ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a');
  ___arguments.context.pageData['editingCategory'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'objectId')),'item': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'item')),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'image')) });
  ___arguments.context.pageData['activeScreen'] = 'categoryEdit';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/0beef512e1426846bafd37ed9d761f65/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.dataModel;
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.dataModel, 'name')),'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'modifier': (getObjectProperty(___arguments.context.dataModel, 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.dataModel, 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.dataModel, 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.dataModel, 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.dataModel, 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5dba530cf0278ffdf5ead1f4895b6ada/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var item, addedIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addItem';
  ___arguments.context.pageData['uniqueItems'] = (getObjectProperty(___arguments.context.pageData, 'squareItems'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5216e199f3447d92082afe38d2113d41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteItem';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5239e6cb34e82ad2b5863dbdb167bb2e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['categoryToDelete'] = ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/febdc3c27952f9df956d827592e48717/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteMenu';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/64314f7464a6bad43488e3d84be7efd9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addMenu';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/19262a7e2927f06596277a061e05beda/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1e6780e56a12dc1e04f3b30a268105d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/09ee7b31196c7618e9438bb4d9766b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/67972eade3335ce9fbdf479ab15d7f6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newMenu, list, menuName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  menuName = (getObjectProperty(___arguments.context.pageData, 'newMenuName'));
  list = (getObjectProperty(___arguments.context.pageData, 'menuList'));
  if (menuName) {
    newMenu = (await Backendless.Data.of('Menu').save( ({ 'name': menuName }) ));
    addItemToList(list, newMenu);
    delete ___arguments.context.pageData['newMenuName'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5e23e373e2d47b6876070a18845ace2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/ca2fa06723b33b229969fa56df5fafb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/fffb1468d62269c33bb737b61e9b0659/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, list, categoryName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categoryName = (getObjectProperty(___arguments.context.pageData, 'newCategoryName'));
  list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  if (categoryName) {
    category = (await Backendless.Data.of('Category').save( ({ 'name': categoryName,'order': list.length + 1 }) ));
    category['item'] = [];
    addItemToList(list, category);
    await (async function() {
    	const category = [...document.querySelectorAll('.draggable')].slice(-1).pop();

    	category.draggable = true;
    	category.addEventListener('dragstart', (e) => {
    	  category.classList.add("is-dragging")
    	});

    	category.addEventListener('dragend', (e) => {
    	  category.classList.remove("is-dragging");
    	})
    })();
    await Backendless.Data.of('Menu').addRelation((getObjectProperty(___arguments.context.pageData, 'editing')), 'category', [category]);
    delete ___arguments.context.pageData['newCategoryName'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/80451d7b74fdb049e2133bf6b39e6db7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  delete ___arguments.context.pageData['newItem'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/d2cdea06507901cd1ebfad2e5e7357d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['newItem'];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/81ef466fc0daa5438334ddd78906b37a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newItem, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'newItems')) && !!(getObjectProperty(___arguments.context.pageData, 'newItems')).length) {
    newItem = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'newItems')).map(async item => {; return ({ 'name': (getObjectProperty(item, 'item_data.name')),'squareId': (getObjectProperty(item, 'id')),'variation': ((getObjectProperty(item, 'item_data.variations')).map(item => item['id'])),'order': (getObjectProperty(___arguments.context.pageData, 'newItems')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).length });})));
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['item'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), newItem));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    delete ___arguments.context.pageData['newItems'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/2195e4413bb4651d9af9c0b5f35cc786/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c517adbd761c078868c23a0417cb4009/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var items, j, categories, menu;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categories = (getObjectProperty(___arguments.context.pageData, 'activeMenu.category'));
  menu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'menuList')), menu, 'objectId');
  await Backendless.Data.of('Menu').remove( menu );
  if (categories && !!categories.length) {
    await Backendless.Data.of('Category').bulkDelete( (['objectId IN (\'',(categories.map(item => item['objectId'])).join('\',\''),'\')'].join('')) );
    items = [];
    var j_list = (categories.map(item => item['item']));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(items, j);
    }
    if (!!items.length) {
      await Backendless.Data.of('Item').bulkDelete( (['objectId IN (\'',(items.map(item => item['objectId'])).join('\',\''),'\')'].join('')) );
    }
  }
  ___arguments.context.pageData['activeScreen'] = 'menu';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4d497b345ff8661374350be53f762fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/81eb464908fa214371fad0fc9da7da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, items;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  category = (getObjectProperty(___arguments.context.pageData, 'categoryToDelete'));
  items = (getObjectProperty(category, 'item'));
  await Backendless.Data.of('Category').remove( category );
  if (!!items.length) {
    console.log((await Backendless.Data.of('Item').bulkDelete( (['objectId IN (\'',(items.map(item => item['objectId'])).join('\',\''),'\')'].join('')) )));
  }
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editing.category')), category, 'objectId');
  ___arguments.context.pageData['activeScreen'] = 'category';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f351bbec212b1a64f9bb319b2db7ed1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/04354a94d901e953b2dd83af23dfc6a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  item = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  if (getObjectProperty(item, 'objectId')) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'objectId');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'objectId');
    await Backendless.Data.of('Item').remove( item );
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'order');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'order');
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  ___arguments.context.pageData['editItem'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/95ea0e42eeebb94276a411ce95679f5a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f738bbdb4e5be01adda1681b50387046', true);
  await new Promise(r => setTimeout(r, 3000 || 0));
  if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f738bbdb4e5be01adda1681b50387046')) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f738bbdb4e5be01adda1681b50387046'))['display'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c3e27d82d8e2dd71b48c331f6179b8fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'itemChanges')).length) {
    ___arguments.context.pageData['editItem'] = false;
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
    ___arguments.context.pageData['modal'] = 'saveItem';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/103bb4adbb33508c27bfd2d9ff6790a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, savedItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('00e7a064117b152b13ffce0241090eed', false);
  if (!(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('00e7a064117b152b13ffce0241090eed', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    try {
      savedItem = (await Backendless.Data.of('Item').save( ({ 'variation': (getObjectProperty(___arguments.context.pageData, 'editingItem.variation')),'modifier': (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'editingItem.modifier')))),'objectId': (getObjectProperty(___arguments.context.pageData, 'editingItem.objectId')),'name': (getObjectProperty(___arguments.context.pageData, 'editingItem.name')),'image': (getObjectProperty(___arguments.context.pageData, 'editingItem.image')),'order': (getObjectProperty(___arguments.context.pageData, 'editingItem.order')),'squareId': (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.id')),'notesEnabled': (getObjectProperty(___arguments.context.pageData, 'editingItem.notesEnabled')) }) ));
      Object.assign((getObjectProperty(___arguments.context.pageData, 'activeItem')), savedItem);
      Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));
      console.log(getObjectProperty(___arguments.context.pageData, 'editingCategory'));
      ___arguments.context.pageData['editItem'] = false;

    } catch (error) {
      await (async function(error) {
      	alert(`Something went wrong:\n ${error}`)
      })(error);

    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c2354b1d5cbb04b68e686f65e4f2329d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var newItems;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
  if (___arguments.value) {
    if (newItems) {
      addItemToList(newItems, ___arguments.context.dataModel);
    } else {
      ___arguments.context.pageData['newItems'] = [___arguments.context.dataModel];
      newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
    }
  } else {
    removeItemInList(newItems, ___arguments.context.dataModel, 'id');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'newItems')) && (((getObjectProperty(___arguments.context.pageData, 'newItems')).map(item => item['id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/41679f5722fa99da566274fa92d88fc5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Edit ') + String(getObjectProperty(___arguments.context.pageData, 'activeItem.name')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing_backup/components/89e4d46dbf7218df93e1f3fa2bec4626/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63d122c6aac6f8e76317c0af0696ad0e', true);
  await new Promise(r => setTimeout(r, 3000 || 0));
  if ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('63d122c6aac6f8e76317c0af0696ad0e')) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('63d122c6aac6f8e76317c0af0696ad0e'))['display'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/68a513e8657f1f86451730ac519e999f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/26f1026ff85b2b28c52cbed88d31628a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/1799fbf1333349f910c697b5eb8ae39c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'itemChanges')).length) {
    ___arguments.context.pageData['editItem'] = false;
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
    ___arguments.context.pageData['modal'] = 'saveItem';
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/8b737ccdaf9110cbaca2486f5b66d65c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8a4e6e1c84782adba95feedd7e491eaf'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/8a4e6e1c84782adba95feedd7e491eaf/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var id, itemChanges, variationList, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  variationList = (getObjectProperty(activeItem, 'variation'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (variationList.includes(id)) {
    removeItemInList(variationList, id, '');
  } else {
    addItemToList(variationList, id);
  }
  if (itemChanges.includes(id)) {
    removeItemInList(itemChanges, id, '');
  } else {
    addItemToList(itemChanges, id);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/0b1b67e2386aec0cc056e8978a931e7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierId, modifierListId, allModifiers, modifierOptions, activeItem, id, itemChanges;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  modifierId = (getObjectProperty(___arguments.context.dataModel, 'id'));
  modifierListId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'));
  allModifiers = (getObjectProperty(activeItem, 'modifier'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (allModifiers && ((Object.keys(allModifiers)).includes(modifierListId))) {
    modifierOptions = (getObjectProperty(allModifiers, modifierListId));
    if (modifierOptions.includes(modifierId)) {
      removeItemInList(modifierOptions, modifierId, '');
      if (!modifierOptions.length) {
        delete allModifiers[modifierListId];
      }
    } else {
      addItemToList(modifierOptions, modifierId);
    }
  } else {
    allModifiers[modifierListId] = [modifierId];
  }
  if (itemChanges.includes(modifierId)) {
    removeItemInList(itemChanges, modifierId, '');
  } else {
    addItemToList(itemChanges, modifierId);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var modifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b1ee4c414c8998c21ae7eb6ca6a61b76/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0b1b67e2386aec0cc056e8978a931e7f'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/159bd412fc2f405825bb2cf9eb85d532/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'editImage';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1ec7e0b98baf047f9aef6df2c20a3b11/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['categoryToDelete'] = (getObjectProperty(___arguments.context.pageData, 'editingCategory'));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/d3d5a474c709ae6bfc919e3250f71aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9de2b727d0c49ad7ffe601c858dcf287/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c331492cf5738f93b85d32619a4f57f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/23144bc14fa4e6d42aec6ed66d7da7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/8665bb11608ea9cc4a16d186892a68c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/4813af6d506917b2fa7993d9713e7ce9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/cac1771cf2985c29f493f07d8404503a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/landing_backup/components/979a5b4b8d832e33f19cacccab19ed0f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var activeMenu, menuList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeMenu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  menuList = (getObjectProperty(___arguments.context.dataModel, 'menu'));
  if (___arguments.value) {
    ___arguments.context.dataModel['menu'] = activeMenu;
    await Backendless.Data.of('Location').setRelation(___arguments.context.dataModel, 'menu', [activeMenu]);
  } else {
    delete ___arguments.context.dataModel['menu'];
    await Backendless.Data.of('Location').deleteRelation(___arguments.context.dataModel, 'menu', [activeMenu]);
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'menu')) && (getObjectProperty(___arguments.context.dataModel, 'menu.objectId')) == (getObjectProperty(___arguments.context.pageData, 'activeMenu.objectId')))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/64857fd6e70c62b865fcc1424a7f2a32/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.category__list');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.objectId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editing.category')));

  },
  /* handler:onMounted */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var j, categories, menu, editing, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  editing = (getObjectProperty(___arguments.context.pageData, 'editing'));
  categories = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  editing['category'] = (await asyncListSort(categories, 1, async (item) => {


   return (getObjectProperty(item, 'order'));
  }));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/landing_backup/components/04debe5e790704d2b4c89796d3ca2677/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, allModifiers, modifierId, modifierListId, modifierOptions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'))] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'modifier_list_data.modifiers')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1434445d9e5999340fba3f0b895d8b14/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'))];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6aa1ff2d4df14ec805c8339f4618538e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, id, variationList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  activeItem['variation'] = ((getObjectProperty(activeItem, 'squareObject.item_data.variations')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/00770631cfa6404945b9bb356763e13f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['variation'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/8e7abbdeee6840fb651b245837ef0fba/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'item')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/5a2defbc4875a0b7e1d535b24eb3ee83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) ? !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')).length : true)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/8c3ccce88fcdb265c83723bfa9c1e75a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    var j_list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'oldOrder')) {
        j['order'] = (getObjectProperty(j, 'oldOrder'));
        j['changed'] = false;
        delete j['oldOrder'];
      }
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/7ea3c78b76896a9f6a8bfe3b8467a838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
    await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1f3e6b85e1da4e78f09d0552d3ac44da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/2ea8f662f17978e7b5b36f0b8b868237/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/7e92da65f1f758a852c2bcbdd48d8741/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('23aa26dfcdc16688b2065c0d25da2ca4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/cf2bc997cf9c1dba0748c8248f534a2e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b211bf62b7d9f101e38aef0ce48c4e6a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/a9aaa9d1bd498382baf6d00f95f1f02a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'menu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/8bd7b99185b6ae3788dfce84b9d1b728/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu') {
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ac65e8de202b30c7ef2d83bd7be8675a')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/68a4020f2c832f04358838766e864a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/3678cc2ead6cad6dce141ea1ebbcb756/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/6526393763e3a4c7909bcc2a81665aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/e53b4263b63bbe926862d1f42016faca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/f167cc4a529265896f775dd1f58fcf4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/853de7c6b4c7d7081c68f5f3f27699a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/8528128c771cbd8e7a729a72e25e9622/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteMenu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/21b6da3853232976a77f5053705d4a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/e038155e367d4454206b7ceed51b3c38/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/32a38dc7f8399d5f6a37e115dc0cc44a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b3f655735aba18b7a6582ff4b3a52442/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['section'] = 'offer';
  ___arguments.context.pageData['tab'] = 'calendar';
  ___arguments.context.pageData['sidebar'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/1a5803e560248da7d0f7581b8a93db5b/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'offer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b0d2bc649d62bc74a077376295241b9c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'menus')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b116e0c3fd0b99229caf59dc727aad92/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var curMonth;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'curMonth'))) {
    curMonth = String(new Date((new Date())).getMonth() + 1) + String(new Date((new Date())).getFullYear());
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, (new Date()));
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6']((new Date())));
      ___arguments.context.pageData['curMonth'] = curMonth;
    }
  }
  if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'calendar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/b534fbaf4a97aec2db355cdb919dc8d6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    date = (new Date());
    date.setDate(1);
    date.setFullYear((getObjectProperty(___arguments.context.pageData, 'year')));
    date.setMonth(((getObjectProperty(___arguments.context.pageData, 'month')) + 1));
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, date);
    curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6'](date));
    }
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/bb9374461fafeb16c413a8257add3729/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    date = (new Date());
    date.setFullYear((getObjectProperty(___arguments.context.pageData, 'year')));
    date.setMonth(((getObjectProperty(___arguments.context.pageData, 'month')) - 1));
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, date);
    curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6'](date));
    }
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/215cf642addae3297f43b7bba46c5d69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, (new Date()));
    curMonth = String(new Date((new Date())).getMonth() + 1) + String(new Date((new Date())).getFullYear());
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((new Date())).getMonth()) != (getObjectProperty(___arguments.context.pageData, 'month')) ? true : (new Date((new Date())).getFullYear()) != (getObjectProperty(___arguments.context.pageData, 'year')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/5126650d543e444e109719cfe7ef6668/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, offers, timestamp, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  today = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date())));
  if ((getObjectProperty(___arguments.context.dataModel, 'current')) && (getObjectProperty(___arguments.context.dataModel, 'timestamp')) >= today) {
    timestamp = (getObjectProperty(___arguments.context.dataModel, 'timestamp'));
    offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date(timestamp).getDate())));
    if (!offers) {
      (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth'))))[(new Date(timestamp).getDate())] = [];
      offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date(timestamp).getDate())));
    }
    ___arguments.context.pageData['activeDate'] = ___arguments.context.dataModel;
    ___arguments.context.pageData['offersTitle'] = (['Edit offers for ',(getObjectProperty(___arguments.context.pageData, 'months'))[((new Date(timestamp).getMonth() + 1) - 1)],' ',(getObjectProperty(___arguments.context.dataModel, 'date')),', ',(new Date(timestamp).getFullYear())].join(''));
    ___arguments.context.pageData['editingDate'] = ({ 'timestamp': timestamp,'offers': !offers.length ? [] : (await asyncListSort((await Promise.all(offers.map(async item => {; return (JSON.parse((JSON.stringify(item))));}))), 1, async (item) => {


     return (getObjectProperty(item, 'order'));
    })) });
    ___arguments.context.pageData['offersToDelete'] = [];
    ___arguments.context.pageData['tab'] = 'dayOffer';
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    var timestamp, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  timestamp = (getObjectProperty(___arguments.context.dataModel, 'timestamp'));
  today = (new Date());
  if ((new Date(today).getMonth() + 1) == (new Date(timestamp).getMonth() + 1) && (new Date(today).getFullYear()) == (new Date(timestamp).getFullYear()) && (new Date(today).getDate()) == (new Date(timestamp).getDate())) {
    ___arguments.context.dataModel['today'] = true;
  }

  },
  /* handler:onMounted */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'current')) && (getObjectProperty(___arguments.context.dataModel, 'timestamp')) >= (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date()))) ? ((getObjectProperty(___arguments.context.dataModel, 'today')) ? ['calendar__date', 'today'] : ['calendar__date']) : ['calendar__date', 'disabled'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/6bfec90cc41883d3ef91acc5545e7a83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'todaysOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/38cc2ab7137c43ce012215fafa838566/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, item, j, modifiers, offers, offersToDelete, selectedModifiers, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'offers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  offersToDelete = (getObjectProperty(___arguments.context.pageData, 'offersToDelete'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'offerDate': (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp')),'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'offers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/147fb543216568d9044a403b78dd33c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9741a2f63a781c339b7455042b7a2fcc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/a2fb35e6eb5ed781034fb01ed0c13555/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, offers, offersToDelete, ids, offersToCreate, item, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  timestamp = (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'));
  addItemToList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'offers')), 1, async (item) => {
     item['order'] = ((getObjectProperty(___arguments.context.pageData, 'offers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length);


   return (getObjectProperty(item, 'name'));
  })));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4e0dd03bfa6c67fd7d52072e68433673/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['sidebar'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6a2a5def5cef564788ff3486e38165bc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'current')) ? ['calendar__date_text', 'current'] : ['calendar__date_text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/7b2bd1ec04844548f0f5b324e6f8ca2e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  await Backendless.UserService.logout();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/3b7ca5aa8ac86a1dda15706d607f5aa4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length) {
    ___arguments.context.pageData['periodOffersList'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'periodOffers')), 1, async (item) => {
       item['order'] = ((getObjectProperty(___arguments.context.pageData, 'periodOffers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length);


     return (getObjectProperty(item, 'name'));
    }))));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/2347c3e33bd631fcb82155b1b63efee4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, offers, selectedModifiers, j, modifiers, item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'periodOffers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'periodOffers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d15846c1fa19d2c5bfc2071d973a670e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/33af7aa5b562cfc7e4bfcaaaa5911beb/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'current')) && (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'timestamp'))).getDate()))) ? (await asyncListSort((getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'timestamp'))).getDate()))), 1, async (item) => {


 return (getObjectProperty(item, 'order'));
})) : [])

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/landing_backup/components/94a2c4e501c37ae46467178715faa1db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/95412ce446138ea82f1b0a2953127ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/de042b7c17155e92ca7b0324cfbf86f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffers')), ___arguments.context.getComponentDataStoreByUid('7bbeefa6fdc6e11dd6dc2fd798ad5749'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/3af29a84d03d50b21fe45a3ee8a3683a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'offers')), ___arguments.context.getComponentDataStoreByUid('552b29f96cbcda34a01a373cb661dd0b'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/7e97cdba7e76fe18b375ef73e14a1bd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  ___arguments.context.pageData['periodOffersList'] = [];
  ___arguments.context.pageData['tab'] = 'periodOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/8a2a14e309dc8cab641c2088decdcdc4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['section'] = 'image';
  ___arguments.context.pageData['sidebar'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f6f68129fcb94351ab6c1ef51868ebae/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'image')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/fe54b685c39be297f8a507f6e4aff91d/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['uploading'] = true;
  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['image'] = '';

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('fe54b685c39be297f8a507f6e4aff91d');

  },
  /* handler:onUploadFail */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var item, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['image'] = ___arguments.uploadedFiles;
  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['uploading'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2f4b758b042f67611d55d7cfcb2f2831', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2f4b758b042f67611d55d7cfcb2f2831', true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('fe54b685c39be297f8a507f6e4aff91d');
  object = ({ 'squareId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'id')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'item_data.name')),'image': ___arguments.uploadedFiles });
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'objectId')) {
    object['objectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'objectId'));
  }
  item = (await Backendless.Data.of('ItemImage').save( object ));
  ___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb')['objectId'] = (getObjectProperty(item, 'objectId'));

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'item_data.name')).replace((new RegExp('\\s', 'g')), '_')).replace((new RegExp('\'', 'g')), '')) + String(subsequenceFromStartLast((getObjectProperty(___arguments.file, 'name')), (((getObjectProperty(___arguments.file, 'name')).lastIndexOf('.') + 1) - 1))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/landing_backup/components/2f4b758b042f67611d55d7cfcb2f2831/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('9aef80d2eb554f839ae4e50430d5eceb'), 'image')) : '/img/no-img.png')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/b6699c0351cf444c0c7b028211892aba/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['uploading'] = true;
  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['image'] = '';

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var item, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['image'] = ___arguments.uploadedFiles;
  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['uploading'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e5ea31ebc9549e4cd9276edb8a6d3c', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e5ea31ebc9549e4cd9276edb8a6d3c', true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('b6699c0351cf444c0c7b028211892aba');
  object = ({ 'squareId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'id')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'item_data.name')),'image': ___arguments.uploadedFiles });
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'objectId')) {
    object['objectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'objectId'));
  }
  item = (await Backendless.Data.of('ItemImage').save( object ));
  ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['objectId'] = (getObjectProperty(item, 'objectId'));

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('b6699c0351cf444c0c7b028211892aba');

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'item_data.name')).replace((new RegExp('\\s', 'g')), '_')).replace((new RegExp('\'', 'g')), '')) + String(subsequenceFromStartLast((getObjectProperty(___arguments.file, 'name')), (((getObjectProperty(___arguments.file, 'name')).lastIndexOf('.') + 1) - 1))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d1f1f4fb93ae9a764d5a0681fd34b7a5/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['uploading'] = true;
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['image'] = '';

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var item, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.uploadedFiles);
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['image'] = ___arguments.uploadedFiles;
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['uploading'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dac599d445dab683c979b14ecbe8751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4dac599d445dab683c979b14ecbe8751', true);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('d1f1f4fb93ae9a764d5a0681fd34b7a5');
  object = ({ 'squareId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'id')),'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'item_data.name')),'image': ___arguments.uploadedFiles });
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'objectId')) {
    object['objectId'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'objectId'));
  }
  item = (await Backendless.Data.of('ItemImage').save( object ));
  console.log(item);
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['objectId'] = (getObjectProperty(item, 'objectId'));

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      console.log('fail');
  ___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f')['uploading'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('d1f1f4fb93ae9a764d5a0681fd34b7a5');

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'item_data.name')).replace((new RegExp('\\s', 'g')), '_')).replace((new RegExp('\'', 'g')), '')) + String(subsequenceFromStartLast((getObjectProperty(___arguments.file, 'name')), (((getObjectProperty(___arguments.file, 'name')).lastIndexOf('.') + 1) - 1))))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/landing_backup/components/27e5ea31ebc9549e4cd9276edb8a6d3c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07a89a032ab57e5bcaf733cf14194c5c'), 'image')) : '/img/no-img.png')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/4dac599d445dab683c979b14ecbe8751/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ad11c82e6d64cbe48f6c249784224e7f'), 'image')) : '/img/no-img.png')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/landing_backup/components/1a3683ceb692147e6a24c7dca66c72e3/bundle.js', [], () => ({
  /* content */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('img/items/') + String(getObjectProperty(___arguments.context.pageData, 'activeItem.objectId')))

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['image'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['upload'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('1a3683ceb692147e6a24c7dca66c72e3');

  },
  /* handler:onUploadSuccess */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.pageData['upload'] = true;

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      ___arguments.context.pageData['upload'] = true;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('1a3683ceb692147e6a24c7dca66c72e3');

  },
  /* handler:onUploadFail */
  /* content */
}))

define('./pages/landing_backup/components/f4c9054be843a0964b99aca8ae9c9665/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('#item_list');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);
  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.squareId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/5e55ebfe0de58d894d6312c2ac2dc56e/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.dataModel, 'order'))) {
    ___arguments.context.dataModel['order'] = (((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).map(item => item['objectId'])).indexOf(getObjectProperty(___arguments.context.dataModel, 'objectId')) + 1);
    await Backendless.Data.of('Item').save( ___arguments.context.dataModel );
  }

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/e78ecc0f872f4c9a853383c68cd86e38/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers, squareObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e');
  squareObject = (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems'))));
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': squareObject,'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'image')) : (getObjectProperty(squareObject, 'image')),'order': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'order')),'showVariations': (getObjectProperty(squareObject, 'item_data.variations')).length > 1,'notesEnabled': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'notesEnabled')) });
  console.log(getObjectProperty(___arguments.context.pageData, 'editingItem'));
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/a064b75daaea8fafda4552c63f11eae9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var clonedMenu, copyMenu, error, j, k;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'));
  clonedMenu = (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368')))));
  clonedMenu['name'] = (String(getObjectProperty(clonedMenu, 'name')) + String(' - copy'));
  delete clonedMenu['objectId'];
  delete clonedMenu['created'];
  clonedMenu['objectId'] = (getObjectProperty((await Backendless.Data.of('Menu').save( clonedMenu )), 'objectId'));
  var j_list = (getObjectProperty(clonedMenu, 'category'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    delete j['objectId'];
    delete j['created'];
    var k_list = (getObjectProperty(j, 'item'));
    for (var k_index in k_list) {
      k = k_list[k_index];
      delete k['objectId'];
      delete k['created'];
    }
    j['objectId'] = (getObjectProperty((await Backendless.Data.of('Category').deepSave( j )), 'objectId'));
  }
  await Backendless.Data.of('Menu').addRelation(clonedMenu, 'category', (getObjectProperty(clonedMenu, 'category')));
  console.log(clonedMenu);
  addItemToList((getObjectProperty(___arguments.context.pageData, 'menuList')), clonedMenu);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f76c81716cdf482219c4e1116adaa5a6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'calendar')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d58da494e6b59bef13a2339706a6154f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'dayOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/067b523acaef90acc9f71ea96cc6b276/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/7c335a8fa9a6969fdab1de75ed1d373b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['filteredSquareItems'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'squareItems')), async (item) => {


   return (!(((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
  }));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['offers'] = [];
  ___arguments.context.pageData['modal'] = 'todaysOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/b7e7f2b31fe2b0a81f4d8d7736b64734/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'image')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/3608c0c37214bd66f851fa2ab231086f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j, timestamp;


  ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c6f66dccefed7194b01575c63ea29f8e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  ___arguments.context.dataModel['dragId'] = (generateUUID().short());

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/4b4be3f608273237f7026a0c5fe861d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var offers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'name');
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId')) {
    offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'))).getDate())));
    removeItemInList(offers, ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId');
    await Backendless.Data.of('TodaysOffer').remove( ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e') );
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f2e60cedd8461306cf55af0501dd8c39/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  async ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return (await (async function(files, percent, width) {
	for (let index = 0; index < files.length; index++) {
	  const file = files[index]
	  const fileName = file.name

	  const content = await readFileAsDataURL(file)

	  const img = await getNewImage(content)

	  const elem = document.createElement('canvas')

	  const widthImage = !width ? img.width : img.width > width ? width : img.width
	  const scaleFactor = img.width > width ? width && width / img.width : 1
	  const heightImage = width ? img.height * scaleFactor : img.height

	  elem.width = widthImage
	  elem.height = heightImage

	  const ctx = elem.getContext('2d')

	  ctx.drawImage(img, 0, 0, widthImage, heightImage)

	  const blob = await getCanvasBlob(ctx.canvas, percent)

	  const newFile = new File([blob], fileName, {
	    type: 'image/jpeg',
	    lastModified: Date.now()
	  })

	  files[index] = newFile
	}

	async function readFileAsDataURL(file) {
	  const result = await new Promise((resolve) => {
	    const fileReader = new FileReader()

	    fileReader.onload = (e) => resolve(e.target.result)

	    fileReader.readAsDataURL(file)
	  })

	  return result
	}

	async function getNewImage(content) {
	  const result = await new Promise((resolve) => {
	    const img = new Image()

	    img.src = content

	    img.onload = () => resolve(img)
	  })

	  return result
	}

	async function getCanvasBlob(canvas, percent) {
	  const result = await new Promise((resolve) => {
	    canvas.toBlob((blob) => {
	      resolve(blob)
	    }, 'image/jpeg', percent)
	  })

	  return result
	}

	return files
})(___arguments.files, 1, 600))

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedOfferImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedOfferImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/landing_backup/components/c2b51d85ca38df7c478af3ec9a3dd2ef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editOfferImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/afc9812e122100175a02defad0086d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/50d646aff2df909d6f3cfa42834e46d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5ab734948432b055f37bd2f5d79f49a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/fb3719614bf7550212476e5f7833e0b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editingOffer'] = ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e');
  ___arguments.context.pageData['modal'] = 'editOfferImage';
  ___arguments.context.pageData['selectedUrl'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/73bf13a62b99deee7c49d918cd4e9b65/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/f6f8336f7239d56e550334dc3c796739/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/fa79760dc4aee4548cf8b0b361de395c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/b79da0014b2eec1b63480ae3e545de5d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var ids;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ids = (await Backendless.Data.of('TodaysOffer').bulkUpsert( (getObjectProperty(___arguments.context.pageData, 'editingDate.offers')) ));
  (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth'))))[(new Date((getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'))).getDate())] = (await Backendless.Data.of('TodaysOffer').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId IN (\'',ids.join('\',\''),'\')'].join(''))).setSortBy('order ASC').setPageSize(100)));
  ___arguments.context.pageData['tab'] = 'calendar';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d19902d5fd5f9ce4fa16e4489901bf3b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['filteredSquareItems'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'squareItems')), async (item) => {


   return (!(((getObjectProperty(___arguments.context.pageData, 'periodOffersList')).map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
  }));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'periodOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/839a54593b6167c1ad32851aa5a75b69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editingOffer'] = ___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6');
  ___arguments.context.pageData['modal'] = 'editOfferImage';
  ___arguments.context.pageData['selectedUrl'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/8a097bb5315133112fe2c8b89aa54b7d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c806826c9e1fd2a50732108339f22814/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'image')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/d5c59cac03b8c603972bee97e742c6ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if ((getObjectProperty(___arguments.context.pageData, 'offerTo')) && (getObjectProperty(___arguments.context.pageData, 'offerFrom')) && !!(getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    listToSave = [];
    var i_end = ((getObjectProperty(___arguments.context.pageData, 'offerTo')) - (getObjectProperty(___arguments.context.pageData, 'offerFrom'))) / (1000 * 60 * 60 * 24);
    var i_inc = 1;
    if (0 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 0; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      date = (new Date((getObjectProperty(___arguments.context.pageData, 'offerFrom'))));
      date.setDate(((new Date((getObjectProperty(___arguments.context.pageData, 'offerFrom'))).getDate()) + i));
      var j_list = (getObjectProperty(___arguments.context.pageData, 'periodOffersList'));
      for (var j_index in j_list) {
        j = j_list[j_index];
        curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
        if (getObjectProperty(___arguments.context.pageData, curMonth)) {
          day = (new Date(date).getDate());
          if (!((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)) && (((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)).map(item => item['name'])).includes((getObjectProperty(j, 'name')))))) {
            addItemToList(listToSave, ({ 'name': (getObjectProperty(j, 'name')),'squareId': (getObjectProperty(j, 'squareId')),'offerDate': (date.valueOf()),'image': (getObjectProperty(j, 'image')),'useImage': (getObjectProperty(j, 'useImage')),'modifier': (getObjectProperty(j, 'modifier')),'variation': (getObjectProperty(j, 'variation')),'order': (getObjectProperty(j, 'order')) }));
          }
        }
      }
    }
    ids = (await Backendless.Data.of('TodaysOffer').bulkCreate( listToSave ));
    offers = (await Backendless.Data.of('TodaysOffer').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId IN (\'',ids.join('\',\''),'\')'].join(''))).setPageSize(100)));
    while (!(offers.length % 100 == 0)) {
      result = (await Backendless.Data.of('TodaysOffer').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId IN (\'',ids.join('\',\''),'\')'].join(''))).setPageSize(100).setOffset((offers.length))));
      if (!result.length) {
        break;
      }
      addItemToList(offers, result);
    }
    for (var j_index2 in offers) {
      j = offers[j_index2];
      timestamp = (getObjectProperty(j, 'offerDate'));
      curMonth = String(new Date(timestamp).getMonth() + 1) + String(new Date(timestamp).getFullYear());
      if (getObjectProperty(___arguments.context.pageData, curMonth)) {
        day = (new Date(timestamp).getDate());
        if (getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)) {
          addItemToList((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)), j);
        } else {
          (getObjectProperty(___arguments.context.pageData, curMonth))[day] = [j];
        }
      }
    }
    ___arguments.context.pageData['tab'] = 'calendar';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/60f4db05f953036d6949e357b0532739/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['offerToMinDate'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));
  ___arguments.context.pageData['offerFrom'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));

  },
  /* handler:onChange */
  /* handler:onMinDateAssignment */
  async ['onMinDateAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date())))

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/fcfeee7845d953e1520ddcf27b9b616e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['offerTo'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/00f33d138b7de4ca40bac2887c9ab3f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), ___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'name');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/70bbb5e8c17400c2c65ef5c8a7c67342/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['section'] = 'location';
  ___arguments.context.pageData['sidebar'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c394470b2df24abb927bf18e0676480d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'location')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/16797755ef44d4ecd81cfafed079fabc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);
  try {
    await Backendless.Data.of('Location').bulkUpsert( (getObjectProperty(___arguments.context.pageData, 'allLocationList')) );
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', true);
    await new Promise(r => setTimeout(r, 5000 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);

  } catch (error) {
    await (async function(error) {
    	alert(error)
    })(error);
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/74c24fa4099d67d57e258872cdb6126e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e');
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  console.log(getObjectProperty(___arguments.context.pageData, 'itemModifiers'));
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/8ab8f7b3f5d15cd8a80dbffd0711681c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19a73d3d203b2d138072f2c3c62496c8', false);
  if (!(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19a73d3d203b2d138072f2c3c62496c8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    try {
      Object.assign((getObjectProperty(___arguments.context.pageData, 'activeItem')), (getObjectProperty(___arguments.context.pageData, 'editingItem')));
      Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));
      ___arguments.context.pageData['editItem'] = false;

    } catch (error) {
      await (async function(error) {
      	alert(`Something went wrong:\n ${error}`)
      })(error);

    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/00cb893644f585c7572572d13c6efe7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['editItem'] = false;
  Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4f77e65bbe84d88318a4fafb420ec41f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var id, itemChanges, variationList, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  variationList = (getObjectProperty(activeItem, 'variation'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (variationList.includes(id)) {
    removeItemInList(variationList, id, '');
  } else {
    addItemToList(variationList, id);
  }
  if (itemChanges.includes(id)) {
    removeItemInList(itemChanges, id, '');
  } else {
    addItemToList(itemChanges, id);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/998f58c7c6641cb6ce19ecc9536e079a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierId, itemChanges, modifierListId, allModifiers, modifierOptions, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  modifierId = (getObjectProperty(___arguments.context.dataModel, 'id'));
  modifierListId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'));
  allModifiers = (getObjectProperty(activeItem, 'modifier'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (allModifiers && ((Object.keys(allModifiers)).includes(modifierListId))) {
    modifierOptions = (getObjectProperty(allModifiers, modifierListId));
    if (modifierOptions.includes(modifierId)) {
      removeItemInList(modifierOptions, modifierId, '');
      if (!modifierOptions.length) {
        delete allModifiers[modifierListId];
      }
    } else {
      addItemToList(modifierOptions, modifierId);
    }
  } else {
    allModifiers[modifierListId] = [modifierId];
  }
  if (itemChanges.includes(modifierId)) {
    removeItemInList(itemChanges, modifierId, '');
  } else {
    addItemToList(itemChanges, modifierId);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/5013c39ad5a1b3db9e63c2ce5cef0fc2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  activeItem['variation'] = ((getObjectProperty(activeItem, 'squareObject.item_data.variations')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/a233aaf688a8a74693e86b1b7a22da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['variation'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4726ff1894aa7b9416d28fe8ec9c5e18/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'))] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'modifier_list_data.modifiers')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/295bb519abb50f1f809bc1c7e2a78845/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'))];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/9bcd3286b8c3c68534e1684d7312dc28/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['editItem'] = false;
  Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/b68823b496468bf4585512b1806d42b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6');
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48d68e29ab03cfd12e9712e9c7eeb6a6'), 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/5cc6ff837fda7407bbfc43bcc9082417/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.value);
  ___arguments.context.pageData['mainLocationId'] = ___arguments.value;
  var j_list = (getObjectProperty(___arguments.context.pageData, 'allLocationList'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['isMain'] = false;
  }
  (await (async function(list, id) {
  	return list.find(item => item.objectId === id)
  })((getObjectProperty(___arguments.context.pageData, 'allLocationList')), ___arguments.value))['isMain'] = true;
  console.log(getObjectProperty(___arguments.context.pageData, 'allLocationList'));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/4d6a79f8110c24cebdc2be92c924caa7/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.offers');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.dragId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/e03bc62323b6c7328c4181da3674c527/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.offers');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.dragId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'periodOffersList')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/48d68e29ab03cfd12e9712e9c7eeb6a6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  ___arguments.context.dataModel['dragId'] = (generateUUID().short());

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/dc73838b9e089d7d2e72bf16e7b5c5ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['section'] = 'customers';
  ___arguments.context.pageData['sidebar'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/953bd551accbc806b9dbfad62dabc57a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'customers')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['pageSize'] = 10;
  ___arguments.context.dataModel['pageOffset'] = 0;
  ___arguments.context.dataModel['pageNumber'] = ((getObjectProperty(___arguments.context.dataModel, 'pageOffset')) / (getObjectProperty(___arguments.context.dataModel, 'pageSize')) + 1);
  ___arguments.context.dataModel['sortField'] = 'name';
  ___arguments.context.dataModel['sortOrder'] = 'asc';
  customersAmount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause('phoneNumber LIKE \'+%\'')));
  ___arguments.context.dataModel['customersAmount'] = customersAmount;
  ___arguments.context.dataModel['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.dataModel, 'pageSize'))));
  ___arguments.context.dataModel['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.dataModel));
  console.log('abc');

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/45ef1d17d00bc17c481681125e240fc4/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('45ef1d17d00bc17c481681125e240fc4'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('45ef1d17d00bc17c481681125e240fc4'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'name';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'name' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/2216fcbab4ffd18264bf82611f683fa1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'phoneNumber';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2216fcbab4ffd18264bf82611f683fa1'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2216fcbab4ffd18264bf82611f683fa1'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'phoneNumber' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/80826327cf89e0d94f6623fddef1f9c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortField'] = 'email';
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['sortOrder'] = 'asc';
  }
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortField')) == 'email') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('80826327cf89e0d94f6623fddef1f9c4'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('80826327cf89e0d94f6623fddef1f9c4'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/landing_backup/components/63647a6e2bfb5723de1fff5242be3a2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f3157869327e8f061aa9f4cb6a8ea87c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) > 1 && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) - (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/c4510d57e82c46f6a1d407a9ca6de024/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var value;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  value = (Number((___arguments.value.replace((new RegExp('\\D', 'g')), ''))));
  if (value <= (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages')) && value >= 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = value;
  } else if (value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pagginPages'));
  } else if (value < 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c4510d57e82c46f6a1d407a9ca6de024'))['value'] = 1;
  }

  },
  /* handler:onChange */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.keyCode == 13) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = (((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageNumber')) - 1) * (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
  }

  },
  /* handler:onKeyDown */
  /* content */
}))

define('./pages/landing_backup/components/38fdc0d564cb93555f7b3af990942bbd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteUser')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/5654f8e9daed33de6d7d2575f251f3e1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['userToDelete'] = ___arguments.context.getComponentDataStoreByUid('bb7c09942da32af24212a83b96738f39');
  ___arguments.context.pageData['modal'] = 'deleteUser';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/d462ba7e784a2bfbf4fb69ebd36d9463/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/7ffbfd02194122b2b9fa64d5203f74a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(___arguments.context.pageData, 'userToDelete.objectId')) });
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/b989cd8feda832fee987dd98dacc0d5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['searchText'] = '';
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil((await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((String('phoneNumber LIKE \'+%\'') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''))))) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/705a38190973ff4b085f87d423dc1da3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = true;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    customersAmount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['phoneNumber LIKE \'+%\'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'showRegistered')) ? [' AND ','name IS NOT NULL',' AND ','email IS NOT NULL'].join('') : '',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''].join('')))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/640837d3a097dfdcd91892e2645b96eb/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.dataModel, 'hidden')))

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.dataModel['hidden'] = (!___arguments.value);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/94f9727936598f1c1675cd4bafc5b373/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var date, endDay, startDay, monthSummary, item, lastReconciliationDate, lastReconciliation, locationId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.dataModel['loading'] = true;
  await new Promise(r => setTimeout(r, 1000 || 0));
  while (!(getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
  lastReconciliation = (await Backendless.Data.of('Reconciliation').findFirst(Backendless.DataQueryBuilder.create().setProperties(['lastReconciliationDate', 'objectId'])));
  ___arguments.context.dataModel['lastReconciliation'] = lastReconciliation;
  lastReconciliationDate = (new Date((getObjectProperty(lastReconciliation, 'lastReconciliationDate'))));
  ___arguments.context.dataModel['lastReconciliationDateStr'] = (String('Last reconciliation date: ') + String(lastReconciliationDate.toLocaleDateString()));
  console.log(getObjectProperty(___arguments.context.dataModel, 'lastReconciliation'));
  ___arguments.context.dataModel['paymentSummary'] = (await BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b']());
  Object.assign(___arguments.context.dataModel, (await BackendlessUI.Functions.Custom['fn_fcec57351c91061d5927992ed935b2e9']((getObjectProperty(___arguments.context.dataModel, 'paymentSummary')), (getObjectProperty(___arguments.context.pageData, 'mainLocationId')))));
  ___arguments.context.dataModel['csvList'] = (await Backendless.Data.of('ReconciliationHistory').find(Backendless.DataQueryBuilder.create().setProperties(['url', 'name']).setSortBy('created desc').setPageSize(100)));
  console.log(getObjectProperty(___arguments.context.pageData, 'mainLocationId'));
  console.log(monthSummary);
  ___arguments.context.dataModel['loading'] = false;

  },
  /* handler:onMounted */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'section')) == 'payments')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/landing_backup/components/227d107f05ca7f016b8c72d53de80dd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6f77630d748cf047bc83a9a3a5b6659b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'section')) != 'payments') {
    ___arguments.context.pageData['section'] = 'payments';
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['showIncomeTable'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('94f9727936598f1c1675cd4bafc5b373'))['showPayoutTable'] = false;
  }
  ___arguments.context.pageData['sidebar'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/e533a22258fc99c6cb42074626642be2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54'](___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/93ea3b49c933286acc41dd122d8d41db/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), ___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/f10651b748adc02bd2a1989aaa4e030e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
    date = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate'));
    date.setMonth(((new Date(date).getMonth()) - 1));
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentsDate'] = date;
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['timeInterval'] = (await BackendlessUI.Functions.Custom['fn_67559c72e94d48924fe326d47e282977'](date));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
    }
    Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), date, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/d356090b202f94494949ac6df60e6779/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
    date = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate'));
    date.setMonth(((new Date(date).getMonth()) + 1));
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentsDate'] = date;
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['timeInterval'] = (await BackendlessUI.Functions.Custom['fn_67559c72e94d48924fe326d47e282977'](date));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
    }
    Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), date, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/ada9a22587a6580a47bc3a48e22f01d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ada9a22587a6580a47bc3a48e22f01d8'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['type'] = 'income';
  await new Promise(r => setTimeout(r, 1 || 0));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/eff17117d9eb48be484da79902aa01c3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['type'] = 'outgoing';
  await new Promise(r => setTimeout(r, 1 || 0));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'outgoing' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/3e8b6c2f40af464cbb6b024c9abee553/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var card;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance') {
    card = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'customer')), 'walletId'))) }).send());
    (getObjectProperty(___arguments.context.pageData, 'customer'))['wallet'] = card;
    (getObjectProperty(___arguments.context.pageData, 'customer'))['balance'] = (getObjectProperty(card, 'balance_money.amount'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a591c5686d8d9cb355e7eba9fb40ecc', true);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(card, 'balance_money.amount')) / 100)))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94912c8669c55c478fbc6851df1ce87d', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/landing_backup/components/9cb3eba81d1c1565c465748db4d568f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var user;


  ___arguments.context.pageData['customer'] = ___arguments.context.getComponentDataStoreByUid('bb7c09942da32af24212a83b96738f39');
  ___arguments.context.pageData['modal'] = 'balance';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/12c213fd49647dcefcfeceac2d54c56e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/bb7c09942da32af24212a83b96738f39/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/aa0ec2488a77fafeb0d8716b163be712/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var customersAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageOffset'] = 0;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pageNumber'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageOffset')) / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize')) + 1);
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')));
    customersAmount = (await Backendless.Data.of('Users').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['phoneNumber LIKE \'+%\'',___arguments.value ? [' AND ','name IS NOT NULL',' AND ','email IS NOT NULL'].join('') : '',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')) ? [' AND (',['name LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['phoneNumber LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),' OR ',['email LIKE \'%',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'searchText')),'%\''].join(''),')'].join('') : ''].join('')))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['customersAmount'] = customersAmount;
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['pagginPages'] = (Math.ceil(customersAmount / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a'), 'pageSize'))));
    ___arguments.context.getComponentDataStoreByUid('953bd551accbc806b9dbfad62dabc57a')['loading'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/c6e29ba49b64e8678919ffe51b74abf4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, wallet, order, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    if (!(getObjectProperty(user, 'customerId'))) {
      user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
    }
    if (!(getObjectProperty(user, 'walletId'))) {
      wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))))), 'gift_card'));
      await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      try {
        amount = 500;
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'line_items': [({ 'name': 'Welcome gift','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) })), 'payment'));
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
        user['wallet'] = wallet;
        user['walletId'] = (getObjectProperty(wallet, 'id'));
        user['bonusBalance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
        	return number.toFixed(2)
        })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
        await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(user, 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(user, 'customerId')) })) );

      } catch (error) {
        console.log(error);

      }
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6dafc3783e893a15101bb6ff439889a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, wallet, user, order, locationId, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Proceeding...';
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Manual refill','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      if (getObjectProperty(user, 'walletId')) {
        wallet = (getObjectProperty(user, 'wallet'));
      } else {
        wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(locationId))), 'gift_card'));
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      }
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
      	return number.toFixed(2)
      })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Done';
      await new Promise(r => setTimeout(r, 5000 || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = '';

    } catch (error) {
      console.log(error);
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = (String('Error: ') + String(getObjectProperty(error, 'message')));
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/b4b1bbad5badf82823b35199fc3eeaed/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4b1bbad5badf82823b35199fc3eeaed'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/145f77debf7fa897e9d3a73ef8dba7bb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationName'] = (getObjectProperty(___arguments.context.dataModel, 'locationName'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pageSize'] = 10;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showIncomeTable'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['transactionSearch'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', false);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await BackendlessUI.Functions.Custom['fn_de9c0eec9816df08f6e21d751148f188']((getObjectProperty(___arguments.context.dataModel, 'id')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')).length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/0919b9516c361e17e5b9f11efa8793a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationName'] = (getObjectProperty(___arguments.context.dataModel, 'locationName'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showPayoutTable'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', false);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pageSize'] = 10;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['transactionSearch'] = '';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await BackendlessUI.Functions.Custom['fn_de9c0eec9816df08f6e21d751148f188']((getObjectProperty(___arguments.context.dataModel, 'id')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')).length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')) ? null : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/4494ae2196374ab569430767b49971b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', true);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showIncomeTable'] = false;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f2ccb45c4ab65c62ec8481c5d7af7a06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', true);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showPayoutTable'] = false;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/0e69a9df25dabf74ba7b466ca95c81c3/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0e69a9df25dabf74ba7b466ca95c81c3'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0e69a9df25dabf74ba7b466ca95c81c3'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'amount';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6bd8b1d2ccbc4d96d0ee027340757bcc/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6bd8b1d2ccbc4d96d0ee027340757bcc'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6bd8b1d2ccbc4d96d0ee027340757bcc'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'name';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'title'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'title'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6d38af6628bee7ce49abf556cf61f171/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d38af6628bee7ce49abf556cf61f171'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d38af6628bee7ce49abf556cf61f171'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'user';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/427f14d92349e264ada64c64b49de852/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('427f14d92349e264ada64c64b49de852'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('427f14d92349e264ada64c64b49de852'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'created'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'created'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/49795d6f900f8234461c1587ab0a23ba/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'amount';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/40a58e39aa0f35f6013ccade35a42f88/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('40a58e39aa0f35f6013ccade35a42f88'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('40a58e39aa0f35f6013ccade35a42f88'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filteredList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'user';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/db78b60e1ff10bcd3a1bd735ef275381/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField'))));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField'))));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created' ? ['customers__header_icon', 'active'] : ['customers__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/landing_backup/components/a109324acb4bb0ec3503c9cbf0643dca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) + 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f5fd1cd4ebef152a790d0741e222e4e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) - 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/636f1b4adfedfcc592843588aa94ef3a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  } else if (___arguments.value < 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ___arguments.value;
  }
  stopSetTimeout('paggination');

  ;(function() {
    const callback = async () => {
        ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

    };

    const timerId = 'paggination';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/aa5bf7f90678135c451f91163c8c70ce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) - 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/7bee3754f6bdf8b622f4a761841be4f2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    console.log('> pages');
    console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7bee3754f6bdf8b622f4a761841be4f2'))['value'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  } else if (___arguments.value < 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7bee3754f6bdf8b622f4a761841be4f2'))['value'] = 1;
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ___arguments.value;
  }
  stopSetTimeout('paggination');

  ;(function() {
    const callback = async () => {
        ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

    };

    const timerId = 'paggination';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/7a05548cb23e1e5360fde3842e672645/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) + 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/f23687d6e3b8a503cd386f08637772ff/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), (___arguments.value.toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/4d322d4670491c87776538935cb9190c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (___arguments.value.toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/402123f67b41e9f627aa8b0d6a132bef/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/50f651cce01cc671a244676779c6df89/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var date, lastReconciliation;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', true);
  lastReconciliation = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'));
  date = (new Date());
  date.setDate(((new Date(date).getDate()) - 1));
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['reconciliationModalStr'] = (['Are you sure you want to reconcile all transactions between ',((new Date((getObjectProperty(lastReconciliation, 'lastReconciliationDate')))).toLocaleDateString()),' and ',(date.toLocaleDateString())].join(''));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var lastReconciliationDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  lastReconciliationDate = (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation.lastReconciliationDate'))));

  return ((new Date((new Date())).getDate()) == (new Date(lastReconciliationDate).getDate()) && (new Date((new Date())).getMonth() + 1) == (new Date(lastReconciliationDate).getMonth() + 1) && (new Date((new Date())).getFullYear()) == (new Date(lastReconciliationDate).getFullYear()))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/landing_backup/components/eba9575be99b3396baf9390be52a4ee6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/6244141c5f41cca81413b199bcaec762/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newCsvList, csvNameDate, j, item, csvList, locations, reconciliationTransactions, lastReconciliationDate, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['processingReconciliation'] = true;
  try {
    reconciliationTransactions = (await BackendlessUI.Functions.Custom['fn_1b3221e760f6705f5d2efe0d87fac07e'](null, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))));
    csvList = (await Promise.all(reconciliationTransactions.map(async item => {; return ({ 'amount': String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(item, 'amount')) / 100)))),'bonuses': (typeof (getObjectProperty(item, 'usedBonus')) === 'number' && !isNaN((getObjectProperty(item, 'usedBonus')))) ? String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(item, 'usedBonus')) / 100)))) : '$0.00','method': (getObjectProperty(item, 'method')),'location': (getObjectProperty(item, 'name')),'title': (getObjectProperty(item, 'title')),'user': (getObjectProperty(item, 'username')) });})));
    locations = (await (async function(list) {
    	return [...new Set(list.map(item => item.location))]
    })(csvList));
    date = (new Date());
    date.setDate(((new Date(date).getDate()) - 1));
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    csvNameDate = [(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()),'-',(new Date(date).getFullYear())].join('');
    ;(async () => {
        lastReconciliationDate = date;
      (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))['lastReconciliationDate'] = (lastReconciliationDate.valueOf());
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['lastReconciliationDateStr'] = (String('Last reconciliation date: ') + String(lastReconciliationDate.toLocaleDateString()));
      await Backendless.Data.of('Reconciliation').save( (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation')) );
      await Backendless.Data.of('Ledger').bulkUpdate( (['objectId IN (\'',(reconciliationTransactions.map(item => item['paymentId'])).join('\',\''),'\')'].join('')), ({ 'isReconciled': true }) );
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentSummary'] = (await BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b']());
      Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_fcec57351c91061d5927992ed935b2e9']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentSummary')), (getObjectProperty(___arguments.context.pageData, 'mainLocationId')))));

    })();
    newCsvList = [];
    for (var j_index in locations) {
      j = locations[j_index];
      newCsvList.unshift(({ 'url': (await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/list2csv`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'filePath': (['reconciliation/',(([j,'_',csvNameDate].join('')).replace((new RegExp('\\s', 'g')), '_')),'.csv'].join('')),'data': (await asyncListFilter(csvList, async (item) => {


       return ((getObjectProperty(item, 'location')) == j);
      })),'csvOptions': ({ 'header': true }) })),'name': [j,'_',csvNameDate].join('') }));
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['csvList'] = (newCsvList.concat((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'csvList'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', false);
    ;(async () => {
        await Backendless.Data.of('ReconciliationHistory').bulkCreate( newCsvList );

    })();

  } catch (error) {
    console.log(error);

  } finally {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['processingReconciliation'] = false;

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'processingReconciliation')) ? 'Creating reports...' : 'Yes')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing_backup/components/a191ab34d59d250f6fefd5532378d787/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/landing_backup/components/b27674164c3b35eba77c04c976e2f48a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, list, order, orders, orders2, tender;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orders = (await Backendless.Data.of('Order').find(Backendless.DataQueryBuilder.create().setWhereClause((['payment is not null',' AND ','payment.squareId is null'].join(''))).setRelated('payment').setPageSize(100)));
  console.log(orders);
  orders2 = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-order-history`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((orders.map(item => item['squareId']))))), 'orders'));
  console.log(orders2);
  for (var j_index in orders) {
    j = orders[j_index];
    order = (await (async function(list, id) {
    	return list.find(item => item.id === id)
    })(orders2, (getObjectProperty(j, 'squareId'))));
    tender = (getObjectProperty(order, 'tenders'))[0];
    (getObjectProperty(j, 'payment'))['amount'] = (getObjectProperty(tender, 'amount_money.amount'));
    (getObjectProperty(j, 'payment'))['squareId'] = (getObjectProperty(tender, 'payment_id'));
  }
  await Backendless.Data.of('Ledger').bulkUpsert( (orders.map(item => item['payment'])) );
  console.log(orders.map(item => item['payment']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing_backup/components/fe2abe980d6b9aad4b617ae48c277fae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var id, locations, j, orders;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locations = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setWhereClause('name is not null').setPageSize(100)));
  orders = (await Backendless.Data.of('Order').find(Backendless.DataQueryBuilder.create().setWhereClause((['location is null',' or ','location.name is null'].join(''))).setPageSize(100)));
  console.log(orders.length);
  for (var j_index in orders) {
    j = orders[j_index];
    id = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(j, 'squareId'))) }).send()), 'order.location_id'));
    await Backendless.Data.of('Order').setRelation(j, 'location', [({ 'objectId': (await (async function(list, id) {
    	return list.find(item => item.squareId === id).objectId
    })(locations, id)) })]);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var mainLocation, allLocationsList, item, activeLocationsList, newLocations, squareLocations, itemsLength, sortedItems, j, updateItem, itemsList, menuItems, result, offset, res;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.pageData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  if (await Backendless.UserService.isValidLogin()) {
    ___arguments.context.pageData['timestamps'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    if (getObjectProperty(___arguments.context.appData, 'locations')) {
      allLocationsList = (getObjectProperty(___arguments.context.appData, 'locations'));
    } else {
      await getLocationsFromSquare();
      allLocationsList = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((squareLocations.map(item => item['id'])))));
      if (squareLocations.length > allLocationsList.length) {
        newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (await Promise.all((await asyncListFilter(squareLocations, async (item) => {


         return (!((allLocationsList.map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
        })).map(async item => {; return ({ 'squareId': (getObjectProperty(item, 'id')),'name': (getObjectProperty(item, 'name')),'hidden': (getObjectProperty(item, 'status')) == 'INACTIVE' });}))),'timestampId': (getObjectProperty((await (async function(list) {
        	return list.find(item => item.type === 'location')
        })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) }));
        addItemToList(allLocationsList, newLocations);
      }
      ___arguments.context.appData['locations'] = allLocationsList;
    }
    activeLocationsList = (await asyncListFilter(allLocationsList, async (item) => {


     return ((getObjectProperty(item, 'isMain')) || !(getObjectProperty(item, 'hidden')));
    }));
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c394470b2df24abb927bf18e0676480d'))['allLocationList'] = allLocationsList;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c394470b2df24abb927bf18e0676480d'))['locationSelect'] = (await Promise.all(allLocationsList.map(async item => {; return ({ 'value': (getObjectProperty(item, 'objectId')),'label': (getObjectProperty(item, 'name')) });})));
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c394470b2df24abb927bf18e0676480d'))['paymentsLocationSelect'] = (await Promise.all(activeLocationsList.map(async item => {; return ({ 'value': (getObjectProperty(item, 'squareId')),'label': (getObjectProperty(item, 'name')) });})));
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c394470b2df24abb927bf18e0676480d'))['paymentLocation'] = (getObjectProperty(((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c394470b2df24abb927bf18e0676480d')), 'paymentsLocationSelect'))[0]), 'value'));
    mainLocation = (await (async function(list) {
    	return list.find(item => item.isMain)
    })(allLocationsList));
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c394470b2df24abb927bf18e0676480d'))['mainLocationId'] = (getObjectProperty(mainLocation, 'objectId'));
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('c394470b2df24abb927bf18e0676480d'))['mainLocationSquareId'] = (getObjectProperty(mainLocation, 'squareId'));
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/location/components/1e6780e56a12dc1e04f3b30a268105d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/09ee7b31196c7618e9438bb4d9766b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/67972eade3335ce9fbdf479ab15d7f6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newMenu, list, menuName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  menuName = (getObjectProperty(___arguments.context.pageData, 'newMenuName'));
  list = (getObjectProperty(___arguments.context.pageData, 'menuList'));
  if (menuName) {
    newMenu = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminCreateNewMenu`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'name': JSON.stringify(menuName) }).send());
    addItemToList(list, newMenu);
    delete ___arguments.context.pageData['newMenuName'];
    addItemToList((getObjectProperty(___arguments.context.pageData, 'timestamps')), (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(newMenu, 'objectId'))))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/5e23e373e2d47b6876070a18845ace2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/ca2fa06723b33b229969fa56df5fafb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/fffb1468d62269c33bb737b61e9b0659/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, list, categoryName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categoryName = (getObjectProperty(___arguments.context.pageData, 'newCategoryName'));
  list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  if (categoryName) {
    category = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'name': categoryName,'order': (list.length + 1),'menuId': (getObjectProperty(___arguments.context.pageData, 'editing.objectId')) }));
    category['item'] = [];
    addItemToList(list, category);
    await (async function() {
    	const category = [...document.querySelectorAll('.draggable')].slice(-1).pop();

    	category.draggable = true;
    	category.addEventListener('dragstart', (e) => {
    	  category.classList.add("is-dragging")
    	});

    	category.addEventListener('dragend', (e) => {
    	  category.classList.remove("is-dragging");
    	})
    })();
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
    delete ___arguments.context.pageData['newCategoryName'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/80451d7b74fdb049e2133bf6b39e6db7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  delete ___arguments.context.pageData['newItem'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/d2cdea06507901cd1ebfad2e5e7357d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['newItem'];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/81ef466fc0daa5438334ddd78906b37a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newItem, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'newItems')) && !!(getObjectProperty(___arguments.context.pageData, 'newItems')).length) {
    newItem = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'newItems')).map(async item => {; return ({ 'name': (getObjectProperty(item, 'item_data.name')),'squareId': (getObjectProperty(item, 'id')),'variation': ((getObjectProperty(item, 'item_data.variations')).map(item => item['id'])),'order': (getObjectProperty(___arguments.context.pageData, 'newItems')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).length });})));
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['item'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), newItem));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    delete ___arguments.context.pageData['newItems'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/2195e4413bb4651d9af9c0b5f35cc786/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/c517adbd761c078868c23a0417cb4009/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var categories, items, j, menu;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categories = (getObjectProperty(___arguments.context.pageData, 'activeMenu.category'));
  menu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'menuList')), menu, 'objectId');
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(menu, 'objectId'))));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(menu, 'objectId'))))));
  if (categories && !!categories.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((categories.map(item => item['objectId']))));
    items = [];
    var j_list = (categories.map(item => item['item']));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(items, j);
    }
    if (!!items.length) {
      await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
    }
  }
  ___arguments.context.pageData['activeScreen'] = 'menu';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/4d497b345ff8661374350be53f762fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/81eb464908fa214371fad0fc9da7da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, items;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  category = (getObjectProperty(___arguments.context.pageData, 'categoryToDelete'));
  items = (getObjectProperty(category, 'item'));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(category, 'objectId'))]));
  if (!!items.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
  }
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editing.category')), category, 'objectId');
  await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  ___arguments.context.pageData['activeScreen'] = 'category';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/f351bbec212b1a64f9bb319b2db7ed1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/04354a94d901e953b2dd83af23dfc6a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  item = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  if (getObjectProperty(item, 'objectId')) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'objectId');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'objectId');
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify());
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'order');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'order');
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  ___arguments.context.pageData['editItem'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/c2354b1d5cbb04b68e686f65e4f2329d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var newItems;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
  if (___arguments.value) {
    if (newItems) {
      addItemToList(newItems, ___arguments.context.dataModel);
    } else {
      ___arguments.context.pageData['newItems'] = [___arguments.context.dataModel];
      newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
    }
  } else {
    removeItemInList(newItems, ___arguments.context.dataModel, 'id');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'newItems')) && (((getObjectProperty(___arguments.context.pageData, 'newItems')).map(item => item['id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/location/components/d3d5a474c709ae6bfc919e3250f71aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/9de2b727d0c49ad7ffe601c858dcf287/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/c331492cf5738f93b85d32619a4f57f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/location/components/23144bc14fa4e6d42aec6ed66d7da7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/8665bb11608ea9cc4a16d186892a68c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/location/components/4813af6d506917b2fa7993d9713e7ce9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/cac1771cf2985c29f493f07d8404503a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/location/components/8c3ccce88fcdb265c83723bfa9c1e75a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    var j_list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'oldOrder')) {
        j['order'] = (getObjectProperty(j, 'oldOrder'));
        j['changed'] = false;
        delete j['oldOrder'];
      }
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/7ea3c78b76896a9f6a8bfe3b8467a838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
    await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/1f3e6b85e1da4e78f09d0552d3ac44da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/2ea8f662f17978e7b5b36f0b8b868237/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/7e92da65f1f758a852c2bcbdd48d8741/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('23aa26dfcdc16688b2065c0d25da2ca4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/8bd7b99185b6ae3788dfce84b9d1b728/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu') {
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ac65e8de202b30c7ef2d83bd7be8675a')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/location/components/68a4020f2c832f04358838766e864a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/3678cc2ead6cad6dce141ea1ebbcb756/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/6526393763e3a4c7909bcc2a81665aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/e53b4263b63bbe926862d1f42016faca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/f167cc4a529265896f775dd1f58fcf4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/853de7c6b4c7d7081c68f5f3f27699a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/8528128c771cbd8e7a729a72e25e9622/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteMenu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/21b6da3853232976a77f5053705d4a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/6bfec90cc41883d3ef91acc5545e7a83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'todaysOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/38cc2ab7137c43ce012215fafa838566/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, item, j, modifiers, offers, offersToDelete, selectedModifiers, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'offers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  offersToDelete = (getObjectProperty(___arguments.context.pageData, 'offersToDelete'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'offerDate': (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp')),'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'offers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/location/components/147fb543216568d9044a403b78dd33c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/9741a2f63a781c339b7455042b7a2fcc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/a2fb35e6eb5ed781034fb01ed0c13555/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, offers, offersToDelete, ids, offersToCreate, item, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  timestamp = (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'));
  addItemToList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'offers')), 1, async (item) => {
     item['order'] = ((getObjectProperty(___arguments.context.pageData, 'offers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length);


   return (getObjectProperty(item, 'name'));
  })));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/3b7ca5aa8ac86a1dda15706d607f5aa4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length) {
    ___arguments.context.pageData['periodOffersList'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'periodOffers')), 1, async (item) => {
       item['order'] = ((getObjectProperty(___arguments.context.pageData, 'periodOffers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length);


     return (getObjectProperty(item, 'name'));
    }))));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/location/components/2347c3e33bd631fcb82155b1b63efee4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, offers, selectedModifiers, j, modifiers, item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'periodOffers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'periodOffers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/location/components/d15846c1fa19d2c5bfc2071d973a670e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/94a2c4e501c37ae46467178715faa1db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/95412ce446138ea82f1b0a2953127ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/de042b7c17155e92ca7b0324cfbf86f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffers')), ___arguments.context.getComponentDataStoreByUid('7bbeefa6fdc6e11dd6dc2fd798ad5749'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/3af29a84d03d50b21fe45a3ee8a3683a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'offers')), ___arguments.context.getComponentDataStoreByUid('552b29f96cbcda34a01a373cb661dd0b'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/f2e60cedd8461306cf55af0501dd8c39/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  async ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return (await (async function(files, percent, width) {
	for (let index = 0; index < files.length; index++) {
	  const file = files[index]
	  const fileName = file.name

	  const content = await readFileAsDataURL(file)

	  const img = await getNewImage(content)

	  const elem = document.createElement('canvas')

	  const widthImage = !width ? img.width : img.width > width ? width : img.width
	  const scaleFactor = img.width > width ? width && width / img.width : 1
	  const heightImage = width ? img.height * scaleFactor : img.height

	  elem.width = widthImage
	  elem.height = heightImage

	  const ctx = elem.getContext('2d')

	  ctx.drawImage(img, 0, 0, widthImage, heightImage)

	  const blob = await getCanvasBlob(ctx.canvas, percent)

	  const newFile = new File([blob], fileName, {
	    type: 'image/jpeg',
	    lastModified: Date.now()
	  })

	  files[index] = newFile
	}

	async function readFileAsDataURL(file) {
	  const result = await new Promise((resolve) => {
	    const fileReader = new FileReader()

	    fileReader.onload = (e) => resolve(e.target.result)

	    fileReader.readAsDataURL(file)
	  })

	  return result
	}

	async function getNewImage(content) {
	  const result = await new Promise((resolve) => {
	    const img = new Image()

	    img.src = content

	    img.onload = () => resolve(img)
	  })

	  return result
	}

	async function getCanvasBlob(canvas, percent) {
	  const result = await new Promise((resolve) => {
	    canvas.toBlob((blob) => {
	      resolve(blob)
	    }, 'image/jpeg', percent)
	  })

	  return result
	}

	return files
})(___arguments.files, 1, 600))

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedOfferImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedOfferImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/location/components/c2b51d85ca38df7c478af3ec9a3dd2ef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editOfferImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/afc9812e122100175a02defad0086d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['useImage'] = true;
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/50d646aff2df909d6f3cfa42834e46d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/5ab734948432b055f37bd2f5d79f49a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/73bf13a62b99deee7c49d918cd4e9b65/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/location/components/f6f8336f7239d56e550334dc3c796739/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/location/components/fa79760dc4aee4548cf8b0b361de395c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/5cc6ff837fda7407bbfc43bcc9082417/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.value);
  ___arguments.context.pageData['mainLocationId'] = ___arguments.value;
  var j_list = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c394470b2df24abb927bf18e0676480d'), 'allLocationList'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['isMain'] = false;
  }
  (await (async function(list, id) {
  	return list.find(item => item.objectId === id)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c394470b2df24abb927bf18e0676480d'), 'allLocationList')), ___arguments.value))['isMain'] = true;
  console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('c394470b2df24abb927bf18e0676480d'), 'allLocationList'));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/location/components/38fdc0d564cb93555f7b3af990942bbd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteUser')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/location/components/d462ba7e784a2bfbf4fb69ebd36d9463/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/7ffbfd02194122b2b9fa64d5203f74a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(___arguments.context.pageData, 'userToDelete.objectId')) });
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/640837d3a097dfdcd91892e2645b96eb/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.dataModel, 'hidden')))

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.dataModel['hidden'] = (!___arguments.value);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/location/components/3e8b6c2f40af464cbb6b024c9abee553/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var card;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance') {
    card = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'customer')), 'walletId'))) }).send());
    (getObjectProperty(___arguments.context.pageData, 'customer'))['wallet'] = card;
    (getObjectProperty(___arguments.context.pageData, 'customer'))['balance'] = (getObjectProperty(card, 'balance_money.amount'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a591c5686d8d9cb355e7eba9fb40ecc', true);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(card, 'balance_money.amount')) / 100)))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94912c8669c55c478fbc6851df1ce87d', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/location/components/12c213fd49647dcefcfeceac2d54c56e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/c6e29ba49b64e8678919ffe51b74abf4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, wallet, order, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    if (!(getObjectProperty(user, 'customerId'))) {
      user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
    }
    if (!(getObjectProperty(user, 'walletId'))) {
      wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))))), 'gift_card'));
      await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      try {
        amount = 500;
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'line_items': [({ 'name': 'Welcome gift','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) })), 'payment'));
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
        user['wallet'] = wallet;
        user['walletId'] = (getObjectProperty(wallet, 'id'));
        user['bonusBalance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
        	return number.toFixed(2)
        })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
        await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(user, 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(user, 'customerId')) })) );

      } catch (error) {
        console.log(error);

      }
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/6dafc3783e893a15101bb6ff439889a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, wallet, user, order, locationId, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Proceeding...';
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Manual refill','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      if (getObjectProperty(user, 'walletId')) {
        wallet = (getObjectProperty(user, 'wallet'));
      } else {
        wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(locationId))), 'gift_card'));
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      }
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
      	return number.toFixed(2)
      })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Done';
      await new Promise(r => setTimeout(r, 5000 || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = '';

    } catch (error) {
      console.log(error);
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = (String('Error: ') + String(getObjectProperty(error, 'message')));
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/location/components/b4b1bbad5badf82823b35199fc3eeaed/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4b1bbad5badf82823b35199fc3eeaed'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/location/components/8b39c08d8789848677dd3923e547da4f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);
  try {
    console.log(getObjectProperty(___arguments.context.appData, 'locations'));
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminUpdateLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c394470b2df24abb927bf18e0676480d'), 'allLocationList')),'timestampId': (getObjectProperty((await (async function(list) {
    	return list.find(item => item.type === 'location')
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) });
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', true);
    await new Promise(r => setTimeout(r, 5000 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);

  } catch (error) {
    await (async function(error) {
    	alert(error)
    })(error);
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, username, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, true  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (getObjectProperty(user, 'admin')) {
    ___arguments.context.appData['currentUser'] = user;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);
  } else {
    await Backendless.UserService.logout();
    await setError((new Error('User is not an administrator')));
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/lto/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var j, updateItem, sortedItems, itemsList, item, menuItems, result, squareLocations, offset, res;

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.pageData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  if (await Backendless.UserService.isValidLogin()) {
    ___arguments.context.pageData['timestamps'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    ___arguments.context.pageData['offerImages'] = (await Backendless.Files.listing(encodePath('img/offer'), '', false, 100, 0));
    if (getObjectProperty(___arguments.context.appData, 'squareItems')) {
      sortedItems = (getObjectProperty(___arguments.context.appData, 'squareItems'));
    } else {
      await getMenus();
      sortedItems = (await asyncListSort(menuItems, 1, async (item) => {


       return (getObjectProperty(item, 'item_data.name'));
      }));
      ;(async () => {
          if (getObjectProperty(___arguments.context.appData, 'itemImages')) {
          itemsList = (getObjectProperty(___arguments.context.appData, 'itemImages'));
        } else {
          itemsList = [];
          await getItemImages(0);
          ___arguments.context.appData['itemImages'] = itemsList;
        }
        for (var j_index in itemsList) {
          j = itemsList[j_index];
          updateItem = (await (async function(list, squareId) {
          	return list.find(item => item.id === squareId)
          })(sortedItems, (getObjectProperty(j, 'squareId'))));
          if (updateItem) {
            updateItem['image'] = (getObjectProperty(j, 'image'));
            updateItem['objectId'] = (getObjectProperty(j, 'objectId'));
          }
        }
        ___arguments.context.appData['squareItems'] = sortedItems;

      })();
    }
    await getModifiers();
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
    ___arguments.context.pageData['section'] = 'menu';
    ___arguments.context.pageData['months'] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/lto/components/d3d5a474c709ae6bfc919e3250f71aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/9de2b727d0c49ad7ffe601c858dcf287/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/c331492cf5738f93b85d32619a4f57f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/23144bc14fa4e6d42aec6ed66d7da7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/8665bb11608ea9cc4a16d186892a68c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/4813af6d506917b2fa7993d9713e7ce9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/cac1771cf2985c29f493f07d8404503a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/lto/components/3678cc2ead6cad6dce141ea1ebbcb756/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/b116e0c3fd0b99229caf59dc727aad92/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var curMonth;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'curMonth'))) {
    curMonth = String(new Date((new Date())).getMonth() + 1) + String(new Date((new Date())).getFullYear());
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, (new Date()));
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6']((new Date())));
      ___arguments.context.pageData['curMonth'] = curMonth;
    }
  }
  if ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'calendar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/lto/components/215cf642addae3297f43b7bba46c5d69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, (new Date()));
    curMonth = String(new Date((new Date())).getMonth() + 1) + String(new Date((new Date())).getFullYear());
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((new Date())).getMonth()) != (getObjectProperty(___arguments.context.pageData, 'month')) ? true : (new Date((new Date())).getFullYear()) != (getObjectProperty(___arguments.context.pageData, 'year')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/5126650d543e444e109719cfe7ef6668/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, offers, timestamp, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  today = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date())));
  if ((getObjectProperty(___arguments.context.dataModel, 'current')) && (getObjectProperty(___arguments.context.dataModel, 'timestamp')) >= today) {
    timestamp = (getObjectProperty(___arguments.context.dataModel, 'timestamp'));
    offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date(timestamp).getDate())));
    if (!offers) {
      (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth'))))[(new Date(timestamp).getDate())] = [];
      offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date(timestamp).getDate())));
    }
    ___arguments.context.pageData['activeDate'] = ___arguments.context.dataModel;
    ___arguments.context.pageData['offersTitle'] = (['Edit offers for ',(getObjectProperty(___arguments.context.pageData, 'months'))[((new Date(timestamp).getMonth() + 1) - 1)],' ',(getObjectProperty(___arguments.context.dataModel, 'date')),', ',(new Date(timestamp).getFullYear())].join(''));
    ___arguments.context.pageData['editingDate'] = ({ 'timestamp': timestamp,'offers': !offers.length ? [] : (await asyncListSort((await Promise.all(offers.map(async item => {; return (JSON.parse((JSON.stringify(item))));}))), 1, async (item) => {


     return (getObjectProperty(item, 'order'));
    })) });
    ___arguments.context.pageData['offersToDelete'] = [];
    ___arguments.context.pageData['tab'] = 'dayOffer';
  }

  },
  /* handler:onClick */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    var timestamp, today;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  timestamp = (getObjectProperty(___arguments.context.dataModel, 'timestamp'));
  today = (new Date());
  if ((new Date(today).getMonth() + 1) == (new Date(timestamp).getMonth() + 1) && (new Date(today).getFullYear()) == (new Date(timestamp).getFullYear()) && (new Date(today).getDate()) == (new Date(timestamp).getDate())) {
    ___arguments.context.dataModel['today'] = true;
  }

  },
  /* handler:onMounted */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'current')) && (getObjectProperty(___arguments.context.dataModel, 'timestamp')) >= (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date()))) ? ((getObjectProperty(___arguments.context.dataModel, 'today')) ? ['calendar__date', 'today'] : ['calendar__date']) : ['calendar__date', 'disabled'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/6bfec90cc41883d3ef91acc5545e7a83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'todaysOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/38cc2ab7137c43ce012215fafa838566/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'offers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0825cb1f2baf102f79a4fd0e9a753b1c'), 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/lto/components/147fb543216568d9044a403b78dd33c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/9741a2f63a781c339b7455042b7a2fcc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/a2fb35e6eb5ed781034fb01ed0c13555/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, offers, offersToDelete, ids, offersToCreate, item, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  timestamp = (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'));
  addItemToList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'offers')), 1, async (item) => {
     item['order'] = ((getObjectProperty(___arguments.context.pageData, 'offers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length);


   return (getObjectProperty(item, 'name'));
  })));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/6a2a5def5cef564788ff3486e38165bc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'current')) ? ['calendar__date_text', 'current'] : ['calendar__date_text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/3b7ca5aa8ac86a1dda15706d607f5aa4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length) {
    ___arguments.context.pageData['periodOffersList'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'periodOffers')), 1, async (item) => {
       item['order'] = ((getObjectProperty(___arguments.context.pageData, 'periodOffers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length);


     return (getObjectProperty(item, 'name'));
    }))));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/lto/components/2347c3e33bd631fcb82155b1b63efee4/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'periodOffers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e341d9fb99789754f248a817bb7bde16'), 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/lto/components/d15846c1fa19d2c5bfc2071d973a670e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/33af7aa5b562cfc7e4bfcaaaa5911beb/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'current')) && (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'timestamp'))).getDate()))) ? (await asyncListSort((getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5126650d543e444e109719cfe7ef6668'), 'timestamp'))).getDate()))), 1, async (item) => {


 return (getObjectProperty(item, 'order'));
})) : [])

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/lto/components/94a2c4e501c37ae46467178715faa1db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/95412ce446138ea82f1b0a2953127ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/f76c81716cdf482219c4e1116adaa5a6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'calendar')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/d58da494e6b59bef13a2339706a6154f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'dayOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/067b523acaef90acc9f71ea96cc6b276/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/b7e7f2b31fe2b0a81f4d8d7736b64734/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'image')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/lto/components/c6f66dccefed7194b01575c63ea29f8e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var totalMods, selectedMods, j, k, item, squareItemModifiers;

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.dataModel['dragId'] = (generateUUID().short());
  while (!(getObjectProperty(___arguments.context.appData, 'itemImages'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  ___arguments.context.dataModel['itemImage'] = (getObjectProperty((await (async function(list, id) {
  	return list.find(item => item.squareId === id)
  })((getObjectProperty(___arguments.context.appData, 'itemImages')), (getObjectProperty(___arguments.context.dataModel, 'squareId')))), 'image'));
  ___arguments.context.dataModel['squareObject'] = (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.dataModel, 'squareId')), (getObjectProperty(___arguments.context.appData, 'squareItems'))));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.dataModel['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.dataModel['itemModifiers'] = [];
  }
  totalMods = (getObjectProperty(___arguments.context.dataModel, 'squareObject.item_data.variations')).length;
  selectedMods = (getObjectProperty(___arguments.context.dataModel, 'variation')).length;
  var k_list = (getObjectProperty(___arguments.context.dataModel, 'itemModifiers'));
  for (var k_index in k_list) {
    k = k_list[k_index];
    totalMods = (typeof totalMods == 'number' ? totalMods : 0) + (getObjectProperty(k, 'modifier_list_data.modifiers')).length;
  }
  var k_list2 = (getObjectProperty(___arguments.context.dataModel, 'modifier'));
  for (var k_index2 in k_list2) {
    k = k_list2[k_index2];
    selectedMods = (typeof selectedMods == 'number' ? selectedMods : 0) + k.length;
  }
  ___arguments.context.dataModel['descr'] = ([selectedMods,'/',totalMods,' options selected'].join(''));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/lto/components/f2e60cedd8461306cf55af0501dd8c39/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  async ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return (await (async function(files, percent, width) {
	for (let index = 0; index < files.length; index++) {
	  const file = files[index]
	  const fileName = file.name

	  const content = await readFileAsDataURL(file)

	  const img = await getNewImage(content)

	  const elem = document.createElement('canvas')

	  const widthImage = !width ? img.width : img.width > width ? width : img.width
	  const scaleFactor = img.width > width ? width && width / img.width : 1
	  const heightImage = width ? img.height * scaleFactor : img.height

	  elem.width = widthImage
	  elem.height = heightImage

	  const ctx = elem.getContext('2d')

	  ctx.drawImage(img, 0, 0, widthImage, heightImage)

	  const blob = await getCanvasBlob(ctx.canvas, percent)

	  const newFile = new File([blob], fileName, {
	    type: 'image/jpeg',
	    lastModified: Date.now()
	  })

	  files[index] = newFile
	}

	async function readFileAsDataURL(file) {
	  const result = await new Promise((resolve) => {
	    const fileReader = new FileReader()

	    fileReader.onload = (e) => resolve(e.target.result)

	    fileReader.readAsDataURL(file)
	  })

	  return result
	}

	async function getNewImage(content) {
	  const result = await new Promise((resolve) => {
	    const img = new Image()

	    img.src = content

	    img.onload = () => resolve(img)
	  })

	  return result
	}

	async function getCanvasBlob(canvas, percent) {
	  const result = await new Promise((resolve) => {
	    canvas.toBlob((blob) => {
	      resolve(blob)
	    }, 'image/jpeg', percent)
	  })

	  return result
	}

	return files
})(___arguments.files, 1, 600))

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedOfferImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedOfferImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/lto/components/c2b51d85ca38df7c478af3ec9a3dd2ef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editOfferImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/afc9812e122100175a02defad0086d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['useImage'] = true;
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/50d646aff2df909d6f3cfa42834e46d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/5ab734948432b055f37bd2f5d79f49a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/fb3719614bf7550212476e5f7833e0b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editingOffer'] = ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e');
  ___arguments.context.pageData['modal'] = 'editOfferImage';
  ___arguments.context.pageData['selectedUrl'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/73bf13a62b99deee7c49d918cd4e9b65/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/f6f8336f7239d56e550334dc3c796739/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/fa79760dc4aee4548cf8b0b361de395c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/60f4db05f953036d6949e357b0532739/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['offerToMinDate'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));
  ___arguments.context.pageData['offerFrom'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));

  },
  /* handler:onChange */
  /* handler:onMinDateAssignment */
  async ['onMinDateAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date())))

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/lto/components/4f77e65bbe84d88318a4fafb420ec41f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var id, itemChanges, variationList, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  variationList = (getObjectProperty(activeItem, 'variation'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (variationList.includes(id)) {
    removeItemInList(variationList, id, '');
  } else {
    addItemToList(variationList, id);
  }
  if (itemChanges.includes(id)) {
    removeItemInList(itemChanges, id, '');
  } else {
    addItemToList(itemChanges, id);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/998f58c7c6641cb6ce19ecc9536e079a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierId, itemChanges, modifierListId, allModifiers, modifierOptions, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  modifierId = (getObjectProperty(___arguments.context.dataModel, 'id'));
  modifierListId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'));
  allModifiers = (getObjectProperty(activeItem, 'modifier'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (allModifiers && ((Object.keys(allModifiers)).includes(modifierListId))) {
    modifierOptions = (getObjectProperty(allModifiers, modifierListId));
    if (modifierOptions.includes(modifierId)) {
      removeItemInList(modifierOptions, modifierId, '');
      if (!modifierOptions.length) {
        delete allModifiers[modifierListId];
      }
    } else {
      addItemToList(modifierOptions, modifierId);
    }
  } else {
    allModifiers[modifierListId] = [modifierId];
  }
  if (itemChanges.includes(modifierId)) {
    removeItemInList(itemChanges, modifierId, '');
  } else {
    addItemToList(itemChanges, modifierId);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/5013c39ad5a1b3db9e63c2ce5cef0fc2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  activeItem['variation'] = ((getObjectProperty(activeItem, 'squareObject.item_data.variations')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/a233aaf688a8a74693e86b1b7a22da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['variation'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/4726ff1894aa7b9416d28fe8ec9c5e18/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'))] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'modifier_list_data.modifiers')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/295bb519abb50f1f809bc1c7e2a78845/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id'))];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/4d6a79f8110c24cebdc2be92c924caa7/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.offers');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.dragId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/lto/components/e03bc62323b6c7328c4181da3674c527/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.offers');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.dragId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'periodOffersList')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/lto/components/1a5803e560248da7d0f7581b8a93db5b/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var recentOffers, totalMods, selectedMods, j, k, item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.pageData['tab'] = 'calendar';
  recentOffers = (await Backendless.Data.of('TodaysOffer').find(Backendless.DataQueryBuilder.create().setProperties(['name', 'squareId', 'modifier', 'variation', 'image', 'useImage']).setSortBy('count(name) desc').setGroupBy('name').setPageSize(4)));
  while (!((getObjectProperty(___arguments.context.appData, 'squareItems')) && (getObjectProperty(___arguments.context.pageData, 'modifierList')))) {
    await new Promise(r => setTimeout(r, 1000 || 0));
  }
  for (var j_index in recentOffers) {
    j = recentOffers[j_index];
    j['itemImage'] = (getObjectProperty((await (async function(list, squareId) {
    	return list.find(item => item.id === squareId)
    })((getObjectProperty(___arguments.context.appData, 'squareItems')), (getObjectProperty(j, 'squareId')))), 'image'));
    if (!(getObjectProperty(j, 'itemImage'))) {
      j['itemImage'] = '/img/no-img.png';
    }
    j['squareObject'] = (await (async function(condition, list) {
    	return list.find(item => item.id === condition)
    })((getObjectProperty(j, 'squareId')), (getObjectProperty(___arguments.context.appData, 'squareItems'))));
    squareItemModifiers = (getObjectProperty(j, 'squareObject.item_data.modifier_list_info'));
    if (squareItemModifiers) {
      j['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


       return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
      }));
    } else {
      j['itemModifiers'] = [];
    }
    totalMods = (getObjectProperty(j, 'squareObject.item_data.variations')).length;
    selectedMods = (getObjectProperty(j, 'variation')).length;
    var k_list = (getObjectProperty(j, 'itemModifiers'));
    for (var k_index in k_list) {
      k = k_list[k_index];
      totalMods = (typeof totalMods == 'number' ? totalMods : 0) + (getObjectProperty(k, 'modifier_list_data.modifiers')).length;
    }
    var k_list2 = (getObjectProperty(j, 'modifier'));
    for (var k_index2 in k_list2) {
      k = k_list2[k_index2];
      selectedMods = (typeof selectedMods == 'number' ? selectedMods : 0) + k.length;
    }
    j['descr'] = ([selectedMods,'/',totalMods,' options selected'].join(''));
  }
  ___arguments.context.pageData['recentOffers'] = recentOffers;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/lto/components/0bb810b2e97a586ab37f0b7fd1749529/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'tab')) == 'item')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/lto/components/1a1339e6feb36e6a995bd3ed7765cd4f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  ___arguments.context.pageData['periodOffersList'] = [];
  ___arguments.context.pageData['tab'] = 'periodOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/3ab4dc45892c839e10ed8d196fa892de/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    date = (new Date());
    date.setFullYear((getObjectProperty(___arguments.context.pageData, 'year')));
    date.setMonth(((getObjectProperty(___arguments.context.pageData, 'month')) - 1));
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, date);
    curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6'](date));
    }
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/f3bdb83247a49f81624ee513ac14c0c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var curMonth, date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1651053db6a41f65fbc9a441c166d547')), 'display'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', true);
    date = (new Date());
    date.setDate(1);
    date.setFullYear((getObjectProperty(___arguments.context.pageData, 'year')));
    date.setMonth(((getObjectProperty(___arguments.context.pageData, 'month')) + 1));
    await BackendlessUI.Functions.Custom['fn_cec8cdbb0f189ed2751b0981095a423e'](___arguments.context.pageData, date);
    curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
    if (!(getObjectProperty(___arguments.context.pageData, curMonth))) {
      ___arguments.context.pageData[curMonth] = (await BackendlessUI.Functions.Custom['fn_76da9e8603d2501c3881ff997b9cafb6'](date));
    }
    ___arguments.context.pageData['curMonth'] = curMonth;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1651053db6a41f65fbc9a441c166d547', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1d7baaf865b23c6576ef594bb847df59', true);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b116e0c3fd0b99229caf59dc727aad92')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/f91ddcbe10891d6781bf70f7bfdeb172/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/f54853e80be9360f022a6df50bbe1494/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var ids;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ids = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewOffer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')))));
  (getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth'))))[(new Date((getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'))).getDate())] = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetOffers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': ids,'offset': 0 }));
  ___arguments.context.pageData['tab'] = 'calendar';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/07d536bac6b2eca3ef89036b76993a76/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/e3bc1c2dae88b3b5f048a2255f199da8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e');
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'squareObject')),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  ___arguments.context.pageData['previousTab'] = 'dayOffer';
  ___arguments.context.pageData['tab'] = 'item';
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/c7727e72f15a26c74e3b645ac79935e8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var offers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'name');
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId')) {
    offers = (getObjectProperty((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.pageData, 'curMonth')))), (new Date((getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'))).getDate())));
    removeItemInList(offers, ___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId');
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteOffer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c6f66dccefed7194b01575c63ea29f8e'), 'objectId'))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/722ad6cf71d52332a0960841110e495d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['editingOffer'] = ___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f');
  ___arguments.context.pageData['modal'] = 'editOfferImage';
  ___arguments.context.pageData['selectedUrl'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/d7262dfed358d99dff34327c4127b1ea/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), ___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'name');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/68c746bdc5a562dad6b55c18a435827d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'image')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/lto/components/227e09e1af38d204bb58e8b1fa07b38f/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  ___arguments.context.dataModel['dragId'] = (generateUUID().short());

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/lto/components/d1d3d61dd864a7841fd787ec10812f9b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f');
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'squareId')), (getObjectProperty(___arguments.context.appData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('227e09e1af38d204bb58e8b1fa07b38f'), 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  ___arguments.context.pageData['previousTab'] = 'periodOffer';
  ___arguments.context.pageData['tab'] = 'item';
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/f7295b57bc5f168b1fd1d21f396281db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['filteredSquareItems'] = (getObjectProperty(___arguments.context.appData, 'squareItems'));
  ___arguments.context.pageData['offers'] = [];
  ___arguments.context.pageData['modal'] = 'todaysOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/25065283cebf0a98de651b42314a8864/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/4928f3862ee5659fbb8919cb481e4cba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, offset, res;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getOffers(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetOffers`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'ids': ids,'offset': offset }));
  addItemToList(offers, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getOffers(offset);
  }
}


  if ((getObjectProperty(___arguments.context.pageData, 'offerTo')) && (getObjectProperty(___arguments.context.pageData, 'offerFrom')) && !!(getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    listToSave = [];
    var i_end = ((getObjectProperty(___arguments.context.pageData, 'offerTo')) - (getObjectProperty(___arguments.context.pageData, 'offerFrom'))) / (1000 * 60 * 60 * 24);
    var i_inc = 1;
    if (0 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 0; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      date = (new Date((getObjectProperty(___arguments.context.pageData, 'offerFrom'))));
      date.setDate(((new Date((getObjectProperty(___arguments.context.pageData, 'offerFrom'))).getDate()) + i));
      var j_list = (getObjectProperty(___arguments.context.pageData, 'periodOffersList'));
      for (var j_index in j_list) {
        j = j_list[j_index];
        curMonth = String(new Date(date).getMonth() + 1) + String(new Date(date).getFullYear());
        if (getObjectProperty(___arguments.context.pageData, curMonth)) {
          day = (new Date(date).getDate());
          if (!((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)) && (((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)).map(item => item['name'])).includes((getObjectProperty(j, 'name')))))) {
            addItemToList(listToSave, ({ 'name': (getObjectProperty(j, 'name')),'squareId': (getObjectProperty(j, 'squareId')),'offerDate': (date.valueOf()),'image': (getObjectProperty(j, 'image')),'useImage': (getObjectProperty(j, 'useImage')),'modifier': (getObjectProperty(j, 'modifier')),'variation': (getObjectProperty(j, 'variation')),'order': (getObjectProperty(j, 'order')) }));
          }
        }
      }
    }
    ids = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewOffer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(listToSave)));
    offers = [];
    await getOffers(0);
    for (var j_index2 in offers) {
      j = offers[j_index2];
      timestamp = (getObjectProperty(j, 'offerDate'));
      curMonth = String(new Date(timestamp).getMonth() + 1) + String(new Date(timestamp).getFullYear());
      if (getObjectProperty(___arguments.context.pageData, curMonth)) {
        day = (new Date(timestamp).getDate());
        if (getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)) {
          addItemToList((getObjectProperty((getObjectProperty(___arguments.context.pageData, curMonth)), day)), j);
        } else {
          (getObjectProperty(___arguments.context.pageData, curMonth))[day] = [j];
        }
      }
    }
    ___arguments.context.pageData['tab'] = 'calendar';
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/d978d919780d5e9208ca81a3754739d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['tab'] = 'calendar';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/d67ea0ff3f7636b26fafcf86d4dd4101/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['filteredSquareItems'] = (getObjectProperty(___arguments.context.appData, 'squareItems'));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'periodOffer';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/63c31ff5ba21f08e3c13078988aaf16a/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4f77e65bbe84d88318a4fafb420ec41f'), 'id')))) ? 'check circle' : 'check')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/lto/components/a45221ca083b45e9b93882adf3d1ed0f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('4f77e65bbe84d88318a4fafb420ec41f'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/f7e730174ba796554635af714e6a1ee1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length,'/',(getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.variations')).length,' selected'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/lto/components/fdfc266a6b0a189fc09fc3a96afd1c9a/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('998f58c7c6641cb6ce19ecc9536e079a'), 'id')))) ? 'check circle' : 'check')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/lto/components/2688f6c5335e69fe7775e7e714cb2eae/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('998f58c7c6641cb6ce19ecc9536e079a'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lto/components/85e4efc159b3a85312157a53fc4f8aa3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editingItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))) ? [(getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'id')))).length,'/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'modifier_list_data.modifiers')).length,' selected'].join('') : ['0/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('427b52d1fe5a8fa8922646f6a637cc8b'), 'modifier_list_data.modifiers')).length,' selected'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/lto/components/0825cb1f2baf102f79a4fd0e9a753b1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var id, offersToDelete, selectedModifiers, offers, item, j, modifiers, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'offers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  offersToDelete = (getObjectProperty(___arguments.context.pageData, 'offersToDelete'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if ((offers.map(item => item['squareId'])).includes(id)) {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  } else {
    addItemToList(offers, ({ 'offerDate': (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp')),'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['modal__item'];
  if ((getObjectProperty(___arguments.context.pageData, 'offers')) && (((getObjectProperty(___arguments.context.pageData, 'offers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))) {
    addItemToList(classes, 'active');
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'searchValue')) && ((((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'searchValue')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1)) {
    addItemToList(classes, 'result');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('7e27e6731961a0a6e678b7db07654cd8')[(((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0825cb1f2baf102f79a4fd0e9a753b1c'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/lto/components/e341d9fb99789754f248a817bb7bde16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var id, offers, selectedModifiers, j, modifiers, item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'periodOffers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if ((offers.map(item => item['squareId'])).includes(id)) {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  } else {
    addItemToList(offers, ({ 'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['modal__item'];
  if ((getObjectProperty(___arguments.context.pageData, 'periodOffers')) && (((getObjectProperty(___arguments.context.pageData, 'periodOffers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))) {
    addItemToList(classes, 'active');
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'searchValue')) && ((((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'searchValue')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1)) {
    addItemToList(classes, 'result');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('5ee6183466afc8b70541fed19d530a41')[(((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e341d9fb99789754f248a817bb7bde16'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/lto/components/84926a75c8018f11cbef40d7a8eafa42/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/95077225093f0e83bf69a0ba8c58153b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  timestamp = (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'));
  ___arguments.context.getComponentDataStoreByUid('84926a75c8018f11cbef40d7a8eafa42')['order'] = ((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length + 1);
  addItemToList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), ___arguments.context.getComponentDataStoreByUid('84926a75c8018f11cbef40d7a8eafa42'));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/c958dd0d41710aa5e2165ba7fb732c9d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['editItem'] = false;
  ___arguments.context.pageData['tab'] = (getObjectProperty(___arguments.context.pageData, 'previousTab'));
  Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/6372dcf4e1d3948c615c876793c18a92/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19a73d3d203b2d138072f2c3c62496c8', false);
  if (!(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19a73d3d203b2d138072f2c3c62496c8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    try {
      Object.assign((getObjectProperty(___arguments.context.pageData, 'activeItem')), (getObjectProperty(___arguments.context.pageData, 'editingItem')));
      Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));
      ___arguments.context.pageData['tab'] = (getObjectProperty(___arguments.context.pageData, 'previousTab'));
      ___arguments.context.pageData['editItem'] = false;

    } catch (error) {
      await (async function(error) {
      	alert(`Something went wrong:\n ${error}`)
      })(error);

    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/710c57813e3f762437c16cfb92089f08/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.getComponentDataStoreByUid('b392669ec08e45dc389df0fea65a0ab1')['order'] = ((getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length + 1);
  addItemToList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), ___arguments.context.getComponentDataStoreByUid('b392669ec08e45dc389df0fea65a0ab1'));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/ad838cca1672d58d3c97e5c82c91e9fc/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.pageData['offerFrom'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date())));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/lto/components/156610bdeb8e2c1411567a02e36f9207/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['offerTo'] = (await BackendlessUI.Functions.Custom['fn_f00721b3fe57ced8dac4cb530886ec5b']((new Date(___arguments.value))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/lto/components/7e27e6731961a0a6e678b7db07654cd8/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'filteredSquareItems'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/lto/components/21c4bb83967ec56c1e19c108a90ce4a3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var components, item;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  stopSetTimeout('searchTimer');

  ;(function() {
    const callback = async () => {
        components = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'filteredSquareItems')), async (item) => {


     return ((((getObjectProperty(item, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf(((___arguments.value.replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1);
    }));
    ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['searchResult'] = components;
    if (!components.length) {
      ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['searchResultText'] = '0 of 0';
    } else {
      ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['selected'] = 1;
      ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')),' of ',components.length].join(''));
      (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('7e27e6731961a0a6e678b7db07654cd8')), (((getObjectProperty((components[0]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
    }

    };

    const timerId = 'searchTimer';
    const timerDelay = 100;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/lto/components/d67163bb53e589d2597f43c5a5ce787d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')) > 1) {
      ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')) - 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['selected'] = (components.length);
    }
    ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('7e27e6731961a0a6e678b7db07654cd8')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/76774082a7d97aeda2fb861f78cd4181/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')) < components.length) {
      ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')) + 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['selected'] = 1;
    }
    ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('7e27e6731961a0a6e678b7db07654cd8')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/6b0b334ac01fc4d6ff979fe2b4ae3d38/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['searchResult'] = [];
  ___arguments.context.getComponentDataStoreByUid('6bfec90cc41883d3ef91acc5545e7a83')['searchValue'] = '';
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7e27e6731961a0a6e678b7db07654cd8')), 'el'))['scrollTop'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/f9aeeb9b9fb9c8e954ed11d47d56d1d5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var components, item;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  stopSetTimeout('searchTimer');

  ;(function() {
    const callback = async () => {
        components = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'filteredSquareItems')), async (item) => {


     return ((((getObjectProperty(item, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf(((___arguments.value.replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1);
    }));
    ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['searchResult'] = components;
    if (!components.length) {
      ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['searchResultText'] = '0 of 0';
    } else {
      ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['selected'] = 1;
      ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')),' of ',components.length].join(''));
      (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('5ee6183466afc8b70541fed19d530a41')), (((getObjectProperty((components[0]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
    }

    };

    const timerId = 'searchTimer';
    const timerDelay = 100;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/lto/components/5ee6183466afc8b70541fed19d530a41/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'filteredSquareItems'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/lto/components/3083515505776ed79e319f996b6d60f3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')) > 1) {
      ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')) - 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['selected'] = (components.length);
    }
    ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('5ee6183466afc8b70541fed19d530a41')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/bf8c54cb66489b922dfc85187492695a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')) < components.length) {
      ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')) + 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['selected'] = 1;
    }
    ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('5ee6183466afc8b70541fed19d530a41')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/lto/components/04d1a96fd93b0841b919dcf9b35cd654/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['searchResult'] = [];
  ___arguments.context.getComponentDataStoreByUid('d15846c1fa19d2c5bfc2071d973a670e')['searchValue'] = '';
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5ee6183466afc8b70541fed19d530a41')), 'el'))['scrollTop'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var activeLocationsList, allLocationsList, item, mainLocation, menuItems, newLocations, result, sortedItems, squareLocations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.appData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}


  if (await Backendless.UserService.isValidLogin()) {
    ___arguments.context.pageData['timestamps'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    if (!(getObjectProperty(___arguments.context.appData, 'modifierList'))) {
      await getModifiers();
    }
    if (getObjectProperty(___arguments.context.appData, 'locations')) {
      allLocationsList = (getObjectProperty(___arguments.context.appData, 'locations'));
    } else {
      await getLocationsFromSquare();
      allLocationsList = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((squareLocations.map(item => item['id'])))));
      if (squareLocations.length > allLocationsList.length) {
        newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (await Promise.all((await asyncListFilter(squareLocations, async (item) => {


         return (!((allLocationsList.map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
        })).map(async item => {; return ({ 'squareId': (getObjectProperty(item, 'id')),'name': (getObjectProperty(item, 'name')),'hidden': (getObjectProperty(item, 'status')) == 'INACTIVE' });}))),'timestampId': (getObjectProperty((await (async function(list) {
        	return list.find(item => item.type === 'location')
        })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) }));
        addItemToList(allLocationsList, newLocations);
      }
      ___arguments.context.appData['locations'] = allLocationsList;
    }
    activeLocationsList = (await asyncListFilter(allLocationsList, async (item) => {


     return ((getObjectProperty(item, 'isMain')) || !(getObjectProperty(item, 'hidden')));
    }));
    ___arguments.context.pageData['locationList'] = activeLocationsList;
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    } else {
      ___arguments.context.pageData['activeScreen'] = 'menu';
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/menu/components/1bbe429b40c9d4a733d964be9b8565d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'));
  ___arguments.context.pageData['editing'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'objectId')),'category': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'), 'category')) : [] });
  ___arguments.context.pageData['activeMenu'] = ___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368');
  ___arguments.context.pageData['activeScreen'] = 'category';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/986adcc1402d905b0f177d556b1902f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'loaded'))) {
    ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a')['item'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'item')), 1, async (item) => {


     return (getObjectProperty(item, 'order'));
    }));
    ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a')['loaded'] = true;
  }
  ___arguments.context.pageData['activeCategory'] = ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a');
  ___arguments.context.pageData['editingCategory'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'objectId')),'item': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'item')),'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'image')) });
  ___arguments.context.pageData['activeScreen'] = 'categoryEdit';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/0beef512e1426846bafd37ed9d761f65/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.dataModel;
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.dataModel, 'name')),'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'modifier': (getObjectProperty(___arguments.context.dataModel, 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.dataModel, 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.dataModel, 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.dataModel, 'squareId')), (getObjectProperty(___arguments.context.pageData, 'squareItems')))),'image': (getObjectProperty(___arguments.context.dataModel, 'image')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  ___arguments.context.pageData['editItem'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/5239e6cb34e82ad2b5863dbdb167bb2e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['categoryToDelete'] = ___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a');
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/1e6780e56a12dc1e04f3b30a268105d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/09ee7b31196c7618e9438bb4d9766b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/67972eade3335ce9fbdf479ab15d7f6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newMenu, list, menuName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  menuName = (getObjectProperty(___arguments.context.pageData, 'newMenuName'));
  list = (getObjectProperty(___arguments.context.pageData, 'menuList'));
  if (menuName) {
    newMenu = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminCreateNewMenu`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'name': JSON.stringify(menuName) }).send());
    addItemToList(list, newMenu);
    delete ___arguments.context.pageData['newMenuName'];
    addItemToList((getObjectProperty(___arguments.context.pageData, 'timestamps')), (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(newMenu, 'objectId'))))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/5e23e373e2d47b6876070a18845ace2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/ca2fa06723b33b229969fa56df5fafb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/fffb1468d62269c33bb737b61e9b0659/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, list, categoryName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categoryName = (getObjectProperty(___arguments.context.pageData, 'newCategoryName'));
  list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  if (categoryName) {
    category = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'name': categoryName,'order': (list.length + 1),'menuId': (getObjectProperty(___arguments.context.pageData, 'editing.objectId')) }));
    console.log(category);
    category['item'] = [];
    addItemToList(list, category);
    await (async function() {
    	const category = [...document.querySelectorAll('.draggable')].slice(-1).pop();

    	category.draggable = true;
    	category.addEventListener('dragstart', (e) => {
    	  category.classList.add("is-dragging")
    	});

    	category.addEventListener('dragend', (e) => {
    	  category.classList.remove("is-dragging");
    	})
    })();
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
    delete ___arguments.context.pageData['newCategoryName'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/80451d7b74fdb049e2133bf6b39e6db7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  delete ___arguments.context.pageData['newItem'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/d2cdea06507901cd1ebfad2e5e7357d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['newItem'];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/81ef466fc0daa5438334ddd78906b37a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newItem, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'newItems')) && !!(getObjectProperty(___arguments.context.pageData, 'newItems')).length) {
    newItem = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'newItems')).map(async item => {; return ({ 'name': (getObjectProperty(item, 'item_data.name')),'squareId': (getObjectProperty(item, 'id')),'variation': ((getObjectProperty(item, 'item_data.variations')).map(item => item['id'])),'order': (getObjectProperty(___arguments.context.pageData, 'newItems')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).length,'image': (getObjectProperty(item, 'image')) });})));
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['item'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), newItem));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    delete ___arguments.context.pageData['newItems'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/2195e4413bb4651d9af9c0b5f35cc786/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/c517adbd761c078868c23a0417cb4009/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var categories, items, j, menu, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categories = (getObjectProperty(___arguments.context.pageData, 'activeMenu.category'));
  menu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(menu, 'objectId'))));
  timestamp = (await (async function(list, id) {
  	return list.find(item => item.menuId === id)
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(menu, 'objectId'))));
  if (timestamp) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(timestamp, 'objectId'))));
  }
  if (categories && !!categories.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((categories.map(item => item['objectId']))));
    items = [];
    var j_list = (categories.map(item => item['item']));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(items, j);
    }
    if (!!items.length) {
      await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
    }
  }
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'menuList')), menu, 'objectId');
  ___arguments.context.pageData['activeScreen'] = 'menu';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/4d497b345ff8661374350be53f762fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/81eb464908fa214371fad0fc9da7da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, items;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  category = (getObjectProperty(___arguments.context.pageData, 'categoryToDelete'));
  items = (getObjectProperty(category, 'item'));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(category, 'objectId'))]));
  if (!!items.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
  }
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editing.category')), category, 'objectId');
  await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  ___arguments.context.pageData['activeScreen'] = 'category';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/f351bbec212b1a64f9bb319b2db7ed1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/04354a94d901e953b2dd83af23dfc6a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  item = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  if (getObjectProperty(item, 'objectId')) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(item, 'objectId'))]));
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'objectId');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'objectId');
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'order');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'order');
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  ___arguments.context.pageData['activeScreen'] = 'categoryEdit';
  ___arguments.context.pageData['editItem'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/c2354b1d5cbb04b68e686f65e4f2329d/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'newItems')) && (((getObjectProperty(___arguments.context.pageData, 'newItems')).map(item => item['id'])).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6919d06e5a7d6429d9dcbf31292728a7'), 'id')))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/menu/components/8b737ccdaf9110cbaca2486f5b66d65c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8a4e6e1c84782adba95feedd7e491eaf'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/8a4e6e1c84782adba95feedd7e491eaf/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var id, itemChanges, variationList, activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  variationList = (getObjectProperty(activeItem, 'variation'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (variationList.includes(id)) {
    removeItemInList(variationList, id, '');
  } else {
    addItemToList(variationList, id);
  }
  if (itemChanges.includes(id)) {
    removeItemInList(itemChanges, id, '');
  } else {
    addItemToList(itemChanges, id);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/0b1b67e2386aec0cc056e8978a931e7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var modifierId, modifierListId, allModifiers, modifierOptions, activeItem, id, itemChanges;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  modifierId = (getObjectProperty(___arguments.context.dataModel, 'id'));
  modifierListId = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'));
  allModifiers = (getObjectProperty(activeItem, 'modifier'));
  itemChanges = (getObjectProperty(___arguments.context.pageData, 'itemChanges'));
  if (allModifiers && ((Object.keys(allModifiers)).includes(modifierListId))) {
    modifierOptions = (getObjectProperty(allModifiers, modifierListId));
    if (modifierOptions.includes(modifierId)) {
      removeItemInList(modifierOptions, modifierId, '');
      if (!modifierOptions.length) {
        delete allModifiers[modifierListId];
      }
    } else {
      addItemToList(modifierOptions, modifierId);
    }
  } else {
    allModifiers[modifierListId] = [modifierId];
  }
  if (itemChanges.includes(modifierId)) {
    removeItemInList(itemChanges, modifierId, '');
  } else {
    addItemToList(itemChanges, modifierId);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var modifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) ? ['multiselect__item', 'active'] : ['multiselect__item'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/b1ee4c414c8998c21ae7eb6ca6a61b76/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0b1b67e2386aec0cc056e8978a931e7f'), 'id')))) ? ['multiselect__text', 'active'] : ['multiselect__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/159bd412fc2f405825bb2cf9eb85d532/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'editImage';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/d3d5a474c709ae6bfc919e3250f71aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/9de2b727d0c49ad7ffe601c858dcf287/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/c331492cf5738f93b85d32619a4f57f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/23144bc14fa4e6d42aec6ed66d7da7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/8665bb11608ea9cc4a16d186892a68c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/4813af6d506917b2fa7993d9713e7ce9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/cac1771cf2985c29f493f07d8404503a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/menu/components/979a5b4b8d832e33f19cacccab19ed0f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var activeMenu, menuList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeMenu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  menuList = (getObjectProperty(___arguments.context.dataModel, 'menu'));
  if (___arguments.value) {
    ___arguments.context.dataModel['menu'] = activeMenu;
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminAssignMenuToLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locationId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'menuId': (getObjectProperty(activeMenu, 'objectId')) });
  } else {
    delete ___arguments.context.dataModel['menu'];
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenuFromLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locationId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'menuId': (getObjectProperty(activeMenu, 'objectId')) });
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'menu')) && (getObjectProperty(___arguments.context.dataModel, 'menu.objectId')) == (getObjectProperty(___arguments.context.pageData, 'activeMenu.objectId')))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/menu/components/64857fd6e70c62b865fcc1424a7f2a32/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('.category__list');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);

  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.objectId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editing.category')));

  },
  /* handler:onMounted */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var j, categories, menu, editing, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  editing = (getObjectProperty(___arguments.context.pageData, 'editing'));
  categories = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  editing['category'] = (await asyncListSort(categories, 1, async (item) => {


   return (getObjectProperty(item, 'order'));
  }));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/menu/components/04debe5e790704d2b4c89796d3ca2677/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, allModifiers, modifierId, modifierListId, modifierOptions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'))] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'modifier_list_data.modifiers')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/1434445d9e5999340fba3f0b895d8b14/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  delete (getObjectProperty(___arguments.context.pageData, 'editingItem.modifier'))[(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id'))];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/6aa1ff2d4df14ec805c8339f4618538e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem, id, variationList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  activeItem = (getObjectProperty(___arguments.context.pageData, 'editingItem'));
  activeItem['variation'] = ((getObjectProperty(activeItem, 'squareObject.item_data.variations')).map(item => item['id']));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/00770631cfa6404945b9bb356763e13f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var activeItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['variation'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/8e7abbdeee6840fb651b245837ef0fba/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('306c664c1a22ebf1c33c1df5d0c4ce4a'), 'item')).length)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/8c3ccce88fcdb265c83723bfa9c1e75a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    var j_list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'oldOrder')) {
        j['order'] = (getObjectProperty(j, 'oldOrder'));
        j['changed'] = false;
        delete j['oldOrder'];
      }
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/7ea3c78b76896a9f6a8bfe3b8467a838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
    await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/1f3e6b85e1da4e78f09d0552d3ac44da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/2ea8f662f17978e7b5b36f0b8b868237/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/7e92da65f1f758a852c2bcbdd48d8741/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('23aa26dfcdc16688b2065c0d25da2ca4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/cf2bc997cf9c1dba0748c8248f534a2e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/b211bf62b7d9f101e38aef0ce48c4e6a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/a9aaa9d1bd498382baf6d00f95f1f02a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'menu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/8bd7b99185b6ae3788dfce84b9d1b728/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu') {
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ac65e8de202b30c7ef2d83bd7be8675a')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/menu/components/68a4020f2c832f04358838766e864a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/3678cc2ead6cad6dce141ea1ebbcb756/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/6526393763e3a4c7909bcc2a81665aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/e53b4263b63bbe926862d1f42016faca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/f167cc4a529265896f775dd1f58fcf4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/853de7c6b4c7d7081c68f5f3f27699a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/8528128c771cbd8e7a729a72e25e9622/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteMenu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/21b6da3853232976a77f5053705d4a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/1a3683ceb692147e6a24c7dca66c72e3/bundle.js', [], () => ({
  /* content */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('img/items/') + String(getObjectProperty(___arguments.context.pageData, 'activeItem.objectId')))

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'editingItem'))['image'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['upload'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('1a3683ceb692147e6a24c7dca66c72e3');

  },
  /* handler:onUploadSuccess */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      ___arguments.context.pageData['upload'] = true;

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
      ___arguments.context.pageData['upload'] = true;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('1a3683ceb692147e6a24c7dca66c72e3');

  },
  /* handler:onUploadFail */
  /* content */
}))

define('./pages/menu/components/f4c9054be843a0964b99aca8ae9c9665/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function(categoryList) {
  	const items = [...document.querySelectorAll('.draggable')];
  	const container = document.querySelector('#item_list');
  	let dragItem = null;

  	items.forEach(draggable => {
  	  draggable.draggable = true;
  	  draggable.addEventListener('dragstart', (e) => {
  	    draggable.classList.add("is-dragging")
  	  });

  	  draggable.addEventListener('dragend', (e) => {
  	    draggable.classList.remove("is-dragging");
  	  })
  	})

  	container.addEventListener('dragover', (e) => {
  	  e.preventDefault();
  	  const afterElement = getDragAfterElement(container, e.clientY);
  	  const draggable = document.querySelector(".is-dragging");

  	  if (afterElement === null) {
  	    container.appendChild(draggable);
  	  } else {
  	    container.insertBefore(draggable, afterElement);
  	  }
  	});

  	container.addEventListener('drop', (e) => {
  	  const newList = [...document.querySelectorAll('.draggable')].map(item => item.id);
  	  categoryList.forEach(item => {
  	    const newOrder = newList.indexOf(item.squareId) + 1

  	    if(item.order === newOrder) return;

  	    if(!item.oldOrder) item.oldOrder = item.order;
  	    item.order = newOrder;
  	    item.changed = true;
  	  })
  	});

  	function getDragAfterElement(container, y) {
  	  const draggableElements = [
  	    ...container.querySelectorAll(".draggable:not(.is-dragging)")
  	  ];

  	  return draggableElements.reduce(
  	    (closest, child) => {
  	      const box = child.getBoundingClientRect();
  	      const offset = y - box.top - box.height / 2;

  	      if (offset < 0 && offset > closest.offset) {
  	        return {
  	          offset,
  	          element: child
  	        };
  	      } else {
  	        return closest;
  	      }
  	    },
  	    { offset: Number.NEGATIVE_INFINITY }
  	  ).element;
  	}



  })((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/menu/components/5e55ebfe0de58d894d6312c2ac2dc56e/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.dataModel, 'order'))) {
    ___arguments.context.dataModel['order'] = (((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).map(item => item['objectId'])).indexOf(getObjectProperty(___arguments.context.dataModel, 'objectId')) + 1);
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(___arguments.context.dataModel));
  }

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/e78ecc0f872f4c9a853383c68cd86e38/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, squareItemModifiers, squareObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function listsGetSortCompare(type, direction) {
  var compareFuncs = {
    "NUMERIC": function(a, b) {
        return parseFloat(a) - parseFloat(b); },
    "TEXT": function(a, b) {
        return a.toString() > b.toString() ? 1 : -1; },
    "IGNORE_CASE": function(a, b) {
        return a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  ___arguments.context.pageData['activeItem'] = ___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e');
  squareObject = (await (async function(condition, list) {
  	return list.find(item => item.id === condition)
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'squareId')), (getObjectProperty(___arguments.context.appData, 'squareItems'))));
  ___arguments.context.pageData['editingItem'] = ({ 'name': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'name')),'objectId': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'objectId')),'modifier': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'modifier')) ? (JSON.parse((JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'modifier')))))) : ({  }),'variation': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'variation')) ? (await (async function(list) {
  	return [...list]
  })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'variation')))).slice().sort(listsGetSortCompare("IGNORE_CASE", 1)) : [],'squareObject': squareObject,'image': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'image')) : (getObjectProperty(squareObject, 'image')),'order': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'order')),'showVariations': (getObjectProperty(squareObject, 'item_data.variations')).length > 1,'notesEnabled': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('5e55ebfe0de58d894d6312c2ac2dc56e'), 'notesEnabled')) });
  ___arguments.context.pageData['itemChanges'] = [];
  squareItemModifiers = (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.modifier_list_info'));
  if (squareItemModifiers) {
    ___arguments.context.pageData['itemModifiers'] = (await asyncListFilter((getObjectProperty(___arguments.context.appData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
  } else {
    ___arguments.context.pageData['itemModifiers'] = [];
  }
  if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  ___arguments.context.pageData['activeScreen'] = 'item';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/b0d2bc649d62bc74a077376295241b9c/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var j, updateItem, sortedItems, itemsList, item, menuItems, result, offset, res;

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}


  ___arguments.context.pageData['menuList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetMenus`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.pageData['categoryImages'] = (await Backendless.Files.listing(encodePath('img/category'), '', false, 100, 0));
  if (getObjectProperty(___arguments.context.appData, 'squareItems')) {
    sortedItems = (getObjectProperty(___arguments.context.appData, 'squareItems'));
  } else {
    await getMenus();
    sortedItems = (await asyncListSort(menuItems, 1, async (item) => {


     return (getObjectProperty(item, 'item_data.name'));
    }));
    ___arguments.context.appData['squareItems'] = sortedItems;
  }
  ;(async () => {
      if (getObjectProperty(___arguments.context.appData, 'itemImages')) {
      itemsList = (getObjectProperty(___arguments.context.appData, 'itemImages'));
    } else {
      itemsList = [];
      await getItemImages(0);
      ___arguments.context.appData['itemImages'] = itemsList;
    }
    for (var j_index in itemsList) {
      j = itemsList[j_index];
      updateItem = (await (async function(list, squareId) {
      	return list.find(item => item.id === squareId)
      })(sortedItems, (getObjectProperty(j, 'squareId'))));
      if (updateItem) {
        updateItem['image'] = (getObjectProperty(j, 'image'));
        updateItem['objectId'] = (getObjectProperty(j, 'objectId'));
      }
    }

  })();

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/menu/components/99712b94756980479026ddf7df1b8e69/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addMenu';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/59479e0552fd3ed8304594012a164f9d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var clonedMenu, j, k;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368'));
  clonedMenu = (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('1e449a06cd374e4a8f0ecce79408e368')))));
  clonedMenu['name'] = (String(getObjectProperty(clonedMenu, 'name')) + String(' - copy'));
  delete clonedMenu['objectId'];
  delete clonedMenu['created'];
  clonedMenu['objectId'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(clonedMenu))), 'objectId'));
  var j_list = (getObjectProperty(clonedMenu, 'category'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    delete j['objectId'];
    delete j['ownerId'];
    delete j['created'];
    delete j['updated'];
    var k_list = (getObjectProperty(j, 'item'));
    for (var k_index in k_list) {
      k = k_list[k_index];
      delete k['objectId'];
      delete k['ownerId'];
      delete k['created'];
      delete k['updated'];
    }
    j['objectId'] = (getObjectProperty((await Backendless.Data.of('Category').deepSave( j )), 'objectId'));
    console.log(j);
  }
  await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminAssignCategoryToMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'menuId': (getObjectProperty(clonedMenu, 'objectId')),'categories': (getObjectProperty(clonedMenu, 'category')) });
  console.log(clonedMenu);
  addItemToList((getObjectProperty(___arguments.context.pageData, 'menuList')), clonedMenu);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/1e449a06cd374e4a8f0ecce79408e368/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var locations, item, categoriesAmount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.dataModel['locationsStr'] = 'loading ...';
  categoriesAmount = (getObjectProperty(___arguments.context.dataModel, 'category')).length;
  ___arguments.context.dataModel['categoriesStr'] = (String(categoriesAmount) + String(categoriesAmount == 1 ? ' category' : ' categories'));
  ___arguments.context.dataModel['foodStr'] = (String((await (async function(arr) {
  	return arr.flat();
  })(((getObjectProperty(___arguments.context.dataModel, 'category')).map(item => item['item'])))).length) + String(' foods & drinks'));
  while (!(getObjectProperty(___arguments.context.pageData, 'locationList'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  locations = ((await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'locationList')), async (item) => {


   return ((getObjectProperty(item, 'menu.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'objectId')));
  })).map(item => item['name']));
  ___arguments.context.dataModel['locationsStr'] = (locations.length > 2 ? [locations.slice(0, 2).join(', '),' +',locations.length - 2,' more'].join('') : (locations.length > 0 ? locations.join(', ') : 'Locations not assigned'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/menu/components/3f9435478e3c7d594d8b3788635c5e29/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/b72469fa0d5aaa6ccdda0bcadee1f5e9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('403fa05323e8020b447c04a6f4468451'))['content'] = 'saved';
  await new Promise(r => setTimeout(r, 3000 || 0));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('403fa05323e8020b447c04a6f4468451'))['content'] = 'save changes';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/069715246d15527b4d5961f953edbed8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteMenu';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/5ec5c43eefaeaa1e1e6feaf4b95e4d88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['categoryToDelete'] = (getObjectProperty(___arguments.context.pageData, 'editingCategory'));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteCategory';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/f83af2c72d48041b26c48cf9ca19e168/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('174c615ee3522ad1f5dbc73a988aeb03'))['content'] = 'saved';
  await new Promise(r => setTimeout(r, 3000 || 0));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('174c615ee3522ad1f5dbc73a988aeb03'))['content'] = 'save changes';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/21d746aede07ba1b47111432ee5ff9f3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var item, addedIds;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'addItem';
  ___arguments.context.pageData['uniqueItems'] = (getObjectProperty(___arguments.context.appData, 'squareItems'));
  ___arguments.context.pageData['newItems'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/9a372d4958f232a3e6af1a697e98041c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = 'deleteItem';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/aeb9ddffe2de39e19a4525b1ca3ed661/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, savedItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('00e7a064117b152b13ffce0241090eed', false);
  if (!(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('00e7a064117b152b13ffce0241090eed', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
    try {
      savedItem = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveItem`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'variation': (getObjectProperty(___arguments.context.pageData, 'editingItem.variation')),'modifier': (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'editingItem.modifier')))),'objectId': (getObjectProperty(___arguments.context.pageData, 'editingItem.objectId')),'name': (getObjectProperty(___arguments.context.pageData, 'editingItem.name')),'image': (getObjectProperty(___arguments.context.pageData, 'editingItem.image')),'order': (getObjectProperty(___arguments.context.pageData, 'editingItem.order')),'squareId': (getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.id')),'notesEnabled': (getObjectProperty(___arguments.context.pageData, 'editingItem.notesEnabled')) }))));
      Object.assign((getObjectProperty(___arguments.context.pageData, 'activeItem')), savedItem);
      Object.assign((getObjectProperty(___arguments.context.pageData, 'editingItem')), ({ 'variation': '','modifier': '' }));
      console.log(getObjectProperty(___arguments.context.pageData, 'editingCategory'));
      ___arguments.context.pageData['editItem'] = false;
      ___arguments.context.pageData['activeScreen'] = 'categoryEdit';

    } catch (error) {
      await (async function(error) {
      	alert(`Something went wrong:\n ${error}`)
      })(error);

    }
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/23aa26dfcdc16688b2065c0d25da2ca4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'item')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/cb7f9da2e9b413d244ff63c824d6bea7/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'variation')).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('8a4e6e1c84782adba95feedd7e491eaf'), 'id')))) ? 'check circle' : 'check')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/menu/components/218c8f68c6a4e763a429fe514cb31c31/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && !!(Object.keys((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')))).length && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) && ((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).includes((getObjectProperty(___arguments.context.getComponentDataStoreByUid('0b1b67e2386aec0cc056e8978a931e7f'), 'id')))) ? 'check circle' : 'check')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/menu/components/3e16cd0ca4e97b8935e68d9eda020b74/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'editingItem'));
  console.log((getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.variations')).length);
  console.log([(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length,'/',(getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.variations')).length,' selected'].join(''));

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'editingItem.variation')).length,'/',(getObjectProperty(___arguments.context.pageData, 'editingItem.squareObject.item_data.variations')).length,' selected'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/menu/components/44f954b20bb15dd40fc15bc27024bed9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).length);
  console.log(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'));
  console.log(['','/','',' selected'].join(''));

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'editingItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')) && (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))) ? [(getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editingItem')), 'modifier')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'id')))).length,'/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'modifier_list_data.modifiers')).length,' selected'].join('') : ['0/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('17c3b51e01a263d694e9afb99c0856f9'), 'modifier_list_data.modifiers')).length,' selected'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/menu/components/6d7b4a5e2422f7776b43a68ca69a07b4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) != 'menu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/77c2a80187cad465a3a7e59bc398cc6e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'item' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/e038155e367d4454206b7ceed51b3c38/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'item' || (getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit' ? ['breadcrumbs__text', 'active'] : ['breadcrumbs__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/e714e1bfa66adc23c9e84d0b018619d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'item')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/menu/components/d2c736379933fbc8d3e69e42e1bf69c9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'item')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'item' ? ['breadcrumbs__text', 'active'] : ['breadcrumbs__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/cd624ff50c48493d3c67388a4fb5050c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var modal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function showModal(modal) {
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', true);
  ___arguments.context.pageData['modal'] = modal;
}

/**
 * Describe this function...
 */
async function changeScreen() {
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit') {
    ___arguments.context.pageData['activeScreen'] = 'category';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'categoryEdit';
  }
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'categoryEdit') {
    if ((await BackendlessUI.Functions.Custom['fn_ec0d32962c938b39757cb0fa83e523b6'](___arguments.context.pageData))) {
      await showModal('saveCategory');
    } else {
      await changeScreen();
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    if ((await BackendlessUI.Functions.Custom['fn_c0a3f7f2c1b299c7fa9efbcfccfaf763'](___arguments.context.pageData))) {
      await showModal('saveCategory');
    } else {
      await changeScreen();
    }
  } else {
    if (!(getObjectProperty(___arguments.context.pageData, 'itemChanges')).length) {
      await changeScreen();
    } else {
      await showModal('saveItem');
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/26f1026ff85b2b28c52cbed88d31628a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category' ? ['breadcrumbs__text', 'active'] : ['breadcrumbs__text'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/menu/components/6919d06e5a7d6429d9dcbf31292728a7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var newItems;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log(___arguments.context.dataModel);
  newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
  if ((newItems.map(item => item['id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))) {
    removeItemInList(newItems, ___arguments.context.dataModel, 'id');
  } else {
    addItemToList(newItems, ___arguments.context.dataModel);
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['modal__item'];
  if ((getObjectProperty(___arguments.context.pageData, 'newItems')) && (((getObjectProperty(___arguments.context.pageData, 'newItems')).map(item => item['id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))) {
    addItemToList(classes, 'active');
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'searchValue')) && ((((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'searchValue')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1)) {
    addItemToList(classes, 'result');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('ab09aee93f6a2727330df69a99b59639')[(((getObjectProperty(___arguments.context.dataModel, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase())] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6919d06e5a7d6429d9dcbf31292728a7'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/menu/components/072339ee92c4dce80f3c0cba5373d31b/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var allTransactions, amount, cursor, customers, date, duplicates, filteredTransactions, i, item, j, list, location2, offset, payment, prevDay, res, result, storedQR, transactions, transactionsToSave, user, userList, usersToSave;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getPayments(cursor, location2) {
  result = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/getGiftCardPayments`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'cursor': JSON.stringify(cursor),'begin_time': JSON.stringify(date),'locationId': JSON.stringify(location2) }).send());
  list = (addItemToList(list, (getObjectProperty(result, 'payments'))));
  cursor = (getObjectProperty(result, 'cursor'));
  if (cursor) {
    await getPayments(cursor, location2);
  }
}

/**
 * Describe this function...
 */
async function getCustomers(offset) {
  res = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['customerId in (\'',customers.join('\',\''),'\')'].join(''))).setProperties(['customerId', 'name', 'bonusBalance', 'objectId']).setPageSize(100).setOffset(offset)));
  addItemToList(userList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getCustomers(offset);
  }
}

/**
 * Describe this function...
 */
async function getStoredPayments(offset) {
  res = (await Backendless.Data.of('Ledger').find(Backendless.DataQueryBuilder.create().setWhereClause((['squareId in (\'',(allTransactions.map(item => item['id'])).join('\',\''),'\')'].join(''))).setPageSize(100).setOffset(offset)));
  addItemToList(duplicates, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getStoredPayments(offset);
  }
}

/**
 * Describe this function...
 */
async function loadQRTransactions(offset) {
  res = (await Backendless.Data.of('QRTransaction').find(Backendless.DataQueryBuilder.create().setWhereClause((String('created > ') + String(prevDay.valueOf()))).setProperties(['squareId']).setPageSize(100).setOffset(offset)));
  addItemToList(storedQR, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await loadQRTransactions(offset);
  }
}

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/menu/components/ab09aee93f6a2727330df69a99b59639/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'uniqueItems'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/menu/components/5cae2fbf60654f7485f299a5836b2e05/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var components, item;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  stopSetTimeout('searchTimer');

  ;(function() {
    const callback = async () => {
        components = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'uniqueItems')), async (item) => {


     return ((((getObjectProperty(item, 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()).indexOf(((___arguments.value.replace((new RegExp('\\s', 'g')), '')).toLowerCase())) !== -1);
    }));
    ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['searchResult'] = components;
    if (!components.length) {
      ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['searchResultText'] = '0 of 0';
    } else {
      ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['selected'] = 1;
      ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')),' of ',components.length].join(''));
      (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('ab09aee93f6a2727330df69a99b59639')), (((getObjectProperty((components[0]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
    }

    };

    const timerId = 'searchTimer';
    const timerDelay = 100;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/menu/components/c6172bb57c216879f3d40711c16634bf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')) > 1) {
      ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')) - 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['selected'] = (components.length);
    }
    ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('ab09aee93f6a2727330df69a99b59639')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/5055cb6c76b873e64fd7e2d58121a94a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var components;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  components = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'searchResult'));
  if (!!components.length) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')) < components.length) {
      ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['selected'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')) + 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['selected'] = 1;
    }
    ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['searchResultText'] = ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')),' of ',components.length].join(''));
    (function (component) { component.el.scrollIntoView() })((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('ab09aee93f6a2727330df69a99b59639')), (((getObjectProperty((components[((getObjectProperty(___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28'), 'selected')) - 1)]), 'item_data.name')).replace((new RegExp('\\s', 'g')), '')).toLowerCase()))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/093e105fa065655907e5544202a2b379/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['searchResult'] = [];
  ___arguments.context.getComponentDataStoreByUid('21b6da3853232976a77f5053705d4a28')['searchValue'] = '';
  (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ab09aee93f6a2727330df69a99b59639')), 'el'))['scrollTop'] = 0;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/menu/components/306c664c1a22ebf1c33c1df5d0c4ce4a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  console.log(getObjectProperty(___arguments.context.dataModel, 'order'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var mainLocation, allLocationsList, item, activeLocationsList, newLocations, squareLocations, itemsLength, sortedItems, j, updateItem, itemsList, menuItems, result, offset, res;

/**
 * Describe this function...
 */
async function getMenus() {
  try {
    menuItems = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('ITEM') }).send());

  } catch (error) {
    await getMenus();

  }
}

/**
 * Describe this function...
 */
async function getModifiers() {
  try {
    ___arguments.context.pageData['modifierList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-catalog-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'types': JSON.stringify('MODIFIER_LIST') }).send());

  } catch (error) {
    await getModifiers();

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));
    console.log(squareLocations);

  } catch (error) {
    await getLocationsFromSquare();

  }
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getItemImages(offset) {
  res = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/loadImages`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(offset)));
  addItemToList(itemsList, res);
  if (res.length == 100) {
    offset = (typeof offset == 'number' ? offset : 0) + 100;
    await getItemImages(offset);
  }
}

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/settings/components/1e6780e56a12dc1e04f3b30a268105d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/09ee7b31196c7618e9438bb4d9766b23/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/67972eade3335ce9fbdf479ab15d7f6f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newMenu, list, menuName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  menuName = (getObjectProperty(___arguments.context.pageData, 'newMenuName'));
  list = (getObjectProperty(___arguments.context.pageData, 'menuList'));
  if (menuName) {
    newMenu = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminCreateNewMenu`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'name': JSON.stringify(menuName) }).send());
    addItemToList(list, newMenu);
    delete ___arguments.context.pageData['newMenuName'];
    addItemToList((getObjectProperty(___arguments.context.pageData, 'timestamps')), (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(newMenu, 'objectId'))))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0ca41b445208a54d8dc78108a0b4e751', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/5e23e373e2d47b6876070a18845ace2d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/ca2fa06723b33b229969fa56df5fafb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/fffb1468d62269c33bb737b61e9b0659/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, list, categoryName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categoryName = (getObjectProperty(___arguments.context.pageData, 'newCategoryName'));
  list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
  if (categoryName) {
    category = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateCategory`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'name': categoryName,'order': (list.length + 1),'menuId': (getObjectProperty(___arguments.context.pageData, 'editing.objectId')) }));
    category['item'] = [];
    addItemToList(list, category);
    await (async function() {
    	const category = [...document.querySelectorAll('.draggable')].slice(-1).pop();

    	category.draggable = true;
    	category.addEventListener('dragstart', (e) => {
    	  category.classList.add("is-dragging")
    	});

    	category.addEventListener('dragend', (e) => {
    	  category.classList.remove("is-dragging");
    	})
    })();
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
    delete ___arguments.context.pageData['newCategoryName'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e281631c08160b65a6c690a3104953c2', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/80451d7b74fdb049e2133bf6b39e6db7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  delete ___arguments.context.pageData['newItem'];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/d2cdea06507901cd1ebfad2e5e7357d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      delete ___arguments.context.pageData['newItem'];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/81ef466fc0daa5438334ddd78906b37a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newItem, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'newItems')) && !!(getObjectProperty(___arguments.context.pageData, 'newItems')).length) {
    newItem = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'newItems')).map(async item => {; return ({ 'name': (getObjectProperty(item, 'item_data.name')),'squareId': (getObjectProperty(item, 'id')),'variation': ((getObjectProperty(item, 'item_data.variations')).map(item => item['id'])),'order': (getObjectProperty(___arguments.context.pageData, 'newItems')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingCategory.item')).length });})));
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['item'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), newItem));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    delete ___arguments.context.pageData['newItems'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dc8c75c7e6ef284b0c88f107166ae632', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/2195e4413bb4651d9af9c0b5f35cc786/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/c517adbd761c078868c23a0417cb4009/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var categories, items, j, menu;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  categories = (getObjectProperty(___arguments.context.pageData, 'activeMenu.category'));
  menu = (getObjectProperty(___arguments.context.pageData, 'activeMenu'));
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'menuList')), menu, 'objectId');
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenu`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(menu, 'objectId'))));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(menu, 'objectId'))))));
  if (categories && !!categories.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((categories.map(item => item['objectId']))));
    items = [];
    var j_list = (categories.map(item => item['item']));
    for (var j_index in j_list) {
      j = j_list[j_index];
      addItemToList(items, j);
    }
    if (!!items.length) {
      await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
    }
  }
  ___arguments.context.pageData['activeScreen'] = 'menu';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/4d497b345ff8661374350be53f762fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/81eb464908fa214371fad0fc9da7da63/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var category, items;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  category = (getObjectProperty(___arguments.context.pageData, 'categoryToDelete'));
  items = (getObjectProperty(category, 'item'));
  await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteCategories`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify([(getObjectProperty(category, 'objectId'))]));
  if (!!items.length) {
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((items.map(item => item['objectId']))));
  }
  removeItemInList((getObjectProperty(___arguments.context.pageData, 'editing.category')), category, 'objectId');
  await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
  	return list.find(item => item.menuId === id).objectId
  })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  ___arguments.context.pageData['activeScreen'] = 'category';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/f351bbec212b1a64f9bb319b2db7ed1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/04354a94d901e953b2dd83af23dfc6a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  item = (getObjectProperty(___arguments.context.pageData, 'activeItem'));
  if (getObjectProperty(item, 'objectId')) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'objectId');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'objectId');
    await Backendless.Request.delete(`${Backendless.appPath}/services/DatabaseService/adminDeleteItems`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify());
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveMenuTimestamp`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((await (async function(list, id) {
    	return list.find(item => item.menuId === id).objectId
    })((getObjectProperty(___arguments.context.pageData, 'timestamps')), (getObjectProperty(___arguments.context.pageData, 'editing.objectId'))))));
  } else {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'editingCategory.item')), item, 'order');
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'activeCategory.item')), item, 'order');
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  ___arguments.context.pageData['editItem'] = false;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/c2354b1d5cbb04b68e686f65e4f2329d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var newItems;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
  if (___arguments.value) {
    if (newItems) {
      addItemToList(newItems, ___arguments.context.dataModel);
    } else {
      ___arguments.context.pageData['newItems'] = [___arguments.context.dataModel];
      newItems = (getObjectProperty(___arguments.context.pageData, 'newItems'));
    }
  } else {
    removeItemInList(newItems, ___arguments.context.dataModel, 'id');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'newItems')) && (((getObjectProperty(___arguments.context.pageData, 'newItems')).map(item => item['id'])).includes((getObjectProperty(___arguments.context.dataModel, 'id')))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/settings/components/d3d5a474c709ae6bfc919e3250f71aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/9de2b727d0c49ad7ffe601c858dcf287/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/c331492cf5738f93b85d32619a4f57f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/settings/components/23144bc14fa4e6d42aec6ed66d7da7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingCategory'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('71a41f8fac6ec44ffecd30ca0523796a', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/8665bb11608ea9cc4a16d186892a68c7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/settings/components/4813af6d506917b2fa7993d9713e7ce9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/cac1771cf2985c29f493f07d8404503a/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cac1771cf2985c29f493f07d8404503a');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b35dd7a85eda657158b6e36271429c2e', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return ___arguments.files

  },
  /* handler:onBeforeUpload */
  /* content */
}))

define('./pages/settings/components/8c3ccce88fcdb265c83723bfa9c1e75a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var j;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    var j_list = (getObjectProperty(___arguments.context.pageData, 'editing.category'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      if (getObjectProperty(j, 'oldOrder')) {
        j['order'] = (getObjectProperty(j, 'oldOrder'));
        j['changed'] = false;
        delete j['oldOrder'];
      }
    }
    ___arguments.context.pageData['activeScreen'] = 'menu';
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/7ea3c78b76896a9f6a8bfe3b8467a838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if ((getObjectProperty(___arguments.context.pageData, 'activeScreen')) == 'category') {
    ___arguments.context.pageData['activeScreen'] = 'menu';
    await BackendlessUI.Functions.Custom['fn_6c98c9313608834ca421bdc6a59a05e1'](___arguments.context.pageData);
  } else {
    ___arguments.context.pageData['activeScreen'] = 'category';
    await BackendlessUI.Functions.Custom['fn_e7469d9baa2aa6578facbe8a273cb826'](___arguments.context.pageData);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/1f3e6b85e1da4e78f09d0552d3ac44da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/2ea8f662f17978e7b5b36f0b8b868237/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/7e92da65f1f758a852c2bcbdd48d8741/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('23aa26dfcdc16688b2065c0d25da2ca4', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/8bd7b99185b6ae3788dfce84b9d1b728/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addMenu') {
    await new Promise(r => setTimeout(r, 1 || 0));
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ac65e8de202b30c7ef2d83bd7be8675a')));
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/settings/components/68a4020f2c832f04358838766e864a5c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/3678cc2ead6cad6dce141ea1ebbcb756/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/6526393763e3a4c7909bcc2a81665aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/e53b4263b63bbe926862d1f42016faca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'saveCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/f167cc4a529265896f775dd1f58fcf4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/853de7c6b4c7d7081c68f5f3f27699a3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteCategory')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/8528128c771cbd8e7a729a72e25e9622/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteMenu')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/21b6da3853232976a77f5053705d4a28/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'addItem')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/6bfec90cc41883d3ef91acc5545e7a83/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'todaysOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/38cc2ab7137c43ce012215fafa838566/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, item, j, modifiers, offers, offersToDelete, selectedModifiers, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'offers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  offersToDelete = (getObjectProperty(___arguments.context.pageData, 'offersToDelete'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'offerDate': (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp')),'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'offers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/settings/components/147fb543216568d9044a403b78dd33c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/9741a2f63a781c339b7455042b7a2fcc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/a2fb35e6eb5ed781034fb01ed0c13555/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, offers, offersToDelete, ids, offersToCreate, item, timestamp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  timestamp = (getObjectProperty(___arguments.context.pageData, 'editingDate.timestamp'));
  addItemToList((getObjectProperty(___arguments.context.pageData, 'editingDate.offers')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'offers')), 1, async (item) => {
     item['order'] = ((getObjectProperty(___arguments.context.pageData, 'offers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'editingDate.offers')).length);


   return (getObjectProperty(item, 'name'));
  })));
  await (async function() {
  	const items = [...document.querySelectorAll('.draggable')];

  	items.forEach(item =>{
  	  item.draggable = true;
  	  item.addEventListener('dragstart', (e) => {
  	    item.classList.add("is-dragging")
  	  });

  	  item.addEventListener('dragend', (e) => {
  	    item.classList.remove("is-dragging");
  	  })
  	})
  })();
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/3b7ca5aa8ac86a1dda15706d607f5aa4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var j, day, curMonth, timestamp, offers, result, ids, listToSave, date, i, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length) {
    ___arguments.context.pageData['periodOffersList'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'periodOffersList')), (await asyncListSort((getObjectProperty(___arguments.context.pageData, 'periodOffers')), 1, async (item) => {
       item['order'] = ((getObjectProperty(___arguments.context.pageData, 'periodOffers')).indexOf(item) + 1 + (getObjectProperty(___arguments.context.pageData, 'periodOffersList')).length);


     return (getObjectProperty(item, 'name'));
    }))));
    await (async function() {
    	const items = [...document.querySelectorAll('.draggable')];

    	items.forEach(item =>{
    	  item.draggable = true;
    	  item.addEventListener('dragstart', (e) => {
    	    item.classList.add("is-dragging")
    	  });

    	  item.addEventListener('dragend', (e) => {
    	    item.classList.remove("is-dragging");
    	  })
    	})
    })();
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'periodOffers')).length)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/settings/components/2347c3e33bd631fcb82155b1b63efee4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var id, offers, selectedModifiers, j, modifiers, item, squareItemModifiers;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  offers = (getObjectProperty(___arguments.context.pageData, 'periodOffers'));
  id = (getObjectProperty(___arguments.context.dataModel, 'id'));
  squareItemModifiers = (getObjectProperty(___arguments.context.dataModel, 'item_data.modifier_list_info'));
  selectedModifiers = ({  });
  if (squareItemModifiers) {
    modifiers = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'modifierList')), async (item) => {


     return ((squareItemModifiers.map(item => item['modifier_list_id'])).includes((getObjectProperty(item, 'id'))));
    }));
    for (var j_index in modifiers) {
      j = modifiers[j_index];
      selectedModifiers[(getObjectProperty(j, 'id'))] = ((getObjectProperty(j, 'modifier_list_data.modifiers')).map(item => item['id']));
    }
  }
  if (___arguments.value) {
    addItemToList(offers, ({ 'squareId': id,'name': (getObjectProperty(___arguments.context.dataModel, 'item_data.name')),'squareObject': ___arguments.context.dataModel,'variation': ((getObjectProperty(___arguments.context.dataModel, 'item_data.variations')).map(item => item['id'])),'modifier': selectedModifiers }));
  } else {
    removeItemInList(offers, ({ 'squareId': id }), 'squareId');
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(___arguments.context.pageData, 'periodOffers')).map(item => item['squareId'])).includes((getObjectProperty(___arguments.context.dataModel, 'id'))))

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./pages/settings/components/d15846c1fa19d2c5bfc2071d973a670e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'periodOffer')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/94a2c4e501c37ae46467178715faa1db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/95412ce446138ea82f1b0a2953127ddf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['periodOffers'] = [];
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/de042b7c17155e92ca7b0324cfbf86f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'periodOffers')), ___arguments.context.getComponentDataStoreByUid('7bbeefa6fdc6e11dd6dc2fd798ad5749'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/3af29a84d03d50b21fe45a3ee8a3683a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  removeItemInList((getObjectProperty(___arguments.context.pageData, 'offers')), ___arguments.context.getComponentDataStoreByUid('552b29f96cbcda34a01a373cb661dd0b'), 'squareId');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/f2e60cedd8461306cf55af0501dd8c39/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadFail */
  ['onUploadFail'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.error, 'code')) == 6003) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadFail */
  /* handler:onBeforeUpload */
  async ['onBeforeUpload'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27d11aeb21c1b27d4c0aea6b1daf40c6', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);

  return (await (async function(files, percent, width) {
	for (let index = 0; index < files.length; index++) {
	  const file = files[index]
	  const fileName = file.name

	  const content = await readFileAsDataURL(file)

	  const img = await getNewImage(content)

	  const elem = document.createElement('canvas')

	  const widthImage = !width ? img.width : img.width > width ? width : img.width
	  const scaleFactor = img.width > width ? width && width / img.width : 1
	  const heightImage = width ? img.height * scaleFactor : img.height

	  elem.width = widthImage
	  elem.height = heightImage

	  const ctx = elem.getContext('2d')

	  ctx.drawImage(img, 0, 0, widthImage, heightImage)

	  const blob = await getCanvasBlob(ctx.canvas, percent)

	  const newFile = new File([blob], fileName, {
	    type: 'image/jpeg',
	    lastModified: Date.now()
	  })

	  files[index] = newFile
	}

	async function readFileAsDataURL(file) {
	  const result = await new Promise((resolve) => {
	    const fileReader = new FileReader()

	    fileReader.onload = (e) => resolve(e.target.result)

	    fileReader.readAsDataURL(file)
	  })

	  return result
	}

	async function getNewImage(content) {
	  const result = await new Promise((resolve) => {
	    const img = new Image()

	    img.src = content

	    img.onload = () => resolve(img)
	  })

	  return result
	}

	async function getCanvasBlob(canvas, percent) {
	  const result = await new Promise((resolve) => {
	    canvas.toBlob((blob) => {
	      resolve(blob)
	    }, 'image/jpeg', percent)
	  })

	  return result
	}

	return files
})(___arguments.files, 1, 600))

  },
  /* handler:onBeforeUpload */
  /* handler:onUploadSuccess */
  ['onUploadSuccess'](___arguments) {
      ___arguments.context.pageData['uploadedOfferImage'] = ___arguments.uploadedFiles;
  ___arguments.context.pageData['selectedOfferImage'] = ___arguments.uploadedFiles;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('f2e60cedd8461306cf55af0501dd8c39');

  },
  /* handler:onUploadSuccess */
  /* content */
}))

define('./pages/settings/components/c2b51d85ca38df7c478af3ec9a3dd2ef/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'editOfferImage')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/afc9812e122100175a02defad0086d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', true);
  if (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) {
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['image'] = (getObjectProperty(___arguments.context.pageData, 'selectedUrl'));
    (getObjectProperty(___arguments.context.pageData, 'editingOffer'))['useImage'] = true;
    delete ___arguments.context.pageData['selectedUrl'];
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d4f2f3ae904ab65229493b3cfba3016f', true);
  }
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b514cdec7bc17de1aa49bc8f75f1c0a', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/50d646aff2df909d6f3cfa42834e46d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/5ab734948432b055f37bd2f5d79f49a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/73bf13a62b99deee7c49d918cd4e9b65/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) && (getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/settings/components/f6f8336f7239d56e550334dc3c796739/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.dataModel, 'publicUrl'));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'selectedUrl')) == (getObjectProperty(___arguments.context.dataModel, 'publicUrl')) ? ['images__container', 'active'] : ['images__container'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/settings/components/fa79760dc4aee4548cf8b0b361de395c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedUrl'] = (getObjectProperty(___arguments.context.pageData, 'uploadedOfferImage'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/38fdc0d564cb93555f7b3af990942bbd/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'deleteUser')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/settings/components/d462ba7e784a2bfbf4fb69ebd36d9463/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/7ffbfd02194122b2b9fa64d5203f74a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(___arguments.context.pageData, 'userToDelete.objectId')) });
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))['usersList'] = (await BackendlessUI.Functions.Custom['fn_ff8ba791ea54622d40e3ca931eab7d0c'](((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('953bd551accbc806b9dbfad62dabc57a'))));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/3e8b6c2f40af464cbb6b024c9abee553/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance')

  },
  /* handler:onDisplayAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var card;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'modal')) == 'balance') {
    card = (await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-gift-card`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'card_id': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'customer')), 'walletId'))) }).send());
    (getObjectProperty(___arguments.context.pageData, 'customer'))['wallet'] = card;
    (getObjectProperty(___arguments.context.pageData, 'customer'))['balance'] = (getObjectProperty(card, 'balance_money.amount'));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0a591c5686d8d9cb355e7eba9fb40ecc', true);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(card, 'balance_money.amount')) / 100)))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('94912c8669c55c478fbc6851df1ce87d', false);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/settings/components/12c213fd49647dcefcfeceac2d54c56e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2ccb81c976d906d20c4bfbdd5f0baa19', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/c6e29ba49b64e8678919ffe51b74abf4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, user, wallet, order, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading'))) {
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    if (!(getObjectProperty(user, 'customerId'))) {
      user['customerId'] = (await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'email': (getObjectProperty(user, 'email')),'username': (getObjectProperty(user, 'name')),'phone': (getObjectProperty(user, 'phoneNumber')) }));
    }
    if (!(getObjectProperty(user, 'walletId'))) {
      wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))))), 'gift_card'));
      await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      try {
        amount = 500;
        order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'line_items': [({ 'name': 'Welcome gift','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
        payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')) })), 'payment'));
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': (getObjectProperty(___arguments.context.pageData, 'mainLocationId')),'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
        user['wallet'] = wallet;
        user['walletId'] = (getObjectProperty(wallet, 'id'));
        user['bonusBalance'] = (getObjectProperty(wallet, 'balance_money.amount'));
        ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
        	return number.toFixed(2)
        })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
        await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(user, 'objectId')),'walletId': (getObjectProperty(wallet, 'id')),'bonusBalance': (getObjectProperty(wallet, 'balance_money.amount')),'customerId': (getObjectProperty(user, 'customerId')) })) );

      } catch (error) {
        console.log(error);

      }
    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/6dafc3783e893a15101bb6ff439889a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, wallet, user, order, locationId, amount, payment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'loading')) && (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount'))) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Proceeding...';
    ___arguments.context.pageData['loading'] = true;
    user = (getObjectProperty(___arguments.context.pageData, 'customer'));
    try {
      amount = (getObjectProperty(___arguments.context.pageData, 'addBalanceAmount')) * 100;
      locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
      order = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-order`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ 'location_id': locationId,'line_items': [({ 'name': 'Manual refill','quantity': '1','item_type': 'GIFT_CARD','base_price_money': ({ 'amount': amount,'currency': 'USD' }) })] })))), 'order'));
      payment = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-payment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'amount': amount,'source_id': 'CASH','customer_id': (getObjectProperty(user, 'customerId')),'order_id': (getObjectProperty(order, 'id')),'location_id': locationId })), 'payment'));
      if (getObjectProperty(user, 'walletId')) {
        wallet = (getObjectProperty(user, 'wallet'));
      } else {
        wallet = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/createGiftCard`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(locationId))), 'gift_card'));
        await Backendless.Request.post(`${Backendless.appPath}/services/Square/link-customer`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'customer_id': (getObjectProperty(user, 'customerId')) });
      }
      if ((getObjectProperty(wallet, 'state')) == 'ACTIVE') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'LOAD' })), 'gift_card_activity.gift_card_balance_money'));
      } else if ((getObjectProperty(wallet, 'state')) == 'PENDING') {
        wallet['balance_money'] = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/create-gift-card-activity`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'gift_card_id': (getObjectProperty(wallet, 'id')),'location_id': locationId,'order_id': (getObjectProperty(order, 'id')),'line_item_uid': (getObjectProperty(((getObjectProperty(order, 'line_items'))[0]), 'uid')),'type': 'ACTIVATE' })), 'gift_card_activity.gift_card_balance_money'));
        wallet['state'] = 'ACTIVE';
      }
      user['balance'] = (getObjectProperty(wallet, 'balance_money.amount'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0a591c5686d8d9cb355e7eba9fb40ecc'))['content'] = (String('$') + String((await (async function(number) {
      	return number.toFixed(2)
      })(((getObjectProperty(wallet, 'balance_money.amount')) / 100)))));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = 'Done';
      await new Promise(r => setTimeout(r, 5000 || 0));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = '';

    } catch (error) {
      console.log(error);
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('074f6fd832dfee59a42ccdc110413ff6'))['value'] = (String('Error: ') + String(getObjectProperty(error, 'message')));
      if ((getObjectProperty(error, 'message')) == 'Network Error') {
        ___arguments.context.pageData['loading'] = false;
        ___arguments.context.pageData['networkError'] = true;
      }

    }
    ___arguments.context.pageData['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/b4b1bbad5badf82823b35199fc3eeaed/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4b1bbad5badf82823b35199fc3eeaed'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/settings/components/8b39c08d8789848677dd3923e547da4f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/saveAppConfig`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'enableWelcomeGift': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c394470b2df24abb927bf18e0676480d'), 'enableWelcomeGift')),'welcomeGiftAmount': ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('c394470b2df24abb927bf18e0676480d'), 'giftAmount')) * 100) });
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', true);
    await new Promise(r => setTimeout(r, 5000 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('325ea944dc9dd8360902212b71693de1', false);

  } catch (error) {
    await (async function(error) {
    	alert(error)
    })(error);
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/settings/components/6446e121b505d8615d3f1ef186aaf7f4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('6446e121b505d8615d3f1ef186aaf7f4'))['value'] = (___arguments.value.replace((new RegExp('\\D', 'g')), ''));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/settings/components/c394470b2df24abb927bf18e0676480d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var appConfig;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  appConfig = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getAppConfig`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.dataModel['appConfig'] = appConfig;
  ___arguments.context.dataModel['enableWelcomeGift'] = (getObjectProperty(appConfig, 'enableWelcomeGift'));
  ___arguments.context.dataModel['giftAmount'] = ((getObjectProperty(appConfig, 'welcomeGiftAmount')) / 100);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/transactions/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var mainLocation, allLocationsList, item, activeLocationsList, newLocations, squareLocations;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function getLocationsFromSquare() {
  try {
    squareLocations = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/get-location-list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'locations'));

  } catch (error) {
    await getLocationsFromSquare();

  }
}


  if (await Backendless.UserService.isValidLogin()) {
    ___arguments.context.pageData['timestamps'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/getChangeTimestamps`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    if (getObjectProperty(___arguments.context.appData, 'locations')) {
      allLocationsList = (getObjectProperty(___arguments.context.appData, 'locations'));
    } else {
      await getLocationsFromSquare();
      allLocationsList = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminGetLocations`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((squareLocations.map(item => item['id'])))));
      if (squareLocations.length > allLocationsList.length) {
        newLocations = (await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminCreateNewLocation`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'locations': (await Promise.all((await asyncListFilter(squareLocations, async (item) => {


         return (!((allLocationsList.map(item => item['squareId'])).includes((getObjectProperty(item, 'id')))));
        })).map(async item => {; return ({ 'squareId': (getObjectProperty(item, 'id')),'name': (getObjectProperty(item, 'name')),'hidden': (getObjectProperty(item, 'status')) == 'INACTIVE' });}))),'timestampId': (getObjectProperty((await (async function(list) {
        	return list.find(item => item.type === 'location')
        })((getObjectProperty(___arguments.context.pageData, 'timestamps')))), 'objectId')) }));
        addItemToList(allLocationsList, newLocations);
      }
      ___arguments.context.appData['locations'] = allLocationsList;
    }
    activeLocationsList = (await asyncListFilter(allLocationsList, async (item) => {


     return ((getObjectProperty(item, 'isMain')) || !(getObjectProperty(item, 'hidden')));
    }));
    ___arguments.context.pageData['locationList'] = activeLocationsList;
    ___arguments.context.pageData['allLocationList'] = allLocationsList;
    mainLocation = (await (async function(list) {
    	return list.find(item => item.isMain)
    })(allLocationsList));
    ___arguments.context.pageData['mainLocationId'] = (getObjectProperty(mainLocation, 'objectId'));
    ___arguments.context.pageData['mainLocationSquareId'] = (getObjectProperty(mainLocation, 'squareId'));
  }

  },
  /* handler:onEnter */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    user = (getObjectProperty(___arguments.context.appData, 'user')) ? (getObjectProperty(___arguments.context.appData, 'user')) : (await Backendless.UserService.getCurrentUser(false));
    if (!user || !(getObjectProperty(user, 'admin'))) {
      await Backendless.UserService.logout();
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      return ;
    }
  } else {
    await Backendless.UserService.logout();
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    return ;
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/transactions/components/94f9727936598f1c1675cd4bafc5b373/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var j, payout, summary, QRPayments, lastReconciliationDate, lastReconciliation, locationId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.dataModel['loading'] = true;
  await new Promise(r => setTimeout(r, 1000 || 0));
  while (!(getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
  }
  locationId = (getObjectProperty(___arguments.context.pageData, 'mainLocationSquareId'));
  lastReconciliation = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetLastReconDate`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  ___arguments.context.dataModel['lastReconciliation'] = lastReconciliation;
  lastReconciliationDate = (new Date((getObjectProperty(lastReconciliation, 'lastReconciliationDate'))));
  ___arguments.context.dataModel['lastReconciliationDateStr'] = (String('Last reconciliation date: ') + String(lastReconciliationDate.toLocaleDateString()));
  ___arguments.context.dataModel['paymentSummary'] = (await BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b']((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))));
  QRPayments = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetQRPayments`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  payout = (getObjectProperty(___arguments.context.dataModel, 'paymentSummary.payout'));
  for (var j_index in QRPayments) {
    j = QRPayments[j_index];
    j['locationName'] = (getObjectProperty((await (async function(list, id) {
    	return list.find(item => item.squareId === id)
    })((getObjectProperty(___arguments.context.pageData, 'allLocationList')), (getObjectProperty(j, 'locationId')))), 'name'));
    if ((payout.map(item => item['squareId'])).includes((getObjectProperty(j, 'locationId')))) {
      summary = (await (async function(list, id) {
      	return list.find(item => item.squareId === id)
      })(payout, (getObjectProperty(j, 'locationId'))));
      summary['totalAmount'] = ((getObjectProperty(summary, 'totalAmount')) + (getObjectProperty(j, 'totalAmount')));
      summary['totalUsedBonus'] = ((getObjectProperty(summary, 'totalUsedBonus')) + (getObjectProperty(j, 'totalUsedBonus')));
    } else {
      addItemToList(payout, j);
    }
  }
  Object.assign(___arguments.context.dataModel, (await BackendlessUI.Functions.Custom['fn_fcec57351c91061d5927992ed935b2e9']((getObjectProperty(___arguments.context.dataModel, 'paymentSummary')))));
  ___arguments.context.dataModel['loading'] = false;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/transactions/components/227d107f05ca7f016b8c72d53de80dd8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/e533a22258fc99c6cb42074626642be2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54'](___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/93ea3b49c933286acc41dd122d8d41db/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), ___arguments.value, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/f10651b748adc02bd2a1989aaa4e030e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
    date = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate'));
    date.setMonth(((new Date(date).getMonth()) - 1));
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentsDate'] = date;
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['timeInterval'] = (await BackendlessUI.Functions.Custom['fn_67559c72e94d48924fe326d47e282977'](date));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
    }
    Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), date, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/d356090b202f94494949ac6df60e6779/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var date;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
    date = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate'));
    date.setMonth(((new Date(date).getMonth()) + 1));
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentsDate'] = date;
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['timeInterval'] = (await BackendlessUI.Functions.Custom['fn_67559c72e94d48924fe326d47e282977'](date));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
    }
    Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), date, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/ada9a22587a6580a47bc3a48e22f01d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ada9a22587a6580a47bc3a48e22f01d8'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['type'] = 'income';
  await new Promise(r => setTimeout(r, 1 || 0));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/transactions/components/eff17117d9eb48be484da79902aa01c3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['type'] = 'outgoing';
  await new Promise(r => setTimeout(r, 1 || 0));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', true);
  }
  Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_f9da412c517b7cdce9d73c1ba0acec54']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentLocation')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentsDate')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'income') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc244231842305e76e9d06e2ddc9f109', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('60385f33371452850dec32ddf3fd9059', false);
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'type')) == 'outgoing' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'loading')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/transactions/components/145f77debf7fa897e9d3a73ef8dba7bb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationName'] = (getObjectProperty(___arguments.context.dataModel, 'locationName'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pageSize'] = 50;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showIncomeTable'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['transactionSearch'] = '';
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', false);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await BackendlessUI.Functions.Custom['fn_de9c0eec9816df08f6e21d751148f188']((getObjectProperty(___arguments.context.dataModel, 'id')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')), null));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')).length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/0919b9516c361e17e5b9f11efa8793a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.dataModel);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = true;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationName'] = (getObjectProperty(___arguments.context.dataModel, 'locationName'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['activeLocationId'] = (getObjectProperty(___arguments.context.dataModel, 'id'));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showPayoutTable'] = true;
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', false);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pageSize'] = 50;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['transactionSearch'] = '';
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await BackendlessUI.Functions.Custom['fn_de9c0eec9816df08f6e21d751148f188']((getObjectProperty(___arguments.context.dataModel, 'id')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')), (getObjectProperty(___arguments.context.dataModel, 'squareId'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')).length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')) ? null : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')))));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['loading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/4494ae2196374ab569430767b49971b1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', true);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showIncomeTable'] = false;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/f2ccb45c4ab65c62ec8481c5d7af7a06/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e90a8ee3a3f3c9fc7c5fabee1b974a18', true);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['showPayoutTable'] = false;
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = [];

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/0e69a9df25dabf74ba7b466ca95c81c3/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0e69a9df25dabf74ba7b466ca95c81c3'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0e69a9df25dabf74ba7b466ca95c81c3'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'amount';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/6bd8b1d2ccbc4d96d0ee027340757bcc/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6bd8b1d2ccbc4d96d0ee027340757bcc'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6bd8b1d2ccbc4d96d0ee027340757bcc'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'name') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'name';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'title'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'title'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/6d38af6628bee7ce49abf556cf61f171/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d38af6628bee7ce49abf556cf61f171'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d38af6628bee7ce49abf556cf61f171'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'user';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/427f14d92349e264ada64c64b49de852/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('427f14d92349e264ada64c64b49de852'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('427f14d92349e264ada64c64b49de852'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'created'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'created'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/49795d6f900f8234461c1587ab0a23ba/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'amount';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'amount'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'amount' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/transactions/components/40a58e39aa0f35f6013ccade35a42f88/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('40a58e39aa0f35f6013ccade35a42f88'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('40a58e39aa0f35f6013ccade35a42f88'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filteredList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'user';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, 'username'));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'user' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/transactions/components/db78b60e1ff10bcd3a1bd735ef275381/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    var icon;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    icon = 'arrow right alt';
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'desc') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(-90deg)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('49795d6f900f8234461c1587ab0a23ba'))['transform'] = 'rotate(90deg)';
    }
  } else {
    icon = 'compare arrows';
  }

  return icon

  },
  /* handler:onIconAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'desc';
    } else {
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
    }
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortField'] = 'created';
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['sortOrder'] = 'asc';
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortOrder')) == 'asc') {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), 1, async (item) => {


     return (getObjectProperty(item, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField'))));
    }));
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionList'] = (await asyncListSort((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), -1, async (item) => {


     return (getObjectProperty(item, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField'))));
    }));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'sortField')) == 'created' ? ['table__header_icon', 'active'] : ['table__header_icon'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/transactions/components/a109324acb4bb0ec3503c9cbf0643dca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) + 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/f5fd1cd4ebef152a790d0741e222e4e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) - 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/636f1b4adfedfcc592843588aa94ef3a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  } else if (___arguments.value < 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = 1;
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ___arguments.value;
  }
  stopSetTimeout('paggination');

  ;(function() {
    const callback = async () => {
        ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())))));

    };

    const timerId = 'paggination';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/aa5bf7f90678135c451f91163c8c70ce/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > 1) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) - 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/7bee3754f6bdf8b622f4a761841be4f2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.value > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    console.log('> pages');
    console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7bee3754f6bdf8b622f4a761841be4f2'))['value'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  } else if (___arguments.value < 1) {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('7bee3754f6bdf8b622f4a761841be4f2'))['value'] = 1;
  } else {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ___arguments.value;
  }
  stopSetTimeout('paggination');

  ;(function() {
    const callback = async () => {
        ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));

    };

    const timerId = 'paggination';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/7a05548cb23e1e5360fde3842e672645/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) + 1);
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch'))))));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/402123f67b41e9f627aa8b0d6a132bef/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/50f651cce01cc671a244676779c6df89/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var date, lastReconciliation;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', true);
  lastReconciliation = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'));
  date = (new Date());
  date.setDate(((new Date(date).getDate()) - 1));
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['reconciliationModalStr'] = (['Are you sure you want to reconcile all transactions between ',((new Date((getObjectProperty(lastReconciliation, 'lastReconciliationDate')))).toLocaleDateString()),' and ',(date.toLocaleDateString())].join(''));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var lastReconciliationDate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  lastReconciliationDate = (new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation.lastReconciliationDate'))));

  return ((new Date((new Date())).getDate()) == (new Date(lastReconciliationDate).getDate()) && (new Date((new Date())).getMonth() + 1) == (new Date(lastReconciliationDate).getMonth() + 1) && (new Date((new Date())).getFullYear()) == (new Date(lastReconciliationDate).getFullYear()))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/transactions/components/eba9575be99b3396baf9390be52a4ee6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', false);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/6244141c5f41cca81413b199bcaec762/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, newCsvList, csvNameDate, locationName, item, j, csvList, locations, i, reconciliationQR, reconciliationTransactions, lastReconciliationDate, date, payout, summary, QRPayments;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['processingReconciliation'] = true;
  try {
    reconciliationTransactions = (await BackendlessUI.Functions.Custom['fn_1b3221e760f6705f5d2efe0d87fac07e']((getObjectProperty(___arguments.context.pageData, 'mainLocationId')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))));
    reconciliationQR = (await BackendlessUI.Functions.Custom['fn_4744eed4bcbd0dfd8db1ec8f4cb8addc']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))));
    csvList = (await Promise.all((await asyncListSort((reconciliationTransactions.concat(reconciliationQR)), 1, async (item) => {


     return (getObjectProperty(item, 'created'));
    })).map(async item => {; return ({ 'amount': String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(item, 'amount')) / 100)))),'bonuses': (typeof (getObjectProperty(item, 'usedBonus')) === 'number' && !isNaN((getObjectProperty(item, 'usedBonus')))) ? String('$') + String((await (async function(number) {
    	return number.toFixed(2)
    })(((getObjectProperty(item, 'usedBonus')) / 100)))) : '$0.00','method': (getObjectProperty(item, 'method')),'location': (getObjectProperty(item, 'name')) ? (getObjectProperty(item, 'name')) : (getObjectProperty((await (async function(list, id) {
    	return list.find(item => item.squareId === id)
    })((getObjectProperty(___arguments.context.pageData, 'allLocationList')), (getObjectProperty(item, 'locationId')))), 'name')),'title': (getObjectProperty(item, 'title')),'user': (getObjectProperty(item, 'username')),'date': ((new Date((getObjectProperty(item, 'created')))).toLocaleString()),'locationId': (getObjectProperty(item, 'locationId')) });})));
    locations = (await (async function(list) {
    	return [...new Set(list.map(item => item.locationId))]
    })(csvList));
    date = (new Date());
    date.setDate(((new Date(date).getDate()) - 1));
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    csvNameDate = [(new Date(date).getMonth() + 1),'-',(new Date(date).getDate()),'-',(new Date(date).getFullYear())].join('');
    ;(async () => {
        lastReconciliationDate = date;
      (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))['lastReconciliationDate'] = (lastReconciliationDate.valueOf());
      ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['lastReconciliationDateStr'] = (String('Last reconciliation date: ') + String(lastReconciliationDate.toLocaleDateString()));
      await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminUpdateReconciliationDate`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'lastReconciliation'))));

    })();
    var i_end = reconciliationTransactions.length;
    var i_inc = 100;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminUpdateLedger`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((reconciliationTransactions.slice((i - 1), i + 99)).map(item => item['paymentId']))));
    }
    var i_end2 = reconciliationQR.length;
    var i_inc2 = 100;
    if (1 > i_end2) {
      i_inc2 = -i_inc2;
    }
    for (i = 1; i_inc2 >= 0 ? i <= i_end2 : i >= i_end2; i += i_inc2) {
      await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminUpdateQR`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(((reconciliationQR.slice((i - 1), i + 99)).map(item => item['squareId']))));
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['paymentSummary'] = (await BackendlessUI.Functions.Custom['fn_0739089f230c0b29eaf5067eb1646d7b']((getObjectProperty(___arguments.context.pageData, 'mainLocationId'))));
    QRPayments = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetQRPayments`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    payout = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentSummary.payout'));
    for (var j_index in QRPayments) {
      j = QRPayments[j_index];
      j['locationName'] = (getObjectProperty((await (async function(list, id) {
      	return list.find(item => item.squareId === id)
      })((getObjectProperty(___arguments.context.pageData, 'allLocationList')), (getObjectProperty(j, 'locationId')))), 'name'));
      if ((payout.map(item => item['squareId'])).includes((getObjectProperty(j, 'locationId')))) {
        summary = (await (async function(list, id) {
        	return list.find(item => item.squareId === id)
        })(payout, (getObjectProperty(j, 'locationId'))));
        summary['totalAmount'] = ((getObjectProperty(summary, 'totalAmount')) + (getObjectProperty(j, 'totalAmount')));
        summary['totalUsedBonus'] = ((getObjectProperty(summary, 'totalUsedBonus')) + (getObjectProperty(j, 'totalUsedBonus')));
      } else {
        addItemToList(payout, j);
      }
    }
    Object.assign(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), (await BackendlessUI.Functions.Custom['fn_fcec57351c91061d5927992ed935b2e9']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'paymentSummary')))));
    newCsvList = [];
    for (var j_index2 in locations) {
      j = locations[j_index2];
      locationName = (getObjectProperty((await (async function(list, id) {
      	return list.find(item => item.squareId === id)
      })((getObjectProperty(___arguments.context.pageData, 'allLocationList')), j)), 'name'));
      newCsvList.unshift(({ 'url': (await Backendless.Request.post(`${Backendless.appPath}/services/CSVAdapter/list2csv`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'filePath': (['reconciliation/',(([locationName,'_',csvNameDate].join('')).replace((new RegExp('\\s', 'g')), '_')),'.csv'].join('')),'data': (await Promise.all((await asyncListFilter(csvList, async (item) => {


       return ((getObjectProperty(item, 'locationId')) == j);
      })).map(async item => {  delete item['locationId'];
      ; return item;}))),'csvOptions': ({ 'header': true }) })),'name': [locationName,'_',csvNameDate].join('') }));
    }
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['csvList'] = (newCsvList.concat((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'csvList'))));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b61cefd1c0bba8183077dddeec3c8234', false);
    ;(async () => {
        await Backendless.Request.post(`${Backendless.appPath}/services/DatabaseService/adminSaveCSVInfo`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(newCsvList));

    })();

  } catch (error) {
    console.log(error);

  } finally {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['processingReconciliation'] = false;

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'processingReconciliation')) ? 'Creating reports...' : 'Yes')

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/transactions/components/a191ab34d59d250f6fefd5532378d787/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'transactionSearch')).toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/af05f37da9df13ab833454e2219b6113/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.dataModel);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/59cb266bb246deb97b5bbf8522b5344a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), (___arguments.value.toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/ec8e1c225a853353822fb1dccceac7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), (___arguments.value.toLowerCase())));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/transactions/components/dffe66b95436da830e7484150a8b5d2b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'incomeTransactionList')), null));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['incomeTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/affbde4bf76cf6c25e8f71454078a4c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var filteredList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  filteredList = (await BackendlessUI.Functions.Custom['fn_223c2099ea67474426bbe9e8aeaf108b']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'payoutTransactionList')), null));
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = (Math.ceil(filteredList.length / (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize'))));
  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['pagginPages'] = 1;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) > (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'))) {
    ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['page'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pagginPages'));
  }
  ___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373')['payoutTransactionListPage'] = (await BackendlessUI.Functions.Custom['fn_cae38d8db93837ea37c781e2535338e7'](({ 'pageSize': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'pageSize')),'page': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('94f9727936598f1c1675cd4bafc5b373'), 'page')) }), filteredList));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/7a5ccf2775b830c17a81723ba77b8bf5/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
      ___arguments.context.dataModel['csvList'] = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetCsvList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'pageSize': 20,'offset': 0 }).send());

  },
  /* handler:onMounted */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ef31c1071a8e707d9f1b0e66dee6dc13'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/f6525249bb38ba3798f94f25495dca1c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var component, list, response;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a5ccf2775b830c17a81723ba77b8bf5'), 'loading')) && !(getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a5ccf2775b830c17a81723ba77b8bf5'), 'allReportsLoaded'))) {
    ___arguments.context.getComponentDataStoreByUid('7a5ccf2775b830c17a81723ba77b8bf5')['loading'] = true;
    list = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('7a5ccf2775b830c17a81723ba77b8bf5'), 'csvList'));
    response = (await Backendless.Request.get(`${Backendless.appPath}/services/DatabaseService/adminGetCsvList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'pageSize': 20,'offset': (list.length) }).send());
    ___arguments.context.getComponentDataStoreByUid('7a5ccf2775b830c17a81723ba77b8bf5')['csvList'] = (addItemToList(list, response));
    if (response.length < 20) {
      ___arguments.context.getComponentDataStoreByUid('7a5ccf2775b830c17a81723ba77b8bf5')['allReportsLoaded'] = true;
    }
    component = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ef31c1071a8e707d9f1b0e66dee6dc13')), 'el'));
    component['scrollTop'] = ((getObjectProperty(component, 'scrollHeight')) - (getObjectProperty(component, 'clientHeight')));
    ___arguments.context.getComponentDataStoreByUid('7a5ccf2775b830c17a81723ba77b8bf5')['loading'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/b91c5a1d39066b43eb914ed9da4db3c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var date;


  date = (new Date());
  date.setDate(((new Date((new Date())).getDay()) - 1));
  console.log((await Backendless.Data.of('Ledger').bulkUpdate( (String('updated > ') + String(date.valueOf())), ({ [`isReconciled`]: false }) )));
  console.log((await Backendless.Data.of('QRTransaction').bulkUpdate( (String('updated > ') + String(date.valueOf())), ({ [`isReconciled`]: false }) )));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/a27fae61143a700a764afa07328c7b1d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var id, locations, j, orders;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  locations = (await Backendless.Data.of('Location').find(Backendless.DataQueryBuilder.create().setWhereClause('squareId is not null').setPageSize(100)));
  orders = (await Backendless.Data.of('Order').find(Backendless.DataQueryBuilder.create().setWhereClause((['location is null',' or ','location.squareId is null'].join(''))).setPageSize(100)));
  console.log((orders.map(item => item['squareId'])).length);
  console.log('abc');
  for (var j_index in orders) {
    j = orders[j_index];
    id = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/Square/retrieve-order`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'order_id': JSON.stringify((getObjectProperty(j, 'squareId'))) }).send()), 'order.location_id'));
    console.log('abc');
    await Backendless.Data.of('Order').setRelation(j, 'location', [({ [`objectId`]: (await (async function(list, id) {
    	return list.find(item => item.squareId === id).objectId
    })(locations, id)) })]);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/transactions/components/ac78d47f743fe70b3f17f64d8a6b2bf9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var orders, tender, j, order, orders2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orders = (await Backendless.Data.of('Order').find(Backendless.DataQueryBuilder.create().setWhereClause((['payment is not null',' AND ','payment.squareId is null'].join(''))).setRelated('payment').setPageSize(100)));
  console.log(orders);
  if (!!orders.length) {
    orders2 = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/Square/get-order-history`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((orders.map(item => item['squareId']))))), 'orders'));
    console.log(orders2);
    for (var j_index in orders) {
      j = orders[j_index];
      order = (await (async function(list, id) {
      	return list.find(item => item.id === id)
      })(orders2, (getObjectProperty(j, 'squareId'))));
      tender = (getObjectProperty(order, 'tenders'))[0];
      (getObjectProperty(j, 'payment'))['amount'] = (getObjectProperty(tender, 'amount_money.amount'));
      (getObjectProperty(j, 'payment'))['squareId'] = (getObjectProperty(tender, 'payment_id'));
    }
    await Backendless.Data.of('Ledger').bulkUpsert( (orders.map(item => item['payment'])) );
    console.log(orders.map(item => item['payment']));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/cab2b24c03bed7bc94b51031f0c576e4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.dataModel, 'show')) ? ({  }) : ({ 'max-width': '80px' }))

  },
  /* handler:onStyleAssignment */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var pageName, query;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  query = (getObjectProperty(((function () { return window.location })()), 'search'));
  pageName = query.slice(((query.indexOf('=') + 1 + 1) - 1), (query.lastIndexOf('&') + 1) - 1);
  ___arguments.context.dataModel['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  (getObjectProperty(___arguments.context.dataModel, 'icons'))[pageName] = (['/img/icons/admin_icons/',pageName,'_active',(getObjectProperty(___arguments.context.dataModel, 'show')) ? '' : '-alt','.svg'].join(''));

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/01fb0b374671eb7e453bbe93af1790e5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pageName, query;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['show'] = (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')));
  ___arguments.context.appData['show'] = (!(getObjectProperty(___arguments.context.appData, 'show')));
  query = (getObjectProperty(((function () { return window.location })()), 'search'));
  pageName = query.slice(((query.indexOf('=') + 1 + 1) - 1), (query.lastIndexOf('&') + 1) - 1);
  ___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4')['icons'] = (await BackendlessUI.Functions.Custom['fn_f194bc7a16e6c9651e9df683e1bced61']());
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))[pageName] = (['/img/icons/admin_icons/',pageName,'_active.svg'].join(''));
  } else {
    (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'icons'))[pageName] = (['/img/icons/admin_icons/',pageName,'_active-alt.svg'].join(''));
  }

  },
  /* handler:onClick */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? '/img/icons/admin_icons/burger_active.svg' : '/img/icons/admin_icons/burger.svg')

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/e8b8236a173b04c874be92760bfd4a0c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/7947883afab3f46aab21e72e00aa440a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('menu') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/cac3b89f366c7a063d5f92f680fcad71/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('7947883afab3f46aab21e72e00aa440a')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('menu') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('menu', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/feab8a9c2d4c971752e6c8d4371e6369/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('image') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/f04fc7fb754f61f2d8e41459e5347002/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('feab8a9c2d4c971752e6c8d4371e6369')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('image') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('image', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/2e5d48e7c357d23fbf11781d28158286/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('location') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/e1b1c14e8a95c03da97482f3dffdd1e4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('2e5d48e7c357d23fbf11781d28158286')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('location') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('location', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/fb1f6043a0d1d3139aacf3dd6e356204/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('lto') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/f95a300c78637d8f4cc6f8f53826533e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('lto') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('lto', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('fb1f6043a0d1d3139aacf3dd6e356204')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/afd9721c030d9b3c632bbc05eb01f1f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('transactions') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/4612ffce48cbdfb38875804965b36f15/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('transactions') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('transactions', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('afd9721c030d9b3c632bbc05eb01f1f9')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/2ffd88835b68acb5130111d155fff5cd/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('customers') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/1834849d84bf85fe7271628d90ba78bf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('customers') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('customers', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('2ffd88835b68acb5130111d155fff5cd')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/7b2bd1ec04844548f0f5b324e6f8ca2e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  await Backendless.UserService.logout();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/root/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('cab2b24c03bed7bc94b51031f0c576e4'))['show'] = (getObjectProperty(___arguments.context.appData, 'show'));

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/964fceff23ca77535dc1a62fe1734a4d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'show'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/b2de1f0c5124b356b155a514468ccd4a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'show'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/c0a3c31c9c7fba168dfe5612030cc6c1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'show'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/6d1cc63ffb1cf39218fc6e3b12c993e3/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'show'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/5cd986632a31546ef2cf834da59357e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('settings') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/68d37e773ac4b7f74b7b76d824db199e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('settings') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('settings', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('5cd986632a31546ef2cf834da59357e3')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/51769b6fc45592db8a9c8bda3ef86d59/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('content') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/d1952ead1acc510e45794a2594dabefc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('content') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('content', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('51769b6fc45592db8a9c8bda3ef86d59')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/e5d56f1b47cfd4b8b1276848d085fb16/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('2a72419942aff6a4637ed86b3ae4f648')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('dashboard') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dashboard', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/2a72419942aff6a4637ed86b3ae4f648/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('dashboard') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/95c10ce27df90c8d7c7378036de8d2b2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('billing') !== -1) ? ['nav__block', 'active'] : ['nav__block'])

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) ? ({  }) : ({ 'box-shadow': 'none' }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_17ce2dfcd1071b81bbb6eb48dab1841c/logic/f5a1baf76035eb8814fca5871fdbfc61/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((getObjectProperty(((function () { return window.location })()), 'search')).indexOf('billing') !== -1)) {
    ___arguments.context.appData['show'] = true;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('billing', undefined);
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('cab2b24c03bed7bc94b51031f0c576e4'), 'show')) && (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('95c10ce27df90c8d7c7378036de8d2b2')).includes('active')) ? ({ 'backgroundColor': '#B29A6C' }) : ({  }))

  },
  /* handler:onStyleAssignment */
  /* content */
}))

